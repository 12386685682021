.navbarLogin {
  display: flex;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  flex-direction: row;
  gap: 0.75rem;
  background-color: #ffffff;
  height: 52px;
  background: linear-gradient(179deg, #2e5afa -79.3%, #000 146.58%);
}

.main{
  display: flex;
  flex-direction: column;
  min-height: max-content
}

 ::after, ::before {
  box-sizing: border-box;
  overflow: hidden;
}


.navbarLogoWrapper {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: bottom;
}

.loginWrapper {
  position: absolute;
  position: relative;
  top: 0;
  left: 50%;
  flex: 1 1 0%;
  width: 100%;
  background: #132462;
}

.loginWrapper:before {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(50%);
  content: "";
  width: 948px;
  height: 948px;
  flex-shrink: 0;
  border-radius: 948px;
  opacity: 0.5;
  background: #1b5ba7;
  filter: blur(125px);
}

.heading {
  line-height: 27px;
  font-size: 22px;
  font-weight: 900;
  color: rgb(225, 225, 225);
}

.seperator {
  height: 22px;
  width: 1px;
  border: 1px solid rgb(156 163 175);
}

.loginLabel {
  color: white;
  padding-bottom: 4px;
  line-height: 20px;
  font-weight: 600;
  font-size: 12px;
}

.SSO {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.inputStyle::placeholder{
  align-items: center;
  padding-left: 0px;
}


.mainContent {
  display: flex;
  flex-direction: column;
  padding: 100px;
  padding-left: 100px;
  min-height: calc(100vh - 52px);
}

.details {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
}

.inputStyle {
  border-radius: 5px;
  border: 1px solid var(--Neutral-colors-100, #e1e1e1);
  background: #fafafa;
  width: 300px;
  height: 40px;
}

.loginFields {
  display: flex;
  flex-direction: column;
  width: 300px !important;

}

.buttonStyle {
  border-radius: 4px;
  border: none;
  background: var(--Charts-Blue, #056ee5);
  color: var(--Primary-colors-Cloud-White-Base, #fff);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px; /* 100% */
  padding: 8px 20px;
  margin-top: 24px;
}

.emailText {
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

.loginError {
  color: #ff6a6a;
  margin-top: 8px;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 123.077% */
}
