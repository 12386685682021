.butoon-first__Cont{
  display: flex;
padding: 8px 12px;
justify-content: center;
align-items: center;
gap: 3px;
position: relative;
left: 242px;
bottom: 110px;
border-radius: 4px;
border: 1px solid var(--charts-blue, #056EE5);
background: var(--Controls-BG, #F0F6FF);
cursor: pointer;
}

.cost_widget_main_div {
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  margin: 0px;
}
.custom_widget_topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.custom_widget_name {
  color: #495057;
  font-family: Inter, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.add_new_widget {
  display: flex;
  padding: 6px 16px 6px 12px;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
}
.add_widgte_svg {
  align-items: flex-end;
}
.add_widget_button {
  color: var(--neutral-d-3, #495057);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.graph_main_div_1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.graph_boxes {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.graph_box1 {
  /* width: 670px; */
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-d-8, #e9ecef);
  background: #fff;
}
.graph_box_topname_bar {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}
.graph_box_names {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
}
.aws_costs {
  color: #495057;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.cost_value {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.css_projectName_days {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
}
.finops_project_cost {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.days_name_css {
  color: var(--neutral-d-5, #adb5bd);

  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 3px;
}
.days_css {
  color: var(--neutral-d-5, #adb5bd);
  /* text-align: right; */
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: flex-end;
  padding-top: 2px;
}
.graph_icons {
  display: flex;
  align-items: flex-start;
  gap: 2px;
}
.icons_background {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
}
.icons2_background {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  transform: rotate(-90deg);

  gap: 2px;
}
.infoicon_css {
  border-radius: 100px;
  background: var(--neutral-d-4, #797b80);
  display: flex;
  width: 16px;
  height: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.add_widget_overlay_screen {
  position: fixed;
  top: 0;
  right: 0px;

  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  /* overflow-y: scroll; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 9999;
}

/* add new custom widget ------------------------------------------------------------------------------- */
.add_custom_new_div {
  display: inline-flex;
  /* overflow-y: scroll; */
  /* height: 100vh; */
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  background-color: #fff;
  /* width: 464px; */
}
/* hide the scrollbar of whole body  */

.new_custom_widget {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px; /* 93.75% */
  display: flex;
  padding: 36px 24px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.custom_widget_values {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
}
.title_name {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
}
.name_input_box_css {
  display: flex;
  /* height: 58px; */
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}
.input_fields {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  background: var(--surface-default, #fff);

  /* shadow-button */
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
  width: 400px;
  height: 36px;
  padding: 0px 8px;
}
::placeholder {
  flex: 1 0 0;
  color: var(--neutral-d-5, #adb5bd);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}
.graphs {
  left: 0px;
}
.input_fields1 {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  background: var(--surface-default, #fff);

  /* shadow-button */
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
  width: 184px;
  height: 36px;
  padding: 0px 8px;
}
.filters_css {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  /* width: 400px; */
}
.filter_name_div {
  display: flex;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.filter_name {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.filter_select_div {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
.filter_select_css {
  border-radius: 3px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.choose_css {
  display: flex;
  padding: 4px 28px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--White, #fff);
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.select_name_css {
  color: var(--charts-blue, #056ee5);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.buttons_div {
  border-top: 1px solid var(--neutral-d-8, #e9ecef);
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.delete_button {
  border-radius: 4px;
  border: 1px solid var(--ceq-core-ui-palette-fire-engine-red, #d01a1a);
  background: var(--White, #fff);
  display: flex;
  padding: 9px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.delete_name_css {
  color: var(--ceq-core-ui-palette-fire-engine-red, #d01a1a);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 100% */
}
.cancel_button {
  display: flex;
  padding: 9px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid var(--neutral-d-5, #adb5bd);
  background: var(--White, #fff);
}
.save_button {
  display: flex;
  padding: 9px 24px 10px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--charts-blue, #056ee5);
}
.cancel_name_css {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 92.857% */
}
.save_name_css {
  color: var(--White, #fff);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 13px; /* 92.857% */
border-radius: 4px;

}
.error-message{
  color: red;
}
