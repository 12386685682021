ag-grid-react.tableHead {
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px;
  background-color: #f6f6f6;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
.table_hover_row:hover .sticky_cell {
  background-color: #f0f0f0;
}

.tableRows {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.trucate_css_in {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cloud_cell {
  width: 65%;
  color: var(--Background-module, #f0f0f0);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding-left: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cost_cell {
  /* width: calc(30% - 20px); */
  width: auto;
}

.horizontal_line {
  border-bottom: 1px solid #373737;
}

.spacer_cell {
  width: 55px;
}

.cost_cell {
  color: var(--neutral-d-9, #f8f9fa);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.custom_tooltip {
  background-color: black;
  border: 1px solid #000;
  border-radius: 8px;
  width: 240px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}

.custom_tooltip table {
  width: 100%;
  table-layout: fixed;
}

.custom_tooltip th {
  color: white;
  padding: 8px;
  align-items: flex-start;
  color: var(--nn-4, #a09fa5);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.custom_tooltip td {
  padding: 8px;
  color: white;
  align-items: flex-start;
}

.cpuIcon path {
  stroke: #dee2e6;
}

.cpuIcon {
  width: 15px;
  height: 15px;
}

.tooltip_name {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  align-items: center;
  padding: 8px 12px 8px 12px;
  display: flex;
  line-height: 15px;
  opacity: 70%;

  /* margin-left: 8px; */
}

.tooltip_css_boot {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: #2c2c2c;
}

/* table styling */

.tableData,
.table_class {
  min-width: 105px;
  white-space: nowrap;
  text-overflow: ellipsis;
  /* border: 1px solid #ddd; */
  padding: 8px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  border: none;
  text-align: right;
  border-bottom: 1px solid #f6f6f6;
}
.align__left {
  text-align: left;
}
.align__right {
  text-align: right;
}
.table_body {
  color: #212224;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  border-bottom: 1px solid #edeff2;
  padding: 8px;
}
.table_body_account {
  color: #0f2573;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  padding: 8px;

  border-bottom: 1px solid #edeff2;
}
.tFoot {
  color: var(--Dark, #212224);
  text-align: right;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  outline: none;
}

.totalCosts {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px;
  border-bottom: 1px solid #f6f6f6;
}

/*cost exp header*/

.heading {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  /* padding: 0px 10px */
}

.headingCostExplorer {
  display: flex;
  justify-content: space-between;
  align-items: CENTER;
}

.CEDIV {
  color: white;
  font-size: 21px;
  font-weight: 700;
  opacity: 50%;
}

.calmaindiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.calheading {
  color: var(--neutral-d-9, #f8f9fa);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}

.calsecheading {
  display: flex;
  padding: 3px 8px 4px 7px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #101d4f;
  position: relative;
}

.dropdown_triangle {
  display: flex;
  /* width: 12px;
  height: 12px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7.5px;
}

.statisticContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.ComboFirstDivInnerDiv {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  /* width: 280px; */
}

.TPComboDiv {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.ComboSECDivFirstDiv {
  color: var(--Dark, #333840);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.LDaysDiv {
  color: var(--neutral-d-5, #adb5bd);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ComboSECONDDiv {
  display: flex;
  align-items: flex-start;
  gap: 23px;
  align-self: stretch;
}

.ComboSECONDInnerDIv {
  display: flex;
  align-items: flex-start;
  gap: 21px;
}

.cost_service_header {
  display: flex;
  align-items: center;
  gap: 3px;
  align-self: stretch;
  width: 559px;
  height: 17px;
}

.cost_service_text {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.cost_header {
  display: flex;
  flex: 1 0 0;
  width: 100%;
  align-self: stretch;
  width: 100%;
}

.text_container {
  color: var(--neutral-d-4, #6c757d);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.number_container {
  color: var(--Dark, #333840);
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 24px;
}

.second_text_container {
  color: var(--neutral-d-4, #6c757d);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.cost_service_data {
  /* width: 149px;
  height: 131px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.main_div_header {
  display: flex;
  min-width: 115px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.icon_text_container {
  gap: 8px;
  display: flex;
  align-items: center;
}

.inner_main_header {
  padding: 0px, 0px, 0px, 24px;
  gap: 4px;
  display: flex;
  padding-left: 0px;
  align-items: flex-end;
  line-height: normal;
}

.numb_dropdown_container {
  padding: 0px, 0px, 2px, 0px;
  gap: 4px;
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  gap: 4px;
}

.inner_container_data {
  display: flex;
  min-width: 115px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding-top: 28px;
}

.second_inner_contianer_data {
  gap: 4px;
  display: flex;
  line-height: normal;
  align-items: flex-end;
  margin-left: 24px;
}

.second_dropDown_container {
  /* width: 53px;
  height: 18px; */
  padding: 0px 0px 2px 0px;
  gap: 4px;
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  gap: 4px;
}

.cost_last_div {
  border-radius: 0px 8px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--Success, #3da64f);
}

.cost_last_inner_div {
  padding: 21px 24px 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  gap: 13px;
}

.last_inner_first {
  gap: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.first1_inner_first_cont {
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.last_dropdown_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

/*tabs styling*/

.tabsDateContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.twoTabsContainer {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.first_btn_combo_container {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px 4px 0px 0px;
  background: #fff;
}

.second_btn_combo_container {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px 4px 0px 0px;
  background: rgba(255, 255, 255, 0.18);
}

.text_first_btn_combo_container {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.text_second_btn_combo_container {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.8;
}

.updatedDate {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  opacity: 50%;
}

/*Month component in header*/
.period_cost {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.preceding_header {
  color: var(--neutral-d-5, #adb5bd);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.preceding_text {
  color: #495057;
  font-size: 13px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}

.preceding_cost {
  color: var(--neutral-d-2, #343a40);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.card_styling {
  padding: 4px 8px;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.15px;
  text-align: left;
  color: rgba(33, 34, 36, 1);
  border: none;
  border-radius: 4px;
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modal_title1 {
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.73px;
  text-align: left;
  color: rgba(73, 80, 87, 1);
}

.tableBodyStyling {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  /* padding: 4px 0px; */
  color: #495057;
}

.Tablefooter {
  background-color: #f8f9fa;
  padding: 4px 8px;
  text-align: right;
  font-weight: 600;
}

.sticky_head {
  position: sticky;
  left: 0px;
}
.sticky_service_total {
  position: sticky;
  left: 100px;
}

.legends_Style ul {
  overflow-y: scroll;
  height: 46px;
  margin-top: 15px !important;
  scrollbar-width: none; /* For Firefox */
  padding-left: 20px !important;
}

.legends_Style ul::-webkit-scrollbar {
  display: none; /* For WebKit browsers */
}

/*Usage Details stylesheet*/
.usage_error_main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.mainContainerUsage {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: #f8f9fa;
  /* padding-bottom: 0px !important; */
  /* height: 100% !important; */
  background-color: #f8f9fa;
  /* padding-bottom: 0px !important; */
  /* height: 100% !important; */
}

.usageContainer {
  width: 100%;
  /* padding: 24px 24px 0px 24px; */
  /* padding: 24px 24px 0px 24px; */
}

.breadCrumb a {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0px;
  text-decoration: none;
  color: #495057 !important;
}

.breadCrumb a:active {
  color: #056ee5;
}

.headerComponent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.calendarStyle {
  display: flex;
  justify-content: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  gap: 8px;
  /* position: absolute;
  left: 32.5%;
  left: 32.5%; */
  right: 0;
}

.cloudStyle {
  background-color: #edeff2;
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  gap: 24px;
}

.downloadButton {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  color: #212224;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.downloadButton {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  color: #212224;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.serviceDivStyle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.serviceStyle {
  font-family: Inter;
  font-size: 17px;
  font-weight: 500;
  line-height: 20.57px;
  text-align: left;
  color: #495057;
}

.serviceNameStyle {
  font-family: Inter;
  font-size: 26px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #0f2573;
}

.costStyle {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 24.2px;
  text-align: center;
  color: #343a40;
}
.category_name {
  color: #797b80;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  text-wrap: nowrap;
}
.textOverFlowStyle {
  text-wrap: nowrap;
  /* max-width: 218px; */
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* --------------usage bar css-------------------- */
.bar_main {
  width: 100%;
  padding: 12px 4px 12px 4px;
  gap: 16px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #f8f9fa;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cost_trend {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
  color: #212529;
  padding-left: 8px;
}
.last_month {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: right;
  color: #adb5bd;
}

/* --------collapsible table css--------- */

.collabsibleTable {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  justify-content: center;
  padding-left: 24px;
  padding-right: 24px;
  background-color: #fff;
  border-radius: 8px;
  padding: 12px;
}

.tableStyle {
  table-layout: fixed;
  width: 100%;
  border-collapse: collapse;
}

.tableStyle td {
  border: 1px solid #ccc;
  padding: 0.5rem;
}

.collapsibleTableHead {
}
.col_header {
  padding: 0px 8px 0px 8px;
  gap: 8px;
  /* border: 0px 0px 1px 0px; */
  border: none;
  background: #edeff2 !important;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #212224;
}
.tr_head_css {
  border: none;
}
.collapse_tr {
  border-bottom: 1px solid #edeff2;
}
.table_head_css {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
  color: #212224;
}
.side_align {
  text-align: right;
}

/**Tag and Untagged component*/

.accordionTags button::after {
  /* display: none; */
  /* height: 6px;
  width: 12px; */
  /* width: 10%; */
  color: #056ee5;
}

.accordionTags button {
  background-color: #f8f9fa;
}

.accordionTags button:hover {
  z-index: 0;
}

.accordionTags button:focus {
  z-index: 0;
}

.accordionTags button:not(.collapsed) {
  background-color: #f8f9fa;
}

.accordionHeader {
}

.tagStyle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
  color: #495057;
}

.hideStyle {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: left;
  color: #056ee5;
  /* background-color: #fff !important; */
  border: 1px solid #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transition: none;
}

.hideStyle:hover {
  color: #056ee5;
  border: none;
  background-color: #fff;
  transition: none;
}

.accordionBody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* gap: 16px; */
  padding: 0px;
}

.tagDiv {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: start;
  width: 100%;
}

.tagTitleStyle {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #495057;
}

.costStyle {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 24.2px;
  text-align: center;
  color: #212224;
}

.tagDivStyle {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: start;
  width: 100%;
  /* padding: 24px 24px 0px 24px; */
}

.totalPercentage {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 18.15px;
  text-align: start;
  color: #797b80;
  display: flex;
  align-items: start;
}

.totalPercentageDiv {
  display: flex;
  /* justify-content: flex-start; */
  align-items: center;
  text-align: start;

  gap: 8px;
}

.contributionDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topContibutors {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #f8f9fa;
  min-height: 135px;
  /* padding: 24px 24px 0px 24px; */
}

.totalContributorText {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #495057;
  margin-bottom: 8px;
}

.costContributors {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

.totalCostPercentage {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #495057;
}

.errohandling {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.adding_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.check_th:hover .sorting_data {
  display: inline-block;
}
.check_th {
  position: relative;
}

.sorting_data {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: none;
}
.filter_search_text {
  color: #343a40;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
}
.filter_search_text_extras {
  margin-left: 4px;
  color: #343a40;
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  line-height: 13.31px;
  text-align: left;
  background: #ced4da;
  padding: 2px 8px 2px 8px;
  gap: 10px;
  border-radius: 4px;
}
.show_data {
  left: 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: pointer;
}

.selected_values {
  /* margin-top: 20px; */
  padding: 4px 8px 4px 8px;
  gap: 8px;
  border-radius: 12px;
  background: #f1f6ff;
}
.title_data {
  color: #343a40;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}
.title_css {
  color: #212224;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}
.sidebarButtons {
  display: flex;
  padding: 4px var(--Corner-Radius-8, 8px);
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  cursor: pointer;
  background: var(--Header_bg, #edeff2);
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
