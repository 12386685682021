.calendar_button_container {
  position: relative;
  padding: 0px;
}

.calendar_button {
  padding: 6px 10px 7px 11px;
  background-color: #101d4f;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
}

.calendar_dropdown {
  position: absolute;
  top: calc(100% + 5px);
  right: 0px;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  border: none;
  border-radius: 8px;
  padding: 12px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.month_set {
  display: flex;
  flex-direction: column;
}

.month_option {
  cursor: pointer;
  padding: 5px;
  color: #797b80;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: center;
  background-color: 1px solid;
  padding: 4px 32px 4px 32px;
  border: 1px solid #f8f9fa;
}

.month_option:hover {
  background-color: #f0f6ff;
}

.month_option.selected {
  background-color: #3d91ff;
  color: #fff;
  border-radius: 4px;
}
.calendar_header {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
  gap: 32%;
}
.current_year {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #343537;
}
.apply_button {
  display: flex;
  align-items: center;
  width: 32%;
  padding: 6.43px 19.29px 6.43px 19.29px;
  gap: 8.04px;
  border-radius: 4px;
  background-color: #056ee5;
  font-family: Inter;
  font-size: 12.86px;
  font-weight: 500;
  line-height: 15.56px;
  text-align: left;
  color: #fff;
  border: none;
  margin-left: 34%;
}
