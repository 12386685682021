.chart-main_Cont-sec{
    display: flex;
    width: 1300px;
    flex-direction: row;
    align-items: flex-start;
    gap: 24px;
}

.chart-main_Cont{ 
    display: flex;
    width: 1300px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
}


.text_Cont{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    padding: 0px;
}

.text-css{
    color: #495057;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.long-content-css{
    color: var(--neutral-d-3, #495057);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 20px;
align-self: stretch;
padding: 0px;
}

.sec_cont{
    display: flex;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
}

.chart-main_Cont{
    display: flex;
align-items: flex-start;
gap: 24px;
}


.aws-main-Cont{
    width: 32%;
    display: flex;
padding: 20px;
flex-direction: column;
align-items: center;
gap: 16px;
border-radius: 8px;
border: 1px solid var(--neutral-d-7, #DEE2E6);
background: var(--White, #FFF);
}

.aws-chart-cont{
    display: flex;
align-items: center;
/* gap: 36px; */
justify-content: space-between;
}

.aws-text-cont{
    display: flex;
flex-direction: column;
align-items: center;
gap: 8px;
/* align-self: stretch; */
}

.aws-logo{
    /* display: flex; */
/* height: 36.25px; */
/* padding: 4.892px 54.636px 4.775px 0px; */
/* align-items: center; */
/* align-self: stretch; */
}

.logo-css{
    width: 45.364px;
height: 26.583px;
}

.aws-savings{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
}

.saving-text{
    color: #070707;
     font-size: 15px;
     font-family: Inter,sans-serif;
     font-weight: 700;
     word-wrap: break-word
}

.savings-first-value{
    color: var(--Dark, #212224);
font-family: Inter;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 120%;
}

.savings-second-value{
    color: var(--Dark, #212224);
font-family: Inter;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 120%;
}

.chart-cont-aws{
    display: flex;
flex-direction: column;
align-items: center;
gap: 27px;
}

.chart-image{
    width: 123px;
    height: 123px;
}

.aws-sec-cont{
    display: flex;
    padding: var(--Corner-Radius-8, 8px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Corner-Radius-8, 8px);
    flex: 1 0 0;
    border-radius: var(--Corner-Radius-8, 8px);
background: var(--neutral-d-9, #F8F9FA);
}


.range-downsize-cont{
    display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 86px;
align-self: stretch;
}

.range-line{
    height: 5px;
align-self: stretch;
background: #3DA64F;
}

.downsize-value-cont{
    display: flex;
justify-content: space-between;
align-items: center;
align-self: stretch;
}

.Downsize-cont{
    color: #000;

font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.doensize-value{
    color: #000;

text-align: right;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.saving-avail-cont{
    display: flex;
/* align-items: flex-start; */
/* gap: 10px; */
align-self: stretch;
justify-content: space-between;
}

.sav-text{
    color: var(--neutral-d-4, #797B80);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 120%;
display: flex;
align-items: center;
}

.sav-val-cont{
    color: var(--Success-Text, #127E24);
text-align: right;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.sav-val-cont1{
    color: var(--Success-Text, #3DA64F);
text-align: right;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.current-cost-div{
    display: flex;
/* align-items: flex-start; */
gap: 10px;
align-self: stretch;
justify-content: space-between;
}

.cur-cost-text{
    color: var(--neutral-d-4, #797B80);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 120%;
}

.cur-cost-val{
    color: var(--Dark, #212224);
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.ri-saving-cont{
    color: #000;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.skelton-main-div{
    display: flex;
width: 299px;
padding: 20px;
flex-direction: column;
align-items: center;
gap: 16px;
border-radius: 8px;
border: 1px solid var(--neutral-d-9, #F8F9FA);
background: var(--White, #FFF);
}


/* RightsizingSkelton.css */
.rightsizing-skelton {
    width: 100%;
    height: 200px; /* Adjust height as needed */
    background-color: #ddd; /* Placeholder color */
    margin-top: 16px; /* Adjust margin as needed */
    margin-bottom: 16px; /* Adjust margin as needed */
  }
  
  .skelton-row {
    display: flex;
    justify-content: space-between;
  }
  
  .skelton-item {
    width: 30%;
    height: 20px; /* Adjust height as needed */
    background-color: #ccc; /* Placeholder color */
    margin-top: 8px; /* Adjust margin as needed */
  }
  

  /* RightsizingSkelton.css */
.rightsizing-skelton {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  
  .skelton-item {
    width: 100%;
    height: 20px; /* Adjust height as needed */
    background-color: #ccc; /* Placeholder color */
    margin-top: 8px; /* Adjust margin as needed */
  }
  
  .skelton-pie-chart {
    width: 100%;
    height: 180px; /* Adjust height as needed */
    background-color: #ddd; /* Placeholder color */
    margin-top: 8px; /* Adjust margin as needed */
  }

  
.line-vertical{
    border-bottom: 1px solid rgba(222, 226, 230, 1);
    height: 1px;
    width: "100%";
}

