/* ---------------search components------------------------------ */
.main_div {
  box-shadow: 0px 1px 0px 0px #0000000d;
  background: #f8f9fa;
  padding: 12px 0px 12px 0px;
  gap: 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: start;
  /* margin: 100px 30px 50px 70px; */
  width: 100%;
}
.first_data {
  padding: 8px 16px 8px 24px;
  gap: 16px;
  border: 0px 1px 0px 0px;
}
.search_first_part {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  position: relative;
  max-width: 40%;
}
.col_header {
  padding: 0px 8px 0px 8px;
  gap: 8px;
  border: none;
  background: #edeff2 !important;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #212224;
}
.side_align {
  text-align: right;
}
.category_name {
  color: #495057;
  font-family: Inter;
  font-size: 17px;
  font-weight: 500;
  line-height: 20.57px;
  text-align: left;
}
.service_name {
  color: #0f2573;
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  line-height: 32px;
  text-align: left;
}
.cost_data {
  color: #127e24;
  font-family: Inter;
  font-size: 20px;
  font-weight: 800;
  line-height: 20px;
  text-align: left;
}
.align_center {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}
.total_cost_value {
  color: #212224;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.green_box {
  color: #127e24;
  font-family: Inter;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.31px;
  text-align: left;
  background: #14c5411f;
  padding: 2px 4px 3px 5px;
  gap: 10px;
  border-radius: 2px;
}
.red_boxx {
  color: #d01a1a;
  font-family: Inter;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.31px;
  text-align: left;
  background: #faebeb;
  padding: 2px 4px 3px 5px;
  gap: 10px;
  border-radius: 2px;
}
.average_data {
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.73px;
  text-align: left;
}
.average_name {
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
  color: #6c757d;
}
.search_first_part:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  height: 90px;
  width: 1px;
  background-color: #e9ecef;
}
.search_first_part:first-child:before {
  display: none;
}
.search_component {
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}
.bar_div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.costContainer {
  display: flex;
  width: 74%;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 16px;
  /* padding: 12px 4px 12px 4px; */
}
.bar_table_css {
  display: flex;
  flex-direction: column;
  gap: 16px;
  /* width: 74%; */
  width: 100%;
  padding: 12px 4px 12px 4px;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}
.body_section {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-start;
}
.costSpendSection {
  display: flex;
  width: 100%;
  padding: 12px 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: var(--Corner-Radius-8, 8px);
  border: 1px solid var(--Neutral-D8, #e9ecef);
}

.bar_table_sidebar_summery {
  display: flex;
  /* width: 370px; */
  width: 25%;
  padding: 12px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: var(--Corner-Radius-8, 8px);
  border: 1px solid var(--Neutral-D6, #ced4da);
  background: #fff;
}
.costServiceRow {
  display: flex;
  justify-content: flex-start;
  flex-direction: "row";
  align-items: center;
  gap: 16px;
  padding: 0px var(--Corner-Radius-8, 8px);
}
.costRowCss {
  display: flex;
  justify-content: flex-start;
  flex-direction: "row";
  align-items: center;
  gap: 4px;
}
.rowTitle {
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.filterServices {
  color: var(--Charts-Blue, #056ee5);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
#filterServices_button {
  color: var(--Charts-Blue, #056ee5);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background-color: white;
  border: none;
  padding: 0px;
}
#filterServices_button::after {
  display: none;
}
.costServiceGraphContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.sidebarTitle {
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.cost_trend {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
  color: #495057;
  padding-left: 4px;
}
.check_th {
  color: #212224;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  padding: 8px;
  background: #f6f6f6;
}
.right_aligned {
  text-align: right;
}
.check_td {
  border-bottom: 1px solid #edeff2;
  padding: 8px;
  gap: 10px;
}
.td_data_css {
  color: #056ee5;
  text-decoration: underline;
}
.search_result {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.selected_values {
  /* margin-top: 20px; */
  padding: 4px 8px 4px 8px;
  gap: 8px;
  border-radius: 12px;
  background: #f1f6ff;
}

.LDaysDiv {
  color: var(--neutral-d-5, #adb5bd);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.title_data {
  color: #343a40;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}
.title_css {
  color: #212224;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}
.search_name {
  color: #495057;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
}
.filter_maindiv {
  display: flex;
  justify-content: space-between;
}
.filtered {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}
.filter_search_text {
  color: #343a40;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
}
.filter_buttons {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  display: flex;
  align-items: center;
  background: #f8f9fa;
  border: 1px solid #ced4da;
  padding: 6px 10px 7px 11px;
  border-radius: 4px;
}
.filter_search_text_extras {
  margin-left: 4px;
  color: #343a40;
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  line-height: 13.31px;
  text-align: left;
  background: #ced4da;
  padding: 2px 8px 2px 8px;
  gap: 10px;
  border-radius: 4px;
}
#dropdown_item_button {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  display: flex;
  align-items: center;
  color: var(--Dark, #343a40);
  background: #f8f9fa;
  border: 1px solid #ced4da;
  padding: 6px 10px 7px 11px;
  border-radius: 4px;
}
#dropdown_item_button {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  display: flex;
  align-items: center;
  color: var(--Dark, #343a40);
  background: #f8f9fa;
  border: 1px solid #ced4da;
  padding: 4px 8px;
  border-radius: 4px;
}
.dropdown_item {
  color: #000 !important;
  padding: 2px 6px !important;
  font-size: 12px;
  font-weight: 550;
}
.clear_div {
  border: 1px solid #e9ecef;
  background: #ffffff;
  padding: 6px 8px 7px 8px;
  gap: 10px;
  border-radius: 4px;
  color: #d01a1a;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}
.select_date {
  color: #343a40;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: right;
}
.calendar_cssed {
  color: #ffffff;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  border: 1px solid #0f2573;
  background: #0f2573;
  padding: 3px 10px 3px 11px;
  gap: 10px;
  border-radius: 4px;
}
.download_csv {
  border: 1px solid #ced4da;
  background: #ffffff;
  padding: 6px 10px 7px 11px;
  gap: 10px;
  border-radius: 4px;
  color: #212224;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
}
.adding_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}
.check_th:hover .sorting_data {
  display: inline-block;
}
.check_th {
  position: relative;
}

.sorting_data {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: none;
}

.graphY_axis {
  color: var(--Neutral-D3, #495057);
  text-align: right;
  font-family: Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-wrap: balance;
}
.graphX_axis {
  color: #a3a3a3;
  text-align: center;
  font-family: Helvetica;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sidebarButtonContainer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 4px;
  box-sizing: border-box;
  margin-top: 16px;
}
.sidebarButtons {
  display: flex;
  padding: 4px var(--Corner-Radius-8, 8px);
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 2px;
  cursor: pointer;
  background: var(--Header_bg, #edeff2);
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.sidebarButtonsActive {
  color: var(--Charts-Color-10, #fff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: var(--Dark-Blue, #0f2573);
}
.selectedButtonSummery {
  display: flex;
  width: 100%;
  padding: 4px var(--Corner-Radius-8, 8px) var(--Corner-Radius-8, 8px)
    var(--Corner-Radius-8, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.textRendering {
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.textCount {
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
}
.selectedTabDiscriptionContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  /* justify-content: flex-start; */
  /* gap: 27px; */
  align-items: center;
  align-self: stretch;
  flex-grow: 1;
}
.selectedTabRightSizing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.verticalLine {
  width: 1px;
  height: 100%;
  background: #e9ecef;
}
.selectedTabRightSizingHeading {
  text-align: center;
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.selectedTabRightSizingCount {
  text-align: center;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.selectedTabRightSizingSavings {
  color: var(--Success-Text, #127e24);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.selectedTabRightSizingCosting {
  color: #d01a1a;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.detailsContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.detailsContainerHeader {
  display: flex;
  padding: var(--Corner-Radius-8, 8px) 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
  background: var(--Neutral-D9, #f8f9fa);
}

.detailsContainerHeading {
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.detailsContainerBody {
  display: flex;
  padding-bottom: 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #e9ecef;
}
.detailsCurrent {
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.detailsCurrentCost {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
}
.detailsCurrentCostValue {
  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.sideSearch {
  padding: 24px;
  background: white;
  position: fixed;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  width: 45%;
  z-index: 100;
}
.InnerSearchDiv {
  display: flex;
  gap: 8px;
}
.searchHeading {
  font-weight: 500;
  font-size: 12px;
}
.searchOffcanvas {
  display: flex;
  gap: 16px;
  width: 100%;
}
.showLessResult {
  color: #056ee5;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
}
.searchrows {
  padding: 4px 8px 4px 8px;
  display: flex;
  gap: 8px;
  border-radius: 4px;
  width: 100%;
  flex-direction: column;
}
.searchrows:hover {
  background: #e6eefa;
}

.rgNameData {
  color: #797b80;
  font-weight: 400;
  font-size: 10px;
}

.MainSearchDiv {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  height: 100vh;
  margin-top: 60px;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.searchBarButton {
  margin-left: -10px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.searchHrLine {
  background: #dee2;
  margin: 0px;
  color: #dee2e6;
}
.overlayContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
}
.reource_tags {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.resource_collapse {
  display: flex;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}
.tags_text_align {
  text-align: left;
}
.table_overflow {
  overflow-y: scroll;
  max-height: 500px;
}

.stickyHeader {
  background-color: #f6f6f6;
  position: sticky;
  top: 0;
}

.mySpinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.trucate_css_in {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.resorce_id_name {
  color: #343a40;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
}
.resource_class {
  color: #797b80;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
}
.search_breadCrumb a {
  color: #056ee5 !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0px;
  text-decoration: none;
}
.trucated_resource {
  max-width: 310px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.accordianHeader button {
  padding: 0px !important;
}

.textOverFlowStyle {
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 160px;
  text-align: left;
}
.search_trucate {
  text-wrap: nowrap;
  overflow: hidden;
  max-width: 170px;
  text-overflow: ellipsis;
}
.Tags_column {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  gap: 12px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e9ecef;
}
.headder {
  color: #797b80;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
}
.haeder_values {
  color: #343a40;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18.15px;
  text-align: left;
}
