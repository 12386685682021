.add_custom_new_div {
  display: inline-flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  background-color: #fff;
}

.new_custom_widget {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px; /* 93.75% */
  display: flex;
  padding: 36px 24px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.custom_widget_values {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
}

.name_input_box_css {
  display: flex;
  /* height: 58px; */
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
  align-self: stretch;
}

.title_name {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
}

.error_message {
  color: red;
}

.filters_css {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.filter_name_div {
  display: flex;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.filter_name {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.filter_select_div {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.filter_select_css {
  border-radius: 3px;
  display: flex;
  align-items: center;
  align-self: stretch;
}

.filter_dimension_toggle {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  background-color: #F0F6FF;
  padding: 4px 8px;
  text-align: left;
  cursor: pointer;
  color: #056EE5;
}

.filter_dimension_toggle_icon {
  margin-left: 4px;
  margin-right: 8px;
}

.filter_dimension_toggle_icon path {
  fill: #056EE5;
}

.filter_dimension_toggle_selected {
  background-color: transparent;
  padding-left: 4px;
  padding-right: 4px;
  color: #212224;
}

.buttons_div {
  border-top: 1px solid var(--neutral-d-8, #e9ecef);
  display: flex;
  padding: 16px 24px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
}

.placeholder_text {
  font-size: 13px;
  color: #ADB5BD;
}

.placeholder_text_filter {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #212224;
}

.formControl::placeholder{
  color: #ADB5BD;
  opacity: 1; 
}




.chip {
  align-items: center;
  background: #f0f6ff;
  border-radius: 1px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.chip_content {
  flex: 1;
  min-width: 0;
}

.btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0px;
  text-align: left;
  padding: 8px 20px;
  border-radius: 4px;
}

.save_btn {
  background-color: #056EE5;
}

.cancel_btn {
  border-color: #ADB5BD;
  color: #797B80;
}

.delete_btn {
  color: #D01A1A;
  border-color: #D01A1A;
}

.delete_btn:hover {
  background-color: #D01A1A;
}

.remove_icon:hover path {
  stroke: #D01A1A;
}