/*------------------------------------------- custom tooltip css ----------------------------------------- */
.custom-tooltip {
  background-color: black;
  border: 1px solid #000;

  border-radius: 8px;
  width: 240px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}

.custom-tooltip table {
  width: 100%;
}

.tooltip .arrow::before {
  left: 50% !important;
  transform: translateX(-50%) !important;
}


.custom-tooltip th {
  color: white;
  /* check it out */
  padding: 8px;
  align-items:flex-start;
  color: var(--nn-4, #a09fa5);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.cloud-cell {
  width: calc(50% - 20px);
  color: var(--Background-module, #f0f0f0);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  padding-left: 12px;
}

.cost-cell {
  width: calc(50% - 20px);
}
tr.horizontalline_tool {
  border-bottom: 1px solid #373737;
}

.spacer-cell {
  width: 55px;
}

.cost-cell {
  color: var(--neutral-d-9, #f8f9fa);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
}

.custom-tooltip td {
  padding: 8px;
  color: white;
  align-items: flex-start;
}
.cloud-logo {
  width: 16px;
  height: 16.5px;
  flex-shrink: 0;
  margin-right: 8px;
  align-items: flex-end;
  /* margin-top: -30px; */
}
.tooltip_date_css {
  color: var(--neutral-d-7, #dee2e6);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  display: flex;
  padding: 0px 12px;
  align-items: flex-start;
  gap: 10px;
}
.tooltip_date {
  display: flex;
  padding-top: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  background: #2c2c2c;
  padding-bottom: 12px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;

  border-bottom: 1px solid #373737;
}
/* -------------------------------------------indvidualCostGraph.css-------------------------------------------------------- */
.all_cloud_text1 {
  color: #495057;
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.last_days_name1 {
  color: var(--neutral-d-5, #adb5bd);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
 
  padding-top: 4px;
}
.icc_main_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  /* margin-left: 40px; */
}
.graph_main_div {
  display: flex;
  justify-content: flex-start;
  gap: 48px;
  margin-left: 1px;
  margin-right: 0px;
}
.cost_graph {
  display: flex;
  padding: 16px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-d-8, #e9ecef);
  background: #fff;
}
.aws_costs_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}
.aws_cost_name {
  color: #495057;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.aws_cost_value1 {
  color: var(--Neutral-D2, #343A40);
font-family: Inter;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: 120%; /* 28.8px */
}
.buttons_css {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

/* ---------------------------------css for the cost&usagelayover screen--------------------------------------------  */
.costusage_main_div {
  display: inline-flex;
  /* height: 1320px; */
  height: 100vh;
  overflow-y: scroll;

  /* min-width: 449px; */
  padding: 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  background: var(--White, #fff);
}
.costusage_first_half {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}
.aws_name_css {
  color: #495057;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.AWS_value_css {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 43.2px */
}
.costusage_second_half {
  display: flex;
  width: 458px;
  height: 1042px;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: flex-start;
  flex-shrink: 0;
}
.service_download_css {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.service_box {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  padding: 3px 8px 4px 7px;
  align-items: center;
  gap: 10px;
}
.services {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.drop_down_icon {
  /* width: 8px;
height: 4px; */
  fill: var(--Dark, #212224);
  align-items: center;
}
.download_box {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.download_icon {
  /* width: 12px;
  height: 12px;
  align-items: start; */
}
.download_name {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.graph_values {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}
.compute_css {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.compute_header {
  border-radius: 4px;
  background: rgba(231, 192, 103, 0.1);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.compute_legends {
  border-radius: 2px;
  background: #e7c067;
  width: 12px;
  height: 12px;
}
.compute_name {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex: 1 0 0;
}
.compute_total_name {
  color: var(--Dark, #212224);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  flex: 1 0 0;
}
.compute_table {
  gap: 8px;
  align-self: stretch;
  padding: 0px 8px 0px 28px;
}

td .table_css {
  color: var(--neutral-d-3, #495057);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-align: right;
}

/* ----------------------------------------css for the overlay screen------------------------------------------  */
.overlay_screen {
  position: fixed;
  top: 0;
  right: 0px;

  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  /* overflow-y: scroll; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 9999;
}
.overlay_screen ::-webkit-scrollbar {
  /* display: none; */
}
.network_legends {
  border-radius: 2px;
  background: #df8367;
  width: 12px;
  height: 12px;
}
.network_header {
  border-radius: 4px;
  background: rgba(223, 131, 103, 0.1);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.storage_legends {
  border-radius: 2px;
  background: #7fbaff;
  width: 12px;
  height: 12px;
}
.storage_header {
  border-radius: 4px;
  background: rgba(127, 186, 255, 0.1);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.storage_legends {
  border-radius: 2px;
  background: #ced4da;
  width: 12px;
  height: 12px;
}
.other_costs_header {
  border-radius: 4px;
  background: rgba(206, 212, 218, 0.1);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.containers_legends {
  border-radius: 2px;
  background: #6ea57c;
  width: 12px;
  height: 12px;
}
.containers_header {
  border-radius: 4px;
  background: rgba(110, 165, 124, 0.1);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.databases_legends {
  border-radius: 2px;
  background: #9d626e;
  width: 12px;
  height: 12px;
}
.databases_header {
  border-radius: 4px;
  background: rgba(157, 98, 110, 0.1);
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

/* -----------------------------servicetable-css------------------------------------- */
.table_main_container {
  /* margin-left: 0px; */
  /* margin-right: 0px; */
}
