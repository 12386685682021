/* -----------------------------main component--------------------------------------- */
.billing_comp {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
}
.bar_card {
  display: flex;
  justify-content: flex-start;
  gap: 24px;
  padding: 0px 24px 0px 40px;
}
.conatining {
  display: flex;
  justify-content: flex-start;
  /* margin-top: 24px; */
}
.tooltipbox {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}
tr.horizontalline_tool {
  width: 100%;
  padding-left: 24px;
}
/* ---------------------------------------collapse table-------------------------------------- */
.collapse_table {
  background-color: #f8f9fa;
}
.after_expand {
  font-weight: 400;
}
.td_padding_col {
  padding: 8px;
  border-bottom: 1px solid #edeff2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  text-wrap: nowrap;
}
.tr_striped:nth-child(odd) {
  background-color: #f8f9fa80;
}
.truc {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  text-wrap: nowrap;
}
.account_id_name {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.account_id {
  color: #495057;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  text-align: left;
}
.account_named {
  color: #212224;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
}

/* -------------------------------azure header--------------------------------------------- */
.data_bars_azure {
  background: #ffffff;
  padding: 24px 20px;
  gap: 8px;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  flex-direction: column;
  width: 25%;
}
.billed_box {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.total_charge {
  font-family: Inter;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.57px;
  text-align: left;
  color: #212224;
}
.billing_charge {
  color: #797b80;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
}
.billing_component {
  background: #f8f9fa;
  padding: 24px 20px;
  gap: 40px;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* flex: 1 1 0; */
  width: 60%;
}
.balance_name {
  color: #ffffff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.94px;
  text-align: left;
}
.credit_value {
  color: #ffffff;
  font-family: Inter;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.57px;
  text-align: left;
}
.third_boxed {
  width: 30%;
  background: #056ee5;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 24px 24px 16px 24px;
}
/* ------------------------header component------------------------------------ */
.main_containers {
  background: #0f2573;
  width: 100%;
  padding: 16px 40px 16px 40px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.trucate_value {
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 280px;
}
.billing_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.account_type {
  color: #f8f9fa;
  opacity: 50%;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: right;
}
.account_dropdown {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}
.header_dropdown {
  background: #101d4f;
  border-radius: 4px;
}
.selected_value {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #fff;
}
.billing_name {
  color: #ffffff;
  opacity: 50%;
  font-family: Inter;
  font-size: 21px;
  font-weight: 500;
  line-height: 25.41px;
  text-align: left;
}
.component_css {
  display: flex;
  justify-content: flex-start;
  gap: 0px;
  width: 100%;
  background-color: #dee2e6;
  border-radius: 8px;
}
.data_bars {
  background: #ffffff;
  padding: 20px;
  gap: 16px;
  border-radius: 8px 0px 0px 8px;
  display: flex;
  flex-direction: column;
  width: 30%;
}
.second_comp {
  background: #f8f9fa;
  padding: 42px 24px 24px 24px;
  gap: 24px;
  border-radius: 0px 8px 8px 0px;
  display: flex;
  /* justify-content: space-evenly;
   */
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 0;
  width: 60%;
}
.total_name {
  color: #343a40;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
}
.total_month {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #adb5bd;
}
.date_total {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}
.account_name {
  color: #797b80;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  text-wrap: nowrap;
}
.account_value {
  color: #212224;
  font-family: Inter;
  font-size: 26px;
  font-weight: 700;
  line-height: 31.47px;
  text-align: left;
}
.azzount_box {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
  /* margin-right: 80px; */
  /* width: 30%; */
}
.values_css {
  color: #212224;
  font-family: Inter;
  font-size: 26px;
  font-weight: 500;
  line-height: 31.47px;
  text-align: left;
}
.vertical_line {
  border: 1px solid #dee2e6;
  height: 55px;
}
.updated_date {
  color: #ffffff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  width: 150px;
  opacity: 50%;
}
.updated_box {
  display: flex;
  justify-content: flex-end;
}

/* ----------------------------credit balance---------------------------- */
.card {
  border: 1px solid #e9ecef;
  border-bottom: none;
  background: #dee2e6;
  border-radius: 0px 8px 8px 0px;
  padding: 24px 24px 12px 24px;
  /* max-width: 400px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.credit_name {
  color: #343a40;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
}
.soon_credit {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #797b80;
}
.expiring {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: flex-end;
}
.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.balances_box {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.estimated_bal {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}
.estimated_name {
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  color: #797b80;
  text-wrap: nowrap;
}
.estimated_values {
  font-family: Inter;
  font-size: 26px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #212529;
}
.current_values {
  color: #212529;
  font-family: Inter;
  font-size: 26px;
  font-weight: 700;
  line-height: 31.47px;
  text-align: left;
}

.balances {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.creditList {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.credit_list_name {
  color: #495057;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
}
.table_box {
  width: 100%;
  border-radius: 4px;
  /* padding: 8px; */
}

.table_bg {
  width: 100%;
  border-collapse: collapse;
}
.th_head {
  color: #797b80;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  text-wrap: nowrap;
}
.td_data {
  color: #212529;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}
.td_padding {
  padding: 4px 24px 6px 0px;
  gap: 10px;
  border: 0px 0px 1px 0px;
}

.table_bg td:nth-child(1) {
  max-width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table_bg tr:not(:last-child) .td_data {
  border-bottom: 1px solid #adb5bd66;
}
/* -------------------------billing details table--------------------------------------- */
.table_main_container {
  padding: 0px 24px 0px 40px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.billing_details {
  color: #495057;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
}
.download_icon {
  background: #e6eefa;
  padding: 0px 8px;
  gap: 6px;
  border-radius: 4px;
}

#customers {
  width: 100%;
}
#customers .th_data_billing {
  background: var(--Header_bg, #f6f6f6);
  padding: 8px;
  align-items: center;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: right;
  color: #212224;
}
.table_info_css {
  color: var(--Dark, #212224);
  text-align: right;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
  border: 1px solid white !important;
  text-align: right;
}
#customers .table_data {
  padding: 8px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  border-bottom: 1px solid #f6f6f6;
  text-align: right;
}
.table_alignment {
  text-align: left !important;
  width: 27%;
}
.mca_table_alignment {
  text-align: left !important;
  /* width: 27%; */
}
/* --------------------------bar graph css----------------------------------------- */
.graph_main {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
/* -----------------------------credit modal----------------------------------------- */
.modal_main_cont {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 16px;
  width: 100%;
}
.credit_modal_name {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: left;
  color: #212224;
}
.credit_with_icon {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
.credit_des {
  color: #212224;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;
}
.current_values_modal {
  font-family: Inter;
  font-size: 30px;
  font-weight: 700;
  line-height: 30px;
  text-align: left;
  color: #056ee5;
}
.current_name {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  color: #797b80;
}
.credit_list_box {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}
.account_name_css {
  color: #000000;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  text-align: left;
}
.account_value_css {
  color: #000000;
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  line-height: 13.2px;
  text-align: left;
}
/* ------------------------------------------------------tooltip------------------------------------------ */
.tooltip_tr_name {
  font-family: Inter;
font-size: 11px;
font-weight: 500;
line-height: 13.2px;
padding-left: 8px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #CED4DA;
}
.tooltip_cloud_name {
  font-family: Inter;
font-size: 13px;
font-weight: 400;
line-height: 15.6px;
text-align: left;
text-underline-position: from-font;
text-decoration-skip-ink: none;
color: #F0F0F0;
}
.tooltip_tr_value{
  color: #F8F9FA;
  font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 14.4px;
text-align: right;
text-underline-position: from-font;
text-decoration-skip-ink: none;

}
.tooltip_row:not(:last-child) {
  border-bottom: 1px solid #373737; 
}
.tooltip_table_flex {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  padding-top: 8px;
}
