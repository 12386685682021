 .recommended-css{
    display: flex;
    /* height: 40px; */
    padding: 10px var(--Corner-Radius-8, 8px);
    align-items: 'center';
    gap: var(--Corner-Radius-8, 8px);
    align-self: stretch;
    border-bottom: 1px solid var(--Neutral-D8, #E9ECEF);
}

/*.th-style{
    display: flex;
padding: var(--Corner-Radius-8, 8px);

align-items: center;
gap: 10px;
align-self: stretch;
background: var(--Header_bg, #F6F6F6);
color: var(--Neutral-D2, #343A40);
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.text-table{
    overflow: hidden;
color: var(--Dark, #212224);
text-overflow: ellipsis;
font-family: Inter;
font-size: 13px;
font-style: normal;
font-weight: 500;
line-height: normal;
} */