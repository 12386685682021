.periodCost {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
}

.costUsagesHeader {
  display: flex;
  flex-direction: row;
  /* padding-right: 11px; */
  justify-content: space-between;
  /* gap: 8px; */
}

.allCloudsTableBody {
  padding: 8px;
  border: none;
  color: var(--Dark, #212224);
  text-align: left;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.allCloudsServices {
  padding: 10px;
  border: none;
  color: var(--Dark, #212224);
  text-align: left;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.allCloudsTableHeader {
  padding: 8px;
  /* align-self: stretch; */
  background: var(--Header_bg, #f6f6f6);
  color: #000;
  text-align: left;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
}
.footer {
  padding: 8px;
  color: var(--Dark, #212224);
  text-align: left;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.successBadge {
  border-radius: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 4px;
  padding-left: 5px;
  background: rgba(20, 197, 65, 0.12);
  color: var(--Success-Text, #127e24);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}

.dangerBadge {
  border-radius: 2px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 4px;
  padding-left: 5px;
  background: rgba(208, 26, 26, 0.12);
  color: var(--Red, #d01a1a);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 5px;
  line-height: normal;
}
.totalCostHeader {
  color: var(--Dark, #333840);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
}

.filterContainer {
  /* border-left: 1px solid var(#dee2e6); */
  background: #fff;
  /* box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.04); */
  display: flex;
  padding: 16px 0px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  box-sizing: border-box;
}
.dimension_name_css {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
  box-sizing: border-box;
}

.dimension_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  box-sizing: border-box;
}
.service_dropdown_box {
  display: flex;
  width: 100%;
  padding: 8px 8px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  background: var(--surface-default, #fff);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
}

.totalCosts {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.lowerContent_numbers {
  color: #212224;
  font-size: 36px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}

.upperContent_texts {
  padding: 0px;
  color: #797b80;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}

.lowerContent_texts {
  padding: 0px 0px 1px 0px;
  gap: 4px;
  display: flex;
  margin-top: 28px;
}
.lowerContent_numbers {
  color: #212224;
  font-size: 36px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}
.lowerContent_text-1 {
  color: #797b80;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
}
/* ------------------------------------------------ */
#customers .table_data__Class,
#customers .th_class {
  width: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Neutral-D3, #212224);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #f6f6f6;
}
#customers .th_class {
  color: #000;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Header_bg, #f6f6f6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#multiselect {
  position: relative;
}

.placeholder {
  padding: 5px;
  color: var(--Neutral-D6, #ced4da);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}
.container {
  border-right: 1px solid #dee2e6;
  /* box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.04); */
}
.Dropdown_Toggel::after {
  content: none;
}
.custom_dropdown_toggle {
  padding: 4px 6px !important;
  border: none;
}

.dropdown_item {
  color: #000 !important;
  padding: 4px 6px !important;
  font-size: 12px;
  font-weight: 550;
}
#combinedCol,
.col-10 {
  width: 82.333333%;
}

.TotalCost {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.daysDifference {
  color: var(--Neutral-D5, #adb5bd);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.monthRes {
  color: var(--Neutral-D5, #adb5bd);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.previousResult {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.prevCost {
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 4px;
}

.filter_name_addsign {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.filter_name {
  padding:6px;
  color: var(--Dark, #212224);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

/* .dropdown{
  padding: ;
} */
