.pagination_div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.data_selection_container {
  /* width: 15%; */
  display: flex;
  align-items: center;
  gap: 8px;
}
.font_styling {
  color: #212529;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

#dropdown_item_button {
  border-radius: 4px;
  background-color: #e9ecef;
  display: flex;
  border: none;
  padding: 5px 8px 6px 7px;
  align-items: center;
  gap: 20px;
  color: var(--Dark, #495057);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.dropdown_item:hover {
  border-radius: 4px !important;
  background: var(--Controls-BG, #f0f6ff) !important;
}

.dropdown_item {
  color: #000 !important;
  padding: 4px 6px !important;
  font-size: 12px;
}
.pagination{
padding: 5px 0px 0px 0px !important ;
}
/* li.page-item.disabled{
  background-color: red !important;
} */

/* -------------------------------------------------------------------- */
/* .pagination .page-item .page-link {
  background-color: white;
  color: #5A4181;
}

.pagination .page-item .page-link:focus,
.pagination .page-item .page-link:hover {
  color: #5a5a5a;
  background-color: #eee;
  border-color: #ddd;
}

.pagination .page-item.active .page-link {
  color: white;
  background-color: #5A4181 !important;
  border: solid 1px #5A4181 !important;
}

.pagination .page-item.active .page-link:hover {
  background-color: #5A4181 !important;
  border: solid 1px #5A4181;
} */
