.all-notification-first__container.active-tab, .saving-Opportunities.active-tab, .anomaly-detections__Cont.active-tab {
 border-bottom: 1px solid #2660D4;
  background: var(--ceq-core-ceq-secondary-steel-grey-light, #F5F5F5);
}


.all-notification-first__container.active-tab button, .saving-Opportunities.active-tab button, .anomaly-detections__Cont.active-tab button {
    color: #2660D4;
    background: var(--ceq-core-ceq-secondary-steel-grey-light, #F5F5F5); /* Change this to your desired active text color */
}

.btn-color{
    border: none;
    background-color: transparent;
    /* border:"none";
    background-color: "transparent"; */
}

.main-notification__Container{
    position: absolute;
    top: 0%;
    right: 0%;
    display: inline-flex;
    height: 1399px;
    padding: 36px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    background: var(--White, #FFF);
    z-index: 2;
    width: 35%;
    /* position: absolute;
    top: 0;
    left: 500px; */
  }
  
  .notification-cross-icon{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  
  .notification-text{
    color: #000;
  
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  }
  
  .notification-cross__Img{
    width: 12px;
    height: 12px;
    
  }
  
  
  .all-notification__Container{
    display: flex;
    /* width: 100%; */
    /* align-items: flex-start; */
    /* width: 460px; */
    border-bottom: 1px solid var(--neutral-d-6, #CED4DA);
  }
  
  .all-notification-first__container{
    display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #2660D4;
  background: var(--ceq-core-ceq-secondary-steel-grey-light, #F5F5F5); */
  }
  
  .all-notification-first__container-text{
  /* color: #2660D4; */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  
  }
  
  .saving-Opportunities{
    display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  
  }
  
  .saving-Opportunities-text{
    color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  }
  
  .anomaly-detections__Cont{
    display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  }
  
  .anomaly-detections-text{
    color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
  }
  
  .anomaly-detections-value{
    display: flex;
  width: 2px;
  height: 2px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: var(--ceq-core-ui-palette-fire-engine-red, #D01A1A);
  }
  
  
  .anomaly-detections-value__container{
    color: var(--neutral-d-9, #F8F9FA);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 11px;
  }
  
  
  .amazone-cloud-watch__container{
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  }
  
  .amazone-cloud-watch{
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  }
  
  .amazone-cloud-watch-text{
    color: var(--charts-blue, #056EE5);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  }
  
  
  .notification-anomaly-savings__Div{
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  }
  
  .amazone-savings-missed__Cont{
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  }
  
  .amazone-cloud-watch{
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  }
  
  .amazone-cloud-watch-text{
    color: var(--charts-blue, #056EE5);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-self: stretch;
  }
  
  .line__DIV{
    stroke-width: 1px;
  stroke: var(--neutral-d-8, #E9ECEF);
  
  }
  
  .increase-cloudeq__DIV{
    display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  }
  
  .increase-value{
    color: var(--neutral-d-4, #797B80);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: stretch;
  }
  
  .increase-aws-value{
    color: #2660D4;
  align-self: stretch;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  }
  
  .increase-ec2-s3{
    color: var(--nn-6, #73737B);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  }
.notification_icon {
    display: flex;
    width: 44px;
    height: 44px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
   
  .notification_icon__Container{
    display: flex;
  width: 24px;
  height: 24px;
  padding: 2.5px 4px 2px 4px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  }
  
  .noti-icon-div{
    display: flex;
  width: 0px;
  height: 0px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: absolute;
  right: 105px;;
  /* bottom: 8px; */
  border-radius: 20px;
  border: 1px solid #203DA9;
  
  background: var(--ceq-core-ui-palette-fire-engine-red, #D01A1A);
  }
   
  .user_icon {
    /* display: flex;
    width: 38px;
    height: 44px;
    justify-content: center;
    align-items: center;
    gap: 10px; */
    display: flex;
  justify-content: flex-end;
  align-items: center;
  }
   
  .user_icon_Person-image__Container{
    display: flex;
    width: 50px;
    height: 44px;
    justify-content: center;
    align-items: center;
    gap: 10px;
   
  }
   
  .user_icon_Person-image__Container-Image{
    width: 36px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 100px;
  /* background: url('./assets/Rectangle.png'), lightgray 50% / cover no-repeat; */
  }
   
  .notification_icon-text{
    color: var(--neutral-d-9, #F8F9FA);
  text-align: center;
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 11px;
  position: absolute;
  right: 6px;
  bottom: 8px;
  border-radius: 20px;
  border: 1px solid #203DA9;
  background: var(--ceq-core-ui-palette-fire-engine-red, #D01A1A);
  }
   
  .user_icon-Arrow__Container{
    width: 24px;
  height: 24px;
  opacity: 0.5;
   
  }
   
  .notification_css {
    display: flex;
    width: 24px;
    height: 24px;
    padding: 2.5px 4px 2px 4px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #fff;
  }
  .user_css {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
    color: #fff;
  }
   
  .name{
    text-wrap: nowrap;
  }
   
   
  .content-container{
    display: flex;
    justify-content: flex-start;
    /* gap:46px */
  }