.custom_widget_topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  margin-bottom: 16px;
}

.custom_widget_name {
  color: #495057;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.add_new_widget {
  display: flex;
  padding: 6px 16px 6px 12px;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  border: none;
}

.add_widgte_svg {
  align-items: flex-end;
}

.add_widget_button {
  color: var(--neutral-d-3, #495057);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.graph_box1 {
  /* display: flex; */
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-d-8, #e9ecef);
  background: #fff;
  position: relative;
  padding-bottom: 16px;
  height:100%
}

.graph_box_topname_bar {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  padding: 16px;
}

.graph_box_names {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
}

.css_projectName_days {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
}

.finops_project_cost {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.days_name_css {
  color: var(--neutral-d-5, #adb5bd);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-top: 3px;
}

.cost_value {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.graph_icons {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  position: absolute;
  right: 16px;
  top: 16px;
}

.custom_widget_btn {
  background: #F0F6FF;
  border: none;
  padding: 0;
  border-radius: 4px;
  width: 24px;
  height: 24px;
}

.custom_widget_dropdown > a {
  font-size: 13px;
  display: flex;
  align-items: center;
}

.custom_widget_dropdown_toggle {
  position: relative;
  top: -1px;
}

.custom_widget_btn:hover {
  background: #F0F6FF;
}

.dropdown_icon {
  vertical-align: center;
  margin-right: 10px;
  width: 12px
}

.containerHeight {
  min-height: 340px !important;
  height: 100%;
}