.budget_main_container {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}
.budget_container {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 31px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}
#insetStyles{
  position: absolute !important;
  inset: 5px auto auto -8px !important;
  transform: translate3d(8px, 29.6px, 0px) !important; 
}
p {
  margin: 0;
}
.budgets_header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  /* background-color: red; */
}

.add_new_widgets {
  display: flex;
  padding: 8px 12px 8px 9px;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  border: 1px solid #056ee5 !important;
}

.add_widget_text {
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  color: #056ee5;
}

.budget_head {
  display: flex;
  flex-direction: column;
  gap: 4px;
  /* width: 750px; */
}

.head_text {
  color: #212224;
  font-size: 21px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  /* word-wrap: break-word; */
}

.head_paragraph {
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  /* word-wrap: break-word; */
}

.chart_conatiner {
  display: flex;
  gap: 20px;
  /* height:fit-content; */
  width: 100%;
  margin: 0px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.chart_card {
  width: 345px;
  padding: 20px;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  display: flex;
  height: fit-content;
  height: fit-content;
  flex-direction: column;
  gap: 16px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}
.chart_card.blank {
  visibility: hidden;
}
.chart_card.dragging {
  border: 2px dashed #007bff;
}

.chart_card.over {
  opacity: 0.8;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.sandbox-budget {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0px 0px 12px 0px;
  border: 0px 0px 1px 0px;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
}

.text_sandbox {
  color: #212224;
  font-size: 16px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  width: 220px;
  cursor: pointer;
  word-wrap: break-word;
}
.text_sandbox:hover {
  color: var(--Charts-Blue, #056ee5);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 19.2px */
}

.used_Container {
  width: 100%;
  display: flex;
  /* padding:8px; */
  justify-content: space-between;
}

.used_mainDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.usedDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.USed_text {
  color: #797b80;
  /* height: 14px; */
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  display: flex;
  align-items: center;
}

.usedPercentage {
  color: #212224;
  /* height: 38px; */
  /* max-width:  */
  font-family: Inter, sans-serif;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  color: var(--Dark, #212224);
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 120%; /* 33.6px */
}
.exceedingPercentage {
  color: #d01a1a;
}

.forcastPercentageColor {
  color: #127e24;
  background-color: #127e24;
}
.forcastPercentageExcededColor {
  background-color: #d01a1a;
}

/* .normalPercentage{
  color: #212224;
} */

.UsedCost {
  color: #212529;
  /* height: 18px; */
  font-size: 15px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  gap: 5px;
}

.forecast_container {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f8f9fa;
}

.forecast-head {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.forVSbudget {
  color: #495057;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  word-wrap: "break-word";
}

.budgetPercent_body {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.budget_percent {
  color: #d01a1a;
  font-size: 18px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  word-wrap: "break-word";
}

.progress_bar {
  border-radius: 0px;
  height: 8px;
}

.forecast_detail {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.forcast_text {
  color: #797b80;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}

.forecast_cost {
  color: #212224;
  font-size: 13px;
  font-size: "Inter";
  font-weight: 600;
  word-wrap: break-word;
}

.chartDetails {
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  gap: 12px;
  background-color: #f8f9fa;
}

.chart-info {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.detail-field {
  color: #797b80;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}

.detail-text {
  color: #212224;
  font-size: 13px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  text-transform: capitalize !important;
}

/* new Budgets form-------------------------------------------------------------------------------------------------------------- */
.budget_main_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  /* width: 710px; */
  background-color: white;
  /* overflow-y: scroll; */
  height: 100vh;
}

#createBudgetHeader,
.offcanvas-header {
  width: 100%;
  padding: 0px;
  display: flex;
  justify-content: space-between;
}
/* .cross_button{
  position:absolute;
  right:1px  

 } */

.offcanvas {
  --bs-offcanvas-width: 720px;
}
#createBudgetBody,
.offcanvas-body {
  /* padding:0px 24px; */
  padding: 0px;
  overflow-y: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  overflow: scroll;
  padding: 0px 24px;
}

/* .offcanvas-body::-webkit-scrollbar {
  display: none;
}

#createBudgetBody
.offcanvas-body {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
} */

.budget_first_box {
  display: flex;
  padding: 36px 24px 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-bottom: 1px solid var(--Neutral-D8, #e9ecef);
}

.create_budget {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.create_budget_name {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  /* line-height: 15px; 71.429% */
}

.create_budget_disc {
  color: var(--Neutral-D3, #495057);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}

/* .create_budget_second {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  display: flex;
  padding: 0px 24px;
} */

.budget_account_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Corner-Radius-8, 8px);
  width: 100%;
}

.budget_account {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  color: var(--Neutral-D1, #212529);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}

.budget_name {
  color: var(--Neutral-D1, #212529);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  /* line-height: normal; */
}

.budget_discription {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}

.accountid_box {
  display: flex;
  /* width: 45%; */
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}
.forecast_name {
  color: #056ee5;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}
.forecast_data {
  border: 1px solid #adb5bd;
  background: #f8f9fa;
  padding: 12px 16px 12px 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.period {
  color: #797b80;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
}
.forcasted_cost {
  color: #343a40;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  text-wrap: nowrap;
}
.forecast_value {
  color: #212224;
  font-family: Inter;
  font-size: 22px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.for_cast {
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: baseline;
}
.fore_casted {
  height: 37px;
  display: flex;
  justify-content: flex-start;
  gap: 5px;
  align-items: baseline;
  background: #056ee5;
  padding: 10px 16px 8px 16px;
  gap: 5px;
  border-radius: 4px;
}

.accountid_name {
  color: var(--Neutral-D4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  /* line-height: 20px; 153.846% */
  flex: 1 0 0;
}

.input_box_css {
  display: flex;
  height: 36px;
  width: 325px;
  padding: 0px var(--Corner-Radius-8, 8px);
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Neutral-D6, #ced4da);
  background: var(--Surface-Default, #fff);
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
}

::placeholder {
  flex: 1 0 0;
  color: var(--Neutral-D5, #adb5bd);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 20px; 153.846% */
}
.input_box_css.error {
  border: 1px solid red;
  background-color: #ffecec;
}
.input_box_css button span {
  text-overflow: ellipsis;
  width: 280px;
  overflow-x: hidden;
  text-wrap: nowrap;
  text-align: start;
}

.charge_select {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 17px;
  margin-top: 5px;
  align-items: center;
}

.semicolon_css {
  color: #606367;
  display: flex;
  padding-bottom: 10px;
  font-weight: 750;
  margin-left: 10px;
}

.astriek_css {
  color: #d9352a;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 20px; */
}

.learn_more_box {
  border-radius: 20px;
  background: rgba(74, 135, 255, 0.08);
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 64%;
}

.learn_more_css {
  color: var(--Numeric-Display-Dark, #343537);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
  word-wrap: normal;
}

.highlighted_name {
  color: var(--Blue---Progress-bar, #3574e0);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  /* line-height: normal; */
}

.button_box {
  margin-top: 20px;
  display: flex;
  padding: 16px 24px;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--Neutral-D8, #e9ecef);
}
.button_box_edit {
  margin-top: 20px;
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid var(--Neutral-D8, #e9ecef);
}

.delete_button {
  display: flex;
  padding: 9px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  border: 1px solid var(--Red, #d01a1a);
  background: var(--White, #fff);
  color: var(--Red, #d01a1a);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* line-height: normal; */
}

.cancel_button {
  display: flex;
  padding: 9px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-D5, #adb5bd);
  background: var(--White, #fff);
  color: var(--Neutral-D4, #797b80);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* line-height: normal; */
}

.create_button {
  border-radius: 4px;
  background: var(--Charts-Blue, #056ee5);
  display: flex;
  padding: 9px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  color: var(--White, #fff);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* line-height: normal; */
  border: 1px #056ee5;
}

.cancel_create_button {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}

.seperator {
  height: 20px;
  width: 1px;
  border: 1px solid rgb(156 163 175);
}

/* #filterComp . */

/* .add_widget_overlay_screens {
  position: fixed;
  top: 0;
  right: 0px;

  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 99999;
}

@keyframes slideIn {
  from {
    transform: translateX(0%);
    opacity: 0;
  }
  to {
    transform: translateX(100%);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(100%);
    opacity: 1;
  }
  to {
    transform: translateX(0);
    opacity: 0;
  }
}
/* 
.add_widget_overlay_screens {
  position: fixed;
  top: 0;
  right: 0px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 99999;
  /* animation-timing-function: ease-in-out; */
/* animation-fill-mode: forwards;  */
/* opacity: 0;
  /* transition: opacity 1.6s ease-in-out;  */

/* .add_widget_overlay_screens.slideIn {
  opacity: 1;
}

.add_widget_overlay_screens.slideOut {
  opacity: 0;
} */

/* ----------------------------------skeleton------------------------------------------------ */
.skeleton_container {
  width: 345px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #f8f9fa;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.skeleton_header {
  display: flex;
  width: 100%;
  padding: 0px 0px 12px 0px;
  border: 0px 0px 1px 0px;
  justify-content: space-between;
  border-bottom: 1px solid #dee2e6;
  /* height: 36px; */
  /* margin-bottom: 5px; */
}

.first_div {
  width: 64%;
  height: 20px;
  display: flex;
  justify-content: space-between;
  background-color: #f8f9fa;
  margin-top: 3px;
  /* margin-bottom: 5px; */
}

.sec_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 124px;
  /* margin-bottom: 4px; */
}

.three-contaiers {
  width: 126px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.first-one {
  width: 30px;
  height: 14px;
  display: flex;
  gap: 10px;
  background-color: #f8f9fa;
}

.sec-one {
  width: 69px;
  height: 34px;
  display: flex;
  gap: 10px;
  background-color: #f8f9fa;
}

.third-one {
  width: 126px;
  height: 18px;
  display: flex;
  gap: 10px;
  background-color: #f8f9fa;
}

.third_container {
  width: 100%;
  height: 108px;
  padding: 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: #f8f9fa;
}

.third-first {
  width: 254px;
  height: 56px;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.third-sec {
  width: 254px;
  height: 16px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.third-sec-1 {
  width: 54px;
  height: 16px;
  display: flex;
  background-color: #ffffff;
}

.third-first-1 {
  width: 139px;
  height: 16px;
  background-color: #ffffff;
}

.third-first-2 {
  width: 142px;
  height: 22px;
  background-color: #ffffff;
}
.third-first-3 {
  width: 254px;
  height: 8px;
  background-color: #ffffff;
}
.forth-container {
  width: 100%;
  /* height: 162px; */
  padding: 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #f8f9fa;
}
.forth-first {
  width: 254px;
  height: 16px;
  display: flex;
  justify-content: space-between;
  background-color: #f8f9fa;
}
.forth-first-div {
  width: 51px;
  height: 16px;
  background-color: #ffffff;
}

.add_new_widgets_container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.success {
  display: flex;
  width: 645px;
  padding: 12px 36px 12px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border-left: 6px solid #2f803d;
  background: var(--Success, #3da64f);
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.1),
    0px 30px 30px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05),
    0px 118px 47px 0px rgba(0, 0, 0, 0.01), 0px 185px 52px 0px rgba(0, 0, 0, 0);
}

.warning {
  display: flex;
  width: 645px;
  padding: 12px 36px 12px 16px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border-left: 6px solid #cc9929;
  background: #ffbf33;
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.1),
    0px 30px 30px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05),
    0px 118px 47px 0px rgba(0, 0, 0, 0.01), 0px 185px 52px 0px rgba(0, 0, 0, 0);
}

/* .error {
  display: flex;
  padding: 12px 36px 12px 16px;
  width: 645px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border-left: 6px solid #96222b;
  background: #e33341;
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.1),
    0px 30px 30px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05),
    0px 118px 47px 0px rgba(0, 0, 0, 0.01), 0px 185px 52px 0px rgba(0, 0, 0, 0); */
/* } */

.budgetsError {
  color: red;
  font-size: 10px;
}
.dropdown_Toggel::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown_menu {
  left: 0 !important;
  right: 0 !important;
  border-radius: 4px;
  box-shadow: 0px 12px 27px 0px #0000001a;
  overflow-y: hidden;
}

@media (max-width: 100%) {
  .chart_card {
    width: 23.8%;
  }
  .skeleton_container {
    width: 23.8%;
  }
}

@media (max-width: 1428px) {
  .chart_card {
    width: 32%;
  }
  .skeleton_container {
    width: 32%;
  }
}
@media (max-width: 1250px) {
  .chart_card {
    width: 47%;
  }
  .skeleton_container {
    width: 47%;
  }
}

@media (max-width: 768px) {
  .chart_card {
    width: 100%;
  }
  .skeleton_container {
    width: 100%;
  }
}
.cross_button:hover {
  cursor: pointer;
}
/* @media only screen and (max-width: 600px) {
  .budget_main_div {
    width: 100%;
  }
} */
/* 
@media (max-width: 1428px) {
  .mybudgetform {
    width: 50%;
  }
} */
/* @media (max-width: 1021px) {
  .mybudgetform {
    width: 100%;
  }
} */
.notification_bell:hover {
  cursor: pointer;
}
#button-tooltip-2 {
  padding: 0px 60px 0px 0px;
}

.dropdown-menu-custom {
  padding: 8px 12px;
  position: absolute;

  inset: auto auto -113px -178px !important;
  transform: translate3d(0px, -37.6px, 0px);
}
.budget_main_div::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.budget_main_div {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.custom-option {
  /* color:#96222b; */
  padding: 20px;
}

.custom-option:hover {
  background-color: red; /* Change to your desired hover background color */
  color: white; /* Change to your desired hover text color */
}

/* .bootstrap-select.btn-group .dropdown-menu.open {
  width: 250px !important;
  overflow: auto !important;
  background-color: red !important;
} */

/* #myControl .dropdown-menu.open {
  width: 250px !important;
  overflow: auto !important;
  background-color: red !important;
} */
.myClass {
  color: red;
  width: 200px;
  padding-top: 20px;
}

.myClass option {
  color: red;
  padding: 20px;
}

/* .bootstrap-select .dropdown-menu li a {
  padding: 20px !important;
}

.bootstrap-select .dropdown-menu li a:hover {
  background-color: white !important;;
} */

.radioButtonContainer {
  display: flex;
  gap: 10px;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 8px;
}
.filterScopeSelection {
  font-weight: 500;
  color: #797b80;
  font-size: 13px;
  margin-bottom: -15px;
}
.radioButtonSection {
  display: flex;
  gap: 4px;
  justify-content: flex-start;
  flex-direction: row;
}
