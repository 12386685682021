.modalContent {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  /* min-height: 350px; */
  /* height: 495px; */
  border-radius: 8px;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  flex-grow: 1;
}

.headerSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.tittle {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.tagline {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.inputFields {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  gap: 16px;
}
.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
  flex: 1;
}

#AcceptId {
  display: flex;
  padding: 9px 36.5px 10px 36.5px;
  justify-content: center;
  align-items: center;
  /* gap: 3px;
  flex: 1; */
}
.cancleButton {
  border-radius: 4px;
  border: 1px solid var(--Neutral-D5, #adb5bd);
  background: var(--White, #fff);
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  text-align: left;
  text-underline-position: from-font;
}
.highlightedRow {
  background-color: #e6eefa !important;
}

.submitButton {
  border-radius: 4px;
  background-color: #056ee5;
  border: 1px solid #056ee5;
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  text-align: left;
  text-underline-position: from-font;
}

.recHistoryTittle {
  color: var(--Charts-Blue, #056ee5);
  font-family: Inter;
  font-size: 14px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: auto;
  cursor: pointer;
}

.recHistoryContainer {
  position: absolute;
  height: 100%;
  top: 0px;
  left: 442px;
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px var(--Corner-Radius-8, 8px) var(--Corner-Radius-8, 8px) 0px;
  background: #f0f0f0;
  /* width: 100%; */
  /* min-height: 494px; */
  z-index: 1056;
}
.discardedHeaderSection {
  display: flex;
  padding-bottom: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 15px;
}

.historyTittle {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.hideContainer {
  display: flex;
  width: 343px;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: none;
  background: var(--Controls-BG, #f0f6ff);
  color: var(--Charts-Blue, #056ee5);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
}

/* ---------------------------------- */
.hideContainer:hover img,
.hideContainer:active img {
  filter: invert(90) brightness(50);
}
.historyModal {
  height: 50vh;
}
/* --------------------------------------- */

.headingContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  color: var(--Neutral-D3, #495057);
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2px;
}

.acceptCreteriaDetails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
}

.astrick {
  color: #d9352a;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.lableClass {
  color: var(--Neutral-D4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 2px;
}
#inputId,
.inputClass {
  padding: 8px !important;
  border-radius: 4px;
  color: #000;
  border: 1px solid rgba(206, 212, 218, 1);
}

.discardedContainer {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 0px var(--Corner-Radius-8, 8px) var(--Corner-Radius-8, 8px) 0px;
  background: #f0f0f0;
  background: linear-gradient(to bottom, white 67px, #f0f0f0 67px);
  width: 391px;
  position: relative;
}
.detailContainers {
  display: flex;
  padding: var(--Corner-Radius-8, 8px);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--Corner-Radius-8, 8px);
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #e9ecef;
  background: rgba(248, 249, 250, 0.5);
  color: var(--Neutral-D5, #adb5bd);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}
.detailsComments {
  color: var(--Neutral-D3, #495057);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 166.667% */
}
.errorMessage {
  font-size: 10px;
  color: brown;
  margin-top: 0px !important;
}
.historyModal {
  display: flex;
  padding: 24px;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 16px;
  align-self: stretch;
  border-radius: 8px;
  background: #f0f0f0;
  z-index: 1056;
  align-items: center;
}
.offcanvas_header_main {
  padding: 16px 24px;
  background-color: #f8f9fa;
  width: 100%;
}
.offcanvas_main {
  width: 30%;
}
.offcanvas_header {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 12px;
  width: 100%;
}
.retain_close {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.accept_via_jira {
  gap: 4px;
  color: #495057;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: right;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.accept_recommend {
  color: #212224;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.accpt_desc {
  color: #797b80;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.header_desc {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.comment_boxed {
  padding: 24px 24px 24px 24px;
  width: 100%;
  background-color: #fff;
}
.offcanvas_body_css {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  /* overflow: hidden; */
  padding: 0px 0px;
}
.offcanvas_body_view {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  /* overflow: hidden; */
  padding: 0px 0px;
}
/* .forscrollbar ::-webkit-scrollbar {
  display: none;
} */
.buttonsContainered {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background-color: #f8f9fa;
  padding: 16px 24px;
  border-top: 1px solid #e9ecef;
}
.contentContaineredBox {
  /* display: flex;
  flex-direction: column; */
  align-items: flex-start;
  align-self: stretch;
  flex-grow: 1;
  background-color: #f8f9fa;
}
.table_main {
  display: flex;
  flex-direction: row;
  /* align-items: flex-end; */
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
  width: 100%;
  padding: 0px 24px;
}
.body_content {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  /* gap: 32px; */
  width: 100%;
  height: 89%;
}
#customers .table_data__Class,
#customers .th_class,
.total_Costs {
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Neutral-D3, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #e9ecef;
  background: #f8f9fa;
}
.table_data__Class {
  max-width: 30px;
  /* max-width: 185px; */
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  position: relative;
}
.table_td_width {
  width: 15% !important;
}
#customers .th_class {
  color: #343a40;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Header_bg, #e9ecef);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.selected_resource_table {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 12px;
  color: #495057;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
/* -------------------------accept vie data ---------------------------------------------------------- */
.close_box {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.title_box {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.ticket_box {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
}
.tittle_name {
  color: #495057;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.36px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.title_value {
  color: #0f2573;
  font-family: Inter;
  font-size: 26px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: auto;
}
.ticket_idies {
  color: #797b80;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.ticket_id_value {
  color: #343a40;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.selected_resource_des {
  color: #495057;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
#customers .table_data_des,
#customers .th_class,
.total_Costs {
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Neutral-D3, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #e9ecef;
  /* background: #f8f9fa; */
}
.table_data_des {
  max-width: 140px;
  /* max-width: 185px; */
  white-space: nowrap;
  /* overflow: hidden; */
  text-overflow: ellipsis;
  position: relative;
  background-color: transparent !important;
}
.comment_des {
  color: #495057;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.comment_name {
  color: #343A40;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.comment_date {
  color: #797b80;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.commented_details {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  align-items: center;
}
.img_comment {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}

.description_css {
  color: #495057;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.table_data_status {
  background-color: #f9f9f9;
  padding: 2px 8px 4px 8px;
  gap: 4px;
  border-radius: 4px;
  color: #797b80;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
}
.table_ticket_status {
  background-color: #056ee5;
  padding: 2px 8px 4px 8px;
  gap: 4px;
  border-radius: 4px;
  color: #ffffff;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
/* Universal styling for status */
.status {
  width: 15%;
}
.status.in_progress {
  background-color: #ebf4fd;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #0058bd;
}
.status.in_progress::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #0058bd;
}
.status.open {
  background-color: #f9f9f9;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #797b80;
}
.status.open::before {
  content: "";
  margin-right: 4px;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  display: inline-block;
  background-color: #adb5bd;
}

.status.deleted {
  background-color: transparent;
  padding: 2px 8px 4px 8px;
  border-radius: 4px;
  color: #127e24;
  border: 1px solid #dee2e6;
}
.status.deleted::before {
  content: url("@/assets/img/doneStateChange.svg");
  display: inline-block;
  width: 13px;
  height: 13px;
  /* margin-right: 8px; */
}
.ticket_status {
  background: #056ee5;
  color: white;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
