.overlay {
  position: absolute;
  background-color: #343A40;
  padding: 4px 8px;
  color: white;
  border-radius: 3px;
  overflow: hidden;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  z-index: 100000;
}