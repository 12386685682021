.projectedNotify {
  font-size: 11px;
  font-weight: 700;
  color: #127e24;
  background: #14c5411f;
  padding: 2px 4px 3px 5px;
  border-radius: 2px;
}
.PurchaseReservedInstances {
  padding: 2px 4px;
  border-radius: 5px;
  background: #f1effe;
  color: #6d5ded;
}
.PurchaseSavingsPlans {
  padding: 2px 4px;
  border-radius: 5px;
  background: #f7edd2;
  color: #5e5622;
}
.Rightsize {
  padding: 2px 4px;
  border-radius: 5px;
  background: #ebfaed;
  color: #359446;
}
.MigrateToGraviton {
  padding: 2px 4px;
  border-radius: 5px;
  background: #e6eefa;
  color: #056ee5;
}
.Upgrade {
  padding: 2px 4px;
  border-radius: 5px;
  background: #fdf2e6;
  color: #e77d00;
}
.Stop {
  padding: 2px 4px;
  border-radius: 5px;
  background: #faebeb;
  color: #d01a1a;
}
.vm_values {
  color: #212224;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
}
.vm_other_value {
  color: #212529;
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}
.endDetails {
  display: flex;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
}
.detailValues {
  font-weight: 600;
}
.fixed {
  /* margin-right: 20px; */
  display: flex;
  width: 16% !important;
  box-shadow: -25px -2px 11px -21px lightgrey;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 15px;
  background-color: white;
  /* overflow-y: visible; */
}
.scroll {
  width: 84%;
  overflow-x: scroll;
}
.projectedChart {
  width: 100%;
  /* margin-top: 24px; */
  padding-left: 29px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.IndividualChart {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.IndividualSection {
  width: 50%;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 16px 16px 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.individuaTooltip {
  margin: 0;
  padding: 8px 12px;
  color: #dee2e6;
}
.individuaTooltipfunc {
  background: black;
  display: flex;
  gap: 8px;
  justify-content: space-between;
}

.tooltipkey {
  font-size: 11px;
  font-weight: 500;
  color: #ced4da;
  padding-left: 0px !important;
}
.tooltipvalue {
  font-size: 12px;
  font-weight: 600;
  color: #f8f9fa;
  padding-right: 0px !important;
}
.tooltiptr {
  line-height: 12px;
  border-top: 1px solid #373737;
  display: flex;
  justify-content: space-between;
}
.custom_tooltip {
  background-color: black;
  border: 1px solid #000;
  border-radius: 8px;
  width: 272px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
}
.individaualchartsection {
  display: flex;
  justify-content: space-between;
  padding: 25px 40px 40px 29px;
  flex-direction: column;
  gap: 10px;
}
