.mainContainer {
  display: flex;
  flex-direction: column;
  padding: 24px 39px;
  /* width: 80%; */
  gap: 15px;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 22px;
  width: 100%;
}
.menu_icon_css1 {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}


.heading {
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
  color: #495057;
}
.VM_family_block {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.vm_name {
  color: #495057;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
}
.vm_value {
  color: #212224;
  font-family: Inter;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: left;
}
.vm_other_values {
  color: #212529;
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
.per_month {
  color: #797b80;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: center;
}
.instance {
  color: #495057;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  text-align: right;
  padding-bottom: 12px;
}
.reserved {
  color: #212224;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: left;
  padding: 12px;
}
.container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.specs,
.models,
.resourceDetails {
  padding: 10px;
}

.models_data {
  /* text-align: center; */
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  /* width: 62%; */
  align-items: end;
}
.blue{
  background: #E6EEFA80;
}

.green {
  background-color: #ebfaed;
}

.gray {
  background-color: #f8f9fa;
}
.resourceDetails {
  display: flex;
  justify-content: center;
  text-align: center;
}
.resource_name {
  color: #000000;
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
  text-align: left;
}
.resource_value {
  color: #000000;
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  line-height: 13.2px;
  text-align: left;
}
.model_css {
  display: flex;
  justify-content: flex-start;
  width: 33%;
  padding: 12px;
  text-align: center;
}
.border_bottom {
  color: #343537;
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.6px;
  text-align: center;
  padding: 4px 12px 4px 12px;
  gap: 4px;
  border-bottom: 1px solid #acacac1a;
}
.saving_month {
  display: flex;
  flex-direction: column;
}
.main_year_comp {
  display: flex;
  flex-direction: column;
  gap: 0px;
  /* width: 62%; */
}
.year_modal {
  color: #606367;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: center;
  padding-bottom: 8px;
}
.saving_name {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  opacity: 70%;
}
.saving_value {
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
  text-align: center;
}
.specs_name {
  color: #606367;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  /* text-align: left; */
  padding-bottom: 8px;
}
.on_demand_cost {
  color: #343537;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  /* text-align: center; */
  padding: 4px 12px 4px 0px;
  gap: 4px;
  text-wrap: nowrap;
}
.for_border_line {
  border-bottom: 1px solid #acacac1a;
}
.Subscription {
  color: #343537;
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
  text-align: center;
  padding-bottom: 8px;
}
.single_rg {
  color: #212224;
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
}
.models_data2 {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}
.instance_data {
  color: #343537;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: center;
  padding: 4px 12px 4px 12px;
  gap: 4px;
  border-bottom: 1px solid #acacac1a;
}

.filter_main_divs {
  background: #fff;
  width: 18%;
  display: flex;
  padding: 16px 0px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.dropupIconStyle{
  background: #CED4DA;
}
.modalHeadingText{
  padding-left: 12px;
  font-size: 16px;
  font-weight: 600;
  color: black;
}
