.filter_main_div {
  /* border-left: 1px solid var(--neutral-d-7, #dee2e6); */
  background: #fff;
  /* width: 100%; */
  height: 100vh;
  box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.filter_padding_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
}

.dimension_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}

.dimension_name_css {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
}
.service_dropdown_box {
  display: flex;
  height: 38px;
  padding: 4px 6px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  background: var(--surface-default, #fff);

  /* shadow-button */
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
}

.dropdown_font{
  font-family: Inter;
font-size: 13px;
font-weight: 500;
line-height: 16px;
letter-spacing: 0em;
text-align: left;

}
.service_box {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 2px;
  background: var(--Controls-BG, #f0f6ff);
}
.service_name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--neutral-d-1, #212529);
  text-overflow: ellipsis;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 14.4px */
}
.horizontal-line {
  border-top: 1px solid;
  border-color: var(--neutral-d-7, #dee2e6);
  width: 100%;
}
.placeholder_name {
  color: var(--neutral-d-6, #ced4da);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
}
.filter_box_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.filter_name_addsign {
  display: flex;
  padding: 0px 4px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.pagination-sm {
  --bs-pagination-padding-x: 0.86rem; 
  --bs-pagination-padding-y: 0.2rem;
  --bs-pagination-font-size: 13px;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}
.filter_name {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.filter_select_box {
  border-radius: 4px;
  /* border: 1px solid var(--neutral-d-6, #ced4da); */
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
}
.service_name_box {
  border-radius: 4px;
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  padding-left: 0px;
}
.services_name_css {
  /* color: var(--Dark, #212224); */
  color: #797B80;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.values_box {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
}
.value_name_css {
  color: var(--Dark, #212224);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* ---------------------------------------------------------- */

/* ---------------------------------------------------------- */

.filter_grouping_box {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--neutral-d-5, #CED4DA);
  background: var(--White, #fff);
  justify-content: space-between;
}
.filter_group_name_css {
  color: var(--neutral-d-5, #adb5bd);
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.optionContainer::-webkit-scrollbar {
  /* display: none; Safari and Chrome */
}
.searchWrapper {
  border: none !important;
  width: 80%;
}
.chip.false {
  align-items: center;
  background: #f0f6ff;
  border-radius: 1px;
  /* color: #fff; */
  color: #000;

  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-flex;
  /* font-size: 13px; */
  /* line-height: 19px; */
  margin-bottom: 2px;
 
  padding: 4px;
  width: 122%;
  text-overflow: ellipsis;
}
.chip,
.singleChip {
  /* white-space: nowrap; */
  white-space: wrap !important;
}
.closeIcon {
  color: black;
}
.multiselectContainerReact {
  margin-left: 0px;
}

.multiselectContainer li:hover {
  background: var(--Controls-BG, #f0f6ff) !important;
  color: white;
  cursor: pointer;
}
.multiSelectContainer li {
  padding-left: 10px !important;
  padding-bottom: 0px !important;
  padding-right: 0px !important;
  padding-top: 0px !important;
}
.searchBox {
  border: none;
  color: var(--neutral-d-5, #adb5bd);
  border-radius: 4px;
  width: 100%;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.closeIcon {
  background-color: black;

  border-radius: 16px;
}
.inputField {
  margin: 5px;
}
.chips {
  /* background: red; */
}
.optionContainer {
  border: 2px solid;
}
/* #select-category option {
  color: #000;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  min-width: 1px;
  width: 100%;
} */
.optionListContainer {
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  position: absolute;
  width: 100%;
  z-index: 2;
  color: black;
}
.groupHeading {
}
#select-category {
  font-size: 100%;
  font-family: Inter, sans-serif;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background: transparent; */
  background-image: url("@/assets/img/select_minor.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  align-items: center;
  padding-left: 6px;
  border-radius: none;
  color: black;
}
.checkbox {
  width: 10px !important;
}

select:required:invalid {
  color: var(--neutral-d-6, #ced4da);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}
 option[value=" "][disabled] {
  display: none;
  color: red !important;
}
#select-category option {
  color: black;
  margin-left: 10px;
  background-color: #fff;
}

