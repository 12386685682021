.filter_main_div {
  background: #fff;
  height: 100%;
  box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.04);
  display: flex;
  padding: 24px 16px 8px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.filter_padding_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  margin-right: 10px;
  align-self: stretch;
}

.checkboxArea {
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: flex-start;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #343a40;
}

.dimension_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}

.dimension_name_css {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
}

.service_dropdown_box {
  width: 100%;
}

.service_dropdown_box_select {
  display: flex;
  height: 38px;
  /* padding: 4px 6px; */
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  /* border: 1px solid var(--neutral-d-6, #ced4da); */
  /* background: var(--surface-default, #fff); */
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05) !important;
}

.filter_box_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.filter_name_addsign {
  display: flex;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.filter_name_padding {
  display: flex;
  padding: 4px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.filter_name {
  color: var(--Dark, #212224);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.filter_select_box {
  border-radius: 4px;
  border: 1px solid var(--neutral-d-6, #ced4da);
  display: flex;
  padding: 4px 6px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.service_name_box {
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.services_name_css {
  display: flex;
  color: #797b80;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}

.filter_grouping_box {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  min-height: 22px;
}

.filter_group_name_css {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
}

.placeholder_text {
  padding: 4px 6px;
}

.chip {
  align-items: center;
  background: #f0f6ff;
  border-radius: 1px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.chip_content {
  min-width: 0;
}

.filter_dimension_toggle {
  background-color: #f0f6ff;
  padding: 5px 6px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  border-radius: 4px;
  text-align: left;
  color: #212224;
  cursor: pointer;
}
.trucate_vale_tags{
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 175px;
}

.filter_dimension_toggle_selected {
  background-color: transparent;
  padding-left: 4px;
  padding-right: 4px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 175px;
}

.remove_icon:hover path {
  stroke: #d01a1a;
}
