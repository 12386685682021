/* ---------------------------header css------------------------------------- */
.main_container {
  display: flex;
  background: #0f2573;
  padding: 16px 40px 0px 40px;
  gap: 16px;
  flex-direction: column;
  width: 100%;
}

.inner_first_container_text {
  opacity: 0.5;
  color: white;
  font-size: 21px;
  font-family: inter;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
  margin: 0px;
}
/* -----------------------------------main component--------------------------------------- */
.ai_main_component {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* gap: 24px; */
}
.css_tables {
  width: 100%;
  padding: 0px 10px 0px 40px;
}
/* ------------------------------usage pattern table css----------------------------------------- */
.table_filter {
  display: flex;
  justify-content: space-between;
  /* gap: 25px; */
}
.table_head_css {
  color: #343a40;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
  background: #edeff2;
  padding: 8px 16px 8px 8px;
  text-wrap: nowrap;
}
.table_stuc {
  width: 82%;
  display: flex;
  flex-direction: column;
  gap: 22px;
  padding-right: 24px;
  margin-top: 24px;
  /* border-right: 1px solid #dee2e6; */
}
.table_body_css {
  border-bottom: 1px solid #e9ecef;
  padding: 10px 16px 10px 8px;
  color: #212224;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
}
.saving_css {
  font-weight: 600;
  text-align: right;
  color: #127e24;
}
.trunc_ai_css {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 166px;
  text-wrap: nowrap;
}
.table_desc {
  color: #495057;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12.1px;
  text-align: left;
}
.describe_main {
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: start;
}
.usage_pattern_name {
  color: #495057;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.36px;
  text-align: left;
}
.usage_pattern_box {
  display: flex;
  justify-content: space-between;
}
.menu_icon_css1 {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.custom_dropdown_toggle::after {
  content: none;
}
.custom_dropdown_toggle {
  padding: 4px 6px !important;
  border: none;
}
.badge {
  padding: 2px 4px;
  border-radius: 4px;
}

.badgeDanger {
  background: #faebeb;
  color: #d01a1a;
}

.badgeWarning {
  background: #fdf6db;
  color: #5e5622;
}

.badgeOk {
  background: #ebfaed;
  color: #127e24;
}

.badgeNA {
  background: #e5e5e5;
  color: #1a1a1a;
}
.tooltip_css {
  color: #ffffff;
  font-family: Inter;
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  text-align: left;
  background-color: #343a40;
  border-radius: 4px;
}

/* -------------------------------------filter css------------------------------------------------ */
.filter_main_div {
  background: #fff;
  width: 100%;
  display: flex;
  padding: 0px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  /* height: 100%; */
  margin-top: 16px;
}
.filter_padding_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.dimension_div {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2px;
  align-self: stretch;
}
.dimension_name_css {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
}
.disabled_title {
  color: #adb5bd;
}
/* --------------------------------dropdown css------------------------------------------------------ */
.dropdown_menu {
  transform: translate3d(-87%, 34.4px, 0px) !important;
  padding: 5px !important;
}
.download_link {
  text-decoration: none;
  padding: 4px;
  border-radius: 2px;
}
.download_link:hover {
  background-color: #e6eefa;
}
