.mn_container {
  display: flex;
  min-height: 290px;
  height: 100%;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--Controls-BG, #f0f6ff);
  background: #fff;
}

.first_container {
  display: flex;
  justify-content: space-between;
  gap: 2px;
  align-self: stretch;
}

.first_div {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 2px;
  align-self: stretch;
  flex: 1 0 0;
}

.one_cont {
  width: 218px;
  height: 20px;
  background: var(--neutral-d-9, #f8f9fa);
}

.sec_cont {
  width: 111px;
  height: 27px;
  background: var(--neutral-d-9, #f8f9fa);
}

.second_div {
  width: 79px;
  height: 20px;
  background: var(--neutral-d-9, #f8f9fa);
}

.sec_main_Container {
  flex: 1 0 0;
  align-self: stretch;
  position: absolute;
  top: 80px;
  left: 180px;
}

.horizontal_line_one {
  width: 509.04px;
  height: 0px;
  left: 90.96px;
  top: 22.54px;
  position: absolute;
  border: 1px #f8f9fa solid;
  color: aqua;
}

.horizontal_line_two {
  top: 53.34px;
  width: 509.04px;
  height: 0px;
  left: 90.96px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.horizontal_line_three {
  top: 83.03px;
  width: 509.04px;
  height: 0px;
  left: 90.96px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.horizontal_line_four {
  top: 113.82px;
  width: 509.04px;
  height: 0px;
  left: 90.96px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.horizontal_line_five {
  top: 145.16px;
  width: 509.04px;
  height: 0px;
  left: 90.96px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.horizontal_line_six {
  top: 175.96px;
  width: 509.04px;
  height: 0px;
  left: 90.96px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.vertical_line_one {
  width: 0px;
  height: 192.45px;
  left: 90.16px;
  top: 0px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.vertical_line_two {
  left: 162.2px;
  width: 0px;
  height: 192.45px;
  top: 0px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.vertical_line_three {
  left: 235.83px;
  width: 0px;
  height: 192.45px;
  top: 0px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.vertical_line_four {
  left: 307.87px;
  width: 0px;
  height: 192.45px;
  top: 0px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.vertical_line_five {
  left: 381.5px;
  width: 0px;
  height: 192.45px;
  top: 0px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.vertical_line_six {
  left: 453.54px;
  width: 0px;
  height: 192.45px;
  top: 0px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.vertical_line_seven {
  left: 527.17px;
  width: 0px;
  height: 192.45px;
  top: 0px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.vertical_line_eight {
  left: 90.16px;
  width: 0px;
  height: 192.45px;
  top: 0px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.vertical_line_nine {
  width: 509.04px;
  height: 0px;
  left: 90.96px;
  top: 193px;
  position: absolute;
  border: 1px #f8f9fa solid;
}

.left_bar_one {
  width: 65.3px;
  height: 13.2px;
  left: 12.85px;
  top: 15.4px;
  position: absolute;
  background: #f8f9fa;
}

.left_bar_two {
  width: 46.05px;
  height: 13.2px;
  left: 32.45px;
  top: 46.19px;
  position: absolute;
  background: #f8f9fa;
}

.left_bar_three {
  width: 80.09px;
  height: 13.2px;
  left: 0px;
  top: 75.88px;
  position: absolute;
  background: #f8f9fa;
}

.left_bar_four {
  width: 59.07px;
  height: 13.2px;
  left: 19.02px;
  top: 106.67px;
  position: absolute;
  background: #f8f9fa;
}

.left_bar_five {
  width: 68.08px;
  height: 13.2px;
  left: 10.01px;
  top: 138.56px;
  position: absolute;
  background: #f8f9fa;
}

.left_bar_six {
  width: 43.05px;
  height: 13.2px;
  left: 35.04px;
  top: 169.36px;
  position: absolute;
  background: #f8f9fa;
}

.right_bar_one {
  width: 254.52px;
  height: 17.6px;
  left: 90.96px;
  top: 13.2px;
  position: absolute;
  background: #f8f9fa;
}

.right_bar_two {
  top: 43.99px;
  width: 356.97px;
  height: 17.6px;
  left: 90.96px;
  position: absolute;
  background: #f8f9fa;
}

.right_bar_three {
  top: 74.78px;
  width: 437px;
  height: 17.6px;
  left: 90.96px;
  position: absolute;
  background: #f8f9fa;
}

.right_bar_four {
  top: 105.57px;
  width: 443.41px;
  height: 17.6px;
  left: 90.96px;
  position: absolute;
  background: #f8f9fa;
}

.right_bar_five {
  top: 136.37px;
  width: 160.07px;
  height: 17.6px;
  left: 90.96px;
  position: absolute;
  background: #f8f9fa;
}

.right_bar_six {
  top: 167.16px;
  width: 188.89px;
  height: 17.6px;
  left: 90.96px;
  position: absolute;
  background: #f8f9fa;
}

.butoon_first__Cont {
  display: flex;
  padding: 8px 12px;
  justify-content: center;
  align-items: center;
  gap: 3px;
  position: relative;
  left: 242px;
  bottom: 110px;
  border-radius: 4px;
  border: 1px solid var(--charts-blue, #056ee5);
  background: var(--Controls-BG, #f0f6ff);
  cursor: pointer;
}

/* .image{
    width: 16px;
  height: 16px;
  transform: rotate(45deg);
  } */

.button_text {
  color: var(--charts-blue, #056ee5);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
