.userAccountStatusDiv {
  background-color: #f1f7ff;
  border-radius: 4px;
  padding: 16px 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userAccountStatusText {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  color: #495057;
}

.activeDisabledText {
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
  line-height: 18.15px;
  text-align: left;
}

.timeDate {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18.15px;
  text-align: left;
  color: #212529;
}

.userProfileOverviewDiv {
  padding: 24px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.userProfileOverviewText {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.36px;
  text-align: right;
  color: #212529;
}

.userDetailsDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.divsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userDetailsInnerDivs {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 4px;
}

.detailValues {
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18.15px;
  text-align: left;
  color: #343A40;
}
