
.main_container {
    height: 100%;
    width: 100%;
    background: #F8F9FA;
    padding: 64px 10px;
    gap: 16px;
    background: var(--Neutral-D9, #F8F9FA);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-left: 12px; */
}
.main_container_absence {
  height: 50vh;
}

.img_container {
    position: relative;
    height: 32px;
    width: 32px;
}

.sec_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}
.skeleton_container {
    /* width: 23.84%; */
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #F8F9FA;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }
 
  .skeleton_header {
    display: flex;
    width: 100%;
    padding: 0px 0px 12px 0px;
    border: 0px 0px 1px 0px;
    justify-content: space-between;
    border-bottom: 1px solid #DEE2E6;
    /* height: 36px; */
  }


.reload_img_container {
    position: relative;
    height: 12px;
    width: 12px;
}

.text_css {
    color: var(--Neutral-D1, #212529);
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.2px;
}


.sec_text_css {
    width: 465px;
    color: var(--Neutral-D4, #797B80);
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: italic;
    font-weight: 500;
    line-height: 20px;
    /* 153.846% */
    letter-spacing: -0.13px;
}

.reload_text_css {
    color:  #056EE5;
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-left: 4px;
    /* 153.846% */
    letter-spacing: -0.13px;
    /* text-color:primary */
}
.skeleton_container {
    /* width: 23.84%; */
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #F8F9FA;
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
  }
 
  .skeleton_header {
    display: flex;
    width: 100%;
    padding: 0px 0px 12px 0px;
    border: 0px 0px 1px 0px;
    justify-content: space-between;
    border-bottom: 1px solid #DEE2E6;
    /* height: 36px; */
  }
.third_container {
    width: 100%;
    /* height: 116px; */
    padding: 8px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap:8px;
    border-radius: 8px;
    background-color: #E9ECEF;
  }


.skelton-main_cont {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 40px;
    padding-top: 16px;
    padding-right: 40px;
    background: white;
    border-bottom: 1px #E9ECEF solid;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
}

.twoDiv-header_Cont {
    width: 100%;
    gap: 16px;
    display: flex;
    flex-direction: column;
}

.first-line_cont {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    align-items: center;
    height: 29px;
}

.both-line {
    height: 25px;
    background-color: #F8F9FA;
    width: 30%;
}

.line-one_cont {
    display: flex;
    width: 20%;
    height: 29px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.line-two_cont {
    padding-top: 6px;
    padding-bottom: 7px;
    padding-left: 11px;
    padding-right: 10px;
    background-color: #F8F9FA;
    gap: 10px;
    height: 25px;
    width: 80px;
}

.header_container {
    display: flex;
    align-self: stretch;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

}

.header-cont-line {
    display: flex;
    align-self: stretch;
    background-color: #F8F9FA;
    justify-content: flex-start;
    align-items: flex-start;
}

.header-three-div {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    align-items: flex-start;
}

.three-div-css {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #F8F9FA;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    width: 80px;
    height: 35px;
}

.graph-section_cont {
    width: 80%;
    padding-top: 25px;
    padding-left: 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    display: flex;
}

.graph-line {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    display: flex;
}

.graph-first-line {
    width: 218px;
    height: 20px;
    background-color: #F8F9FA;
}

.graph-sec-line {
    display: flex;
    width: 430%;
    align-self: stretch;
    height: 27px;
    background-color: #F8F9FA;
}

.graph-first_cont {
    display: flex;
    width: 252px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
}

.graph-first_line {
    align-self: stretch;
    height: 32px;
    background-color: #F8F9FA;
}

.graph-sec_line {
    align-self: stretch;
    height: 18px;
    background-color: #F8F9FA;
}

.filter-section_cont {
    display: flex;
    width: 20%;
    height: 100%;
    padding-top: 24px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    box-shadow: -1px 0px 0px rgba(0, 0, 0, 0.04);
    border-left: 1px #DEE2E6 solid;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
}

.filter-line-section {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    height: 222px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 18px;
}

.filetr-lin-cont {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    height: 62px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
}

.filetr-lin-first {
    width: 122px;
    height: 18px;
    background-color: #F8F9FA;
}

.filetr-lin-sec {
    display: flex;
    align-self: stretch;
    height: 36px;
    background-color: #F8F9FA;
}
/* ---------------------header skelton------------------------------------------------------ */
/* Horizontal Lines */
.horizontal_line {
  position: absolute;
  width: 70%;
  border-top: 1px solid #f8f9fa;
  color: aqua;
  left: 8%;
}

.horizontal_line.one { top: 22.5px; }
.horizontal_line.two { top: 53.34px; }
.horizontal_line.three { top: 83.03px; }
.horizontal_line.four { top: 90.5px; }


/* Vertical Lines */
.vertical_line {
  position: absolute;
  height: 13vh;
  border-left: 1px solid #f8f9fa;
  left:12%
}

.vertical_line.one { left: 10%; }
.vertical_line.two { left: 20%; }
.vertical_line.three { left: 30%; }
.vertical_line.four { left: 40%; }
.vertical_line.five { left: 50%; }


/* Placeholder Styling */
.placeholder_css {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

  
  