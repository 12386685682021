/* @import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
/* @import url('https://fonts.cdnfonts.com/css/helvetica-neue-9'); */
/* @import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap"); */

/* ======= General Rules ======== */
input[type="checkbox"] {
  accent-color: #056ee5;
  border-radius: 4px;
}
input[type="checkbox"] {
  /* border: 1px solid #ADB5BD; 
  width: 16px;
  height: 16px; */
  border: 1px solid #056ee5; 
}

:root {
  --bs-btn-font-family: Inter;
  --bs-dropdown-divider-bg: #e9ecef;
  --bs-dropdown-divider-bg: #e9ecef;
}

html {
  width: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
.dropdown-button {
  padding: 0px;
  text-decoration: none;
}
.btn-link {
  padding: 0px !important;
  text-decoration: none !important;
}
#dropdownItem {
  padding: 4px 6px !important;
}
#dropdownItem:hover {
  /* padding: 4px 6px !important; */
  background-color: #f0f6ff !important;
  border-radius: 4px;
}
.dropdown-menu.show {
  padding: 8px;
  display: flex !important;
  flex-direction: column !important;
  overflow-x: auto;
  box-shadow: 0px 12px 27px 0px rgba(0, 0, 0, 0.1),
    0px 49px 49px 0px rgba(0, 0, 0, 0.09),
    0px 111px 67px 0px rgba(0, 0, 0, 0.05),
    0px 197px 79px 0px rgba(0, 0, 0, 0.01), 0px 308px 86px 0px rgba(0, 0, 0, 0);
}

.dropdown-item {
  color: #212224 !important;
  font-weight: 500 !important;
}

ul {
  padding: 0;
  margin: 0;
}
/* ---------------------------accordion css--------------------------------------------- */
.enabled_cloud {
  color: #ffffff;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18.15px;
  text-align: left;
}
.body_accordion {
  padding: 0px;
  background-color: transparent;
  margin: 0px;
}
.header_accordian {
  text-decoration: none;
  padding: 0px;
  background-color: transparent;
}

/* .sidebar {
  background: #293949;
  width: 240px;
} */

.sidebar_menu_css {
  list-style-type: none;
  padding: 0;
}

.sub-menu {
  position: relative;
}

.droprecommend_menu {
  display: block;
  /* background: #23313f; */
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.droprecommend_menu li a {
  color: #aeb2b7;
  text-decoration: none;
}

.droprecommend_menu li a:hover {
  color: #1abc9c;
}

.arrow {
  margin-left: auto;
  transition: transform 0.3s;
}

.arrow.fa-angle-down {
  transform: rotate(0deg);
}

.shrink-sidebar .side_name {
  display: none;
}
.shrink-sidebar .label_recommend {
  display: none;
}
/* ---------------------------------------------------- */
.rdrDefinedRangesWrapper{
  color: #000 !important
}
/* ----------------------------------------------------- */

.side_name {
  margin-left: 2px;
  display: inline-block;
}
.side_bg_color {
  padding: 4px;
  gap: 4px;
  background: #ffffff1a;
  margin-left: 10px;
  margin-right: 10px;
}
.droprecommend_menu {
  padding: 4px;
  gap: 4px;
  border-radius: 0px 0px 8px 8px;
  background: #ffffff1a;
  margin-left: 10px;
  margin-right: 10px;
}
/* -------------------------tabs css for saving page----------------------------- */
/* .nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
  background: rgba(255, 255, 255, 0.18);
  padding: 8px 16px;
  color: var(--White, #fff);
  font-family: Inter, sans-serif;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.8;
} */

/* .nav-tabs .nav-item.show .nav-link,
/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: black;
  background: #fff;
  opacity: 1;
} */
.text-btn__Container {
  color: var(--White, #fff);
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
  display: flex;
  align-items: flex-start;
  padding-bottom: 8px;
  margin-right: 0px;
}

.btn__container {
  display: flex;
  padding: 8px 4px 3px 5px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  /* background: rgba(20, 197, 65, 0.12); */
}
/* -------------------------for table hover in idle resource-------------------------- */
.table-hover tbody tr:hover,
.table-hover tbody tr:hover td {
  background-color: #f0f6ff;
}
.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: none !important;
}

.hovered {
  background-color: #f0f0f0;
}

/* --------------------------------pagination css------------------------------- */
.page-link {
  /* color: #797B80 !important; */
}

/* .page-item:not(:first-child) .page-link {
  color: #797B80;
} */
.active > .page-link,
.page-link.active {
  z-index: 0;
  color: #056ee5 !important;
  background-color: #f0f6ff;
  border-color: #ced4da;
}
.disabled > .page-link {
  background-color: white;
  color: #797b80;
}
.pagination-sm {
  --bs-pagination-font-size: 12px !important;
  padding: 5px 8px 6px 8px !important;
  border: 4px !important;
}
/* ---------------------dropdown css for aws cost explorer----------------------------- */
.toggle_drop_down {
  padding-left: 4px;
  font-size: 12px;
}

.dropdown-item {
  border-radius: 4px;
  padding: 4px 8px;
  font-size: 12px;
}
/* -------------------------------------------------------------- */
.elipese {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  /* border: 1px solid #000000; */
}
/* ------------------------------------------------------------------- */

.My-onworPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 16px;
  gap: 16px;
}
.on-work-details {
  color: #797b80;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-style: italic;
  font-weight: 500;
  word-wrap: break-word;
  text-align: center;
}
.contianer-first-onwork {
  width: 46%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.heading-onWork {
  width: 100%;
  text-align: center;
  color: #212529;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  word-wrap: break-word;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

p {
  margin-bottom: 5px;
  line-height: 19px !important;
  -webkit-font-smoothing: antialiased;
}

a:hover,
a:focus {
  text-decoration: none;
}

a,
a:link,
a:hover {
  color: #333;
}

input,
textarea,
select,
select option {
  color: #5c5c5c;
  font-weight: 500;
  line-height: 20px;
}

label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4b4b4b;
}

input,
textarea,
select {
  border: 1px solid rgba(0, 0, 0, 0.15);
  outline: 0 !important;
  -webkit-appearance: none;
  font-size: 13px !important;
}

input[type="range"] {
  background: #d4d4d4;
  border: 1px solid #efefef;
}

button:focus {
  box-shadow: none !important;
}

input,
input:focus,
select,
select:focus,
textarea,
textarea:focus {
  outline: 0 !important;
  -webkit-appearance: none !important;
  box-shadow: none !important;
}

input[type="checkbox"],
input[type="checkbox"]:focus,
input[type="radio"],
input[type="radio"]:focus {
  outline: 1px !important;
  -webkit-appearance: checkbox !important;
}

table {
  color: #555;
}

table thead tr,
table tbody tr,
table tfoot tr {
  padding: 0;
  margin: 0;
  line-height: 23px;
}

table tbody tr td {
  margin: 0px;
}

table tbody tr td {
  font-size: 13px;
  font-weight: 500;
}

body,
.conatiner-fluid {
  overflow-x: hidden !important;
}

/* body,
.conatiner-fluid::-webkit-scrollbar
{
  display: none;
}
body,
.conatiner-fluid
{
  -ms-overflow-style: none;  
  scrollbar-width: none; 

} */

.border-left-primary-4 {
  border-left: 4px solid #007bff;
}

.bg-dark-blue {
  background: #3a5276;
}

.bg-light-blue {
  background: #799cc7;
}

.bg-red {
  background: #ea5941;
}

.bg-green {
  color: #4cc790;
}

.bg-theme {
  background: #da291c;
  color: #fff;
}

/*======= General Rules =========*/

/*====== Dasboard CSS Rules =====*/

/*Loader CSS */
.loader-wrapper {
  position: fixed;
  z-index: 9999;
  background: #fff;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.loader-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  border: 5px solid #fff;
  box-shadow: 0 0 0 5px #0d47a1;
  overflow: hidden;
}

.loader-wave {
  position: relative;
  width: 100%;
  height: 100%;
  background: #0d47a1;
  border-radius: 50%;
  box-shadow: inset 0 0 50px rgba(0, 0, 0, 0.5);
}

.loader-wave:before,
.loader-wave:after {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: 0%;
  left: 50%;
  transform: translate(-50%, -75%);
}

.loader-wave:before {
  border-radius: 45%;
  background: rgba(255, 255, 255, 1);
  animation: animate 5s linear infinite;
  -webkit-animation: animate 5s linear infinite;
  -ms-animation: animate 5s linear infinite;
  -moz-animation: animate 5s linear infinite;
}

.progress-bar {
  background-color: #e9ecef;
}

.loader-wave:after {
  border-radius: 40%;
  background: rgba(255, 255, 255, 0.5);
  animation: animate 10s linear infinite;
  -webkit-animation: animate 10s linear infinite;
  -ms-animation: animate 10s linear infinite;
  -moz-animation: animate 10s linear infinite;
}

@keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

@-webkit-keyframes animate {
  0% {
    transform: translate(-50%, -75%) rotate(0deg);
  }

  100% {
    transform: translate(-50%, -75%) rotate(360deg);
  }
}

/*Header CSS */

.header {
  height: 60px;
  background: linear-gradient(288deg, #2e5afa -69.99%, #000 172.69%);
}

.finops_name {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  align-items: center;
  cursor: pointer;
}

.anaylyzing_name {
  opacity: 0.5;
  color: var(--White, #fff);
  font-family: Inter, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  /* padding-left: 4px; */
}

.cloud_options {
  font-family: Inter, sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown_icon {
  margin: auto;
}

select {
  background-color: transparent;
}

.cloud_options select {
  background: none;
  color: #fff;
  align-items: flex-start;
}

.cloud_options select option {
  /* display: flex; */
  width: 144px;
  padding: 8px 12px;
  /* flex-direction: column;
align-items: flex-start;
gap: 8px; */
}

.cloud_options select svg {
  color: black;
}

select svg {
  opacity: 5% !important;
}

select option {
  /* background: #132462; */
  align-items: start;
}

.logo {
  font-size: 23px;
  font-weight: 500;
  color: #fff !important;
}

.logo a:hover {
  color: #fff !important;
}

.logo span.small {
  color: #fff !important;
  font-weight: 500;
}

.search-box {
  border-radius: 50px;
}

.search-box {
  display: inline;
  width: 200px;
  transition: 500ms !important;
}

.search-box:focus {
  width: 240px;
}

.flex-header-menu,
.flex-social {
  display: flex;
}

.mt-13 {
  margin-top: 13px;
}

.dropdown-menu.mt-10 {
  margin-top: 0.1rem !important;
}

.menu-icon {
  position: relative;
  display: inline-block;
  font-size: 18px;
  margin: 5px 15px 0px 10px;
  cursor: pointer;
}

.menu-icon:first-child {
  margin: 5px 15px 0px 0px;
  color: #313246;
}

.menu-icon:last-child {
  margin-right: 0px;
}

.menu-icon .badge {
  position: absolute;
  border-radius: 30px;
  top: -5px;
  left: 5px;
  transition: 300ms;
  animation: myAnim 2s infinite;
  -webkit-animation: myAnim 300ms infinite;
  -moz-animation: myAnim 300ms infinite;
}

.menu-icon:hover .badge {
  top: -7px;
  left: 7px;
}

@keyframes myAnim {
  from {
    top: -5px;
    left: 5px;
  }

  to {
    top: -7px;
    left: 7px;
  }
}

.menu-icon .sidebar-toggle-btn:before {
  content: "\f0c9";
  font-family: FontAwesome !important;
  display: inline-block;
  color: white;
  transition: 300ms;
}

.menu-icon .sidebar-toggle-btn.slide-in:before {
  content: "\f00d";
  font-family: FontAwesome !important;
  display: inline-block;
  color: white;
  font-size: 18px;
}

/* .dropdown {
/* .dropdown {
    position: absolute;
    z-index: 1;
    display: none;
} */

/* .dropdown.dropdown-left {
  left: -10px !important;
}

.dropdown.dropdown-right {
  right: -10px !important;
} */

.dropdown-item media .media-body > h6,
p,
small {
  line-height: 8px;
}

.media-body h6 {
  margin-bottom: 3px;
}

.media-body p {
  font-size: 13px !important;
  margin-bottom: 0px;
}

.link-all {
  font-weight: bold;
  color: #7c68685e;
}

.notify-icon {
  width: 46px;
  height: 46px;
  text-align: center;
  font-size: 20px;
}

.notify-icon i {
  color: #fff;
  margin-top: 13px;
}

.notify-icon_2 {
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 20px;
}

.notify-icon_2 i {
  color: #fff;
  margin-top: 0px;
  font-size: 60px;
}

/* Main Content */
/* .content-section {
    position: relative;
    background-color: #f1f2f3 !important;
} */

/* Sidebar */

.sidebar {
  height: 100vh;
  min-height: 100vh;
  padding: 0px;
  width: 260px;
  transition-duration: 0.5s;
}
.sidebar:hover {
  /* width: 260px; */
  transition-timing-function: ease-in;
  overflow: hidden;

  /* Quick on the way out */

  /* Hide thing by pushing it outside by default */
  /* transform: translateY(130%); */
  /* Quick on the way out */
  transition: 1.5s;

  /* Hide thing by pushing it outside by default */
  /* transform: translateY(130%); */
}

.sidebar > .inner-sidebar {
  height: 100%;
  /* background: #4B4B4B; */
  background: #132462;
  /* box-shadow: 20px 0px 34px 0px rgba(0, 0, 0, 0.25); */
  width: 100%;
  padding: 0px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.mainDropdownMenu {
  padding: 8px 12px 8px 12px !important;
  border-radius: 8px;
  border: 1px solid #e9ecef;
  z-index: 101;
}

.side_name {
  color: var(--neutral-d-7, #dee2e6);
  /* font-family: Inter; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  /* line-height: normal; */
}

.avatar img {
  width: 75px;
  height: 75px;
  margin-top: 25px;
  margin-bottom: 10px;
  border: 3px solid #fff;
}

.avatar > p {
  color: #fff;
}

.sidebar-menu {
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  position: relative !important;
  display: block;
  /* margin-bottom: 16px; */
}

.sidebar-menu li a {
  border-radius: 8px;
  display: flex !important;
  gap: 8px !important;
  align-items: center;
}

.sidebar-menu li a.active {
  color: white !important;
  border-radius: 8px;
  background: #0a1333;
  display: flex;
  gap: 10px;
  align-items: center;
}

.sidebar-menu .children li.child:first-child {
  padding-top: 10px !important;
}

.sidebar-menu .children li.child:last-child {
  padding-bottom: 10px !important;
}

.sidebar-menu li ul {
  width: 100%;
  height: auto;
  background: #5f5f5f;
  display: none;
}

.sidebar-menu li ul li {
  border: 0px;
}

/* Style for toggling sidebar width */

.sidebar.shrink-sidebar,
.header-logo.shrink-sidebar {
  max-width: 56px !important;
  min-width: 56px !important;
}

.header-logo.shrink-sidebar span {
  display: none;
}

.sidebar.shrink-sidebar .avatar img {
  width: 40px;
  height: 40px;
}

.sidebar.shrink-sidebar .avatar p,
.sidebar.shrink-sidebar .avatar span.small {
  display: none;
}

.sidebar.shrink-sidebar .sidebar-menu li {
  position: relative !important;
  /* border-bottom: 1px solid #313246; */
}

.sidebar.shrink-sidebar .sidebar-menu li a {
  /* padding-top: 10px; */
}

/* .sidebar.shrink-sidebar .sidebar-menu li a:hover {
    padding-left: 15px;
} */
.main_container {
  position: absolute;
  left: 55px;
  padding: 0px;
  width: calc(100vw - 56px);
  margin-top: 54px;
  /* padding-bottom: 33px; */
}

.main_cont_padding {
  padding-bottom: 32px;
}

.overlay {
  /* opacity: 0.2; */
  background: var(--neutral-d-1, #2125291b);
  height: 100vh;
  /* width:calc(100vw-294px) */
  min-height: 400;
  width: 82%;
}

.sidebar.shrink-sidebar .sidebar-menu li a span.none {
  display: none;
}

.sidebar.shrink-sidebar .sidebar-menu li ul {
  position: absolute;
  z-index: 1000;
  top: 0px;
  left: 50px;
  width: 270px !important;
  /* display: none !important; */
  border-radius: 10px;
}

.subMenu {
  display: block !important;
}

.submenu-inactive {
  display: none !important;
}

.sidebar.shrink-sidebar .sidebar-menu li:hover ul {
  display: block;
}

.sidebar.shrink-sidebar .sidebar-menu li ul li {
  border-bottom: 1px solid #2e2e3a;
}

.sidebar.shrink-sidebar .sidebar-menu li ul li:last-child {
  border-bottom: none;
}

.sidebar.shrink-sidebar .sidebar-menu li:hover ul li a {
  padding-left: 2px;
}

/* Style for toggling sidebar width */

/* Sidebar */

/* Content */

.content.expand-content,
.header-menu.expand-content {
  min-width: 75%;
}

.card-main {
  display: flex;
}

.panel-head-success {
  border-top: 4px solid #29a744;
}

.panel-head-primary {
  border-top: 4px solid #0073aa;
}

.panel-head-danger {
  border-top: 4px solid #ed6a5a;
}

.panel-head-warning {
  border-top: 4px solid #f1c40f;
}

.panel-head-info {
  border-top: 4px solid #169db2;
}

.card-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: 15px;
}

.card-loader {
  width: 100px;
  height: 100px;
  text-align: center;
  font-size: 35px;
  font-weight: bold;
  color: #555;
}

.card-sales {
  border-radius: 50%;
  border-right: 10px solid #169db2;
  border-left: 10px solid #efefef;
  border-top: 10px solid #efefef;
  border-bottom: 10px solid #efefef;
}

.card-users {
  border-radius: 50%;
  border-right: 10px solid #efefef;
  border-left: 10px solid #f1c40f;
  border-top: 10px solid #f1c40f;
  border-bottom: 10px solid #efefef;
}

.card-session {
  border-radius: 50%;
  border-right: 10px solid #efefef;
  border-left: 10px solid #313246;
  border-top: 10px solid #313246;
  border-bottom: 10px solid #313246;
}

.card-views {
  border-radius: 50%;
  border-right: 10px solid #efefef;
  border-left: 10px solid #ed6a5a;
  border-top: 10px solid #efefef;
  border-bottom: 10px solid #efefef;
}

.info h2 {
  color: #313246;
  font-weight: 400;
}

.info span {
  color: #666;
  font-weight: 500;
  font-size: 16px;
}

.card-greet {
  margin-bottom: 0px;
}

.card-notice {
  font-weight: 500;
  color: #efefef;
  font-size: 14px;
}

.media-body p.message {
  line-height: 17px;
}

.sent {
  font-size: 13px;
  color: #efefef;
}

.user-about h4,
.user-about p {
  color: #555;
  font-weight: 500;
}

.p-space {
  line-height: 25px;
  font-size: 15px;
  color: #555;
  font-weight: 500;
}

.flex-social {
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -moz-justify-content: space-between;
}

.flex-social a {
  display: inline-block;
}

#chartContainer {
  height: 360px;
}

.calendar-box {
  font-size: 14px;
  font-weight: 500;
}

.calendar .table {
  margin-bottom: 10px !important;
}

.visualmonthyear {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.time h6 {
  color: #505050;
  font-size: 15px;
}

.time .badge {
  border-radius: 30px;
  padding: 5px;
}

#myChart {
  height: 270px !important;
}

.ct-label.ct-horizontal.ct-end,
.ct-label.ct-vertical.ct-start {
  color: #333 !important;
  font-weight: bold !important;
}

.footer span,
footer > span {
  font-size: 16px;
  color: #555;
  font-weight: 500 !important;
}

/* Content */

/* Dashboard 3 Content */
.fb-widget-top-desc h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px !important;
}

.fb-widget-bottom p small {
  font-weight: 500 !important;
  font-size: 11px;
}

.fb-widget-bottom h5 {
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 2px !important;
}

.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 80%;
  max-height: 250px;
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 3.8;
}

.circle {
  fill: none;
  stroke-width: 2.8;
  stroke-linecap: round;
  animation: progress 1s ease-out forwards;
}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}

.circular-chart.orange .circle {
  stroke: #ff9f00;
}

.circular-chart.green .circle {
  stroke: #4cc790;
}

.circular-chart.blue .circle {
  stroke: #3c9ee5;
}

.circular-chart.red .circle {
  stroke: #ea5941;
}

.circular-chart.theme .circle {
  stroke: #0d47a1;
}

.percentage {
  fill: #666;
  font-family: FontAwesome;
  font-size: 0.3em;
  text-anchor: middle;
}

.text-green {
  color: rgb(29, 145, 93);
}

.bg-green {
  background: rgb(29, 145, 93);
  color: #fff;
}

.custom-card {
  font-weight: 500;
}

.custom-card h5,
.custom-card h5 small {
  font-weight: 500;
  color: #5c5c5c;
}

.custom-card h5 {
  margin-bottom: 0px !important;
}

.custom-card h2 {
  color: #5c5c5c;
}

/* Dashboard One CSS Rules*/
.customer-circle {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: #0d47a1;
  box-shadow: 0px 0px 0px 3px #ccc;
  border-radius: 50%;
  margin-left: -1rem;
  position: relative;
  background-size: cover;
  background-position: center;
}

.customer-circle.end {
  top: -0.9rem;
}

/*Main Content */

/*====== Dasboard CSS Rules =====*/

input.active:valid,
select.active:valid,
textarea.active:valid,
.search-wrapper.valid {
  box-shadow: 0 0 5px 1px #ffc72c !important;
}

input[name="operating_system_input"]:valid {
  box-shadow: none !important;
}

input.active:invalid,
select.active:invalid,
textarea.active:invalid,
.search-wrapper.invalid {
  box-shadow: 0 0 5px 1px red !important;
}

.invalidValueError,
.invalidValueError:focus {
  box-shadow: 0 0 5px 1px red !important;
}

.error {
  color: red;
  font-size: 14px;
}

.sc-ezOQGI.hoQsHK {
  line-height: 0;
}

.gMliOo svg {
  top: 9px !important;
}

/*=======Buttons Page CSS Rules === */
.button-container {
  line-height: 45px;
  border-radius: 10px;
}

.button-container.lh-sm {
  line-height: 20px !important;
}

.button-container h6,
.bc-header {
  font-size: 18px;
  color: #5c5c5c;
  font-weight: 500;
}

.bc-header-small {
  font-size: 16px;
  color: #5c5c5c;
  font-weight: 500;
}

.button-container p,
.bc-description {
  font-weight: 500;
  font-size: 14px;
  color: #5c5c5c;
}

.btn-round {
  border-radius: 50px;
}

.icon-round {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

/*======== Accordion CSS Rules ========== */
.accordion-header {
  padding: 0px !important;
  margin: 0px;
}

.panel-title > button {
  font-weight: 500;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left !important;
  background: #0d47a1;
  border-radius: 0px;
  padding: 8px;
  color: #fff;
}

.panel-title > button.collapsed {
  background: #f7f7f7;
  color: #5c5c5c;
}

.panel-title > button:hover {
  background: #0d47a1;
  color: #fff;
}

.accordion-btn:visited,
.accordion-btn:focus,
.accordion-btn:hover {
  text-decoration: none;
}

.accordion-body p {
  line-height: 23px !important;
}

.panel-title > button:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f068";
  padding-right: 5px;
}

.panel-title > button.collapsed:before {
  float: right !important;
  content: "\f067";
}

/* Accordion style 2 */
.panel-title-2 > button {
  font-weight: 500;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left !important;
  background: #ed6a5a;
  border-radius: 0px;
  padding: 8px;
  color: #fff;
}

.panel-title-2 > button.collapsed {
  background: #f7f7f7;
  color: #5c5c5c;
}

.panel-title-2 > button:hover {
  background: #ed6a5a;
  color: #fff;
}

.panel-title-2 > button:hover {
  background: #ed6a5a;
  color: #fff;
}

.panel-title-2 > button:before {
  float: left !important;
  font-family: FontAwesome;
  content: "\f101";
  padding-right: 15px;
}

.panel-title-2 > button.collapsed:before {
  float: left !important;
  content: "\f103";
}

/* Accordion style 3 */
.panel-title-3 > button {
  font-weight: 500;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left !important;
  background: #29a744;
  border-radius: 0px;
  padding: 8px;
  color: #fff;
}

.panel-title-3 > button.collapsed {
  background: #f7f7f7;
  color: #5c5c5c;
}

.panel-title-3 > button:hover {
  background: #29a744;
  color: #fff;
}

.panel-title-3 > button:hover {
  background: #29a744;
  color: #fff;
}

.panel-title-3 > button:before {
  float: left !important;
  font-family: FontAwesome;
  content: "\f105";
  padding-right: 15px;
}

.panel-title-3 > button.collapsed:before {
  float: left !important;
  content: "\f107";
}

/*=========Tab CSS rules ============*/

/* .custom-tabs class 1 */
.custom-tabs {
  color: #5c5c5c;
}

.custom-tabs .nav-tabs .nav-item.show .nav-link,
.custom-tabs .nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent transparent #0d47a1;
  border-bottom: 4px solid #0d47a1 !important;
  font-weight: 500;
}

.custom-tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.custom-tabs .nav-tabs .nav-link:hover {
  border: 1px solid transparent;
}

/* .custom-tabs class 1 */

/* .custom-tabs class 2 */
.custom-tabs-2 .nav-tabs .nav-item.show .nav-link,
.custom-tabs-2 .nav-tabs .nav-link.active {
  background-color: #007bff;
  border: 1px solid #007bff !important;
  font-weight: 500;
  color: #fff;
  padding: 0rem !important;
}

.custom-tabs .nav-tabs .nav-link,
.custom-tabs-2 .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  padding: 0rem !important;
}

/* custom-tabs class 2 */

#tabs {
  background: #007b5e;
  color: #eee;
}

#tabs .nav-tabs .nav-item.show .nav-link,
#tabs .nav-tabs .nav-link.active {
  color: #f3f3f3;
  background-color: transparent;
  border-color: transparent transparent #f3f3f3;
  border-bottom: 4px solid !important;
  font-size: 17px;
  font-weight: 500;
}

#tabs .nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #eee;
  font-size: 17px;
}

.tab-content-border {
  border-width: 0px 1px 1px 1px;
  border-color: #dee2e6;
  border-style: solid;
}

.tab-content-border .tab-pane p,
.tab-pane p,
.media-text {
  font-size: 14px !important;
  line-height: 25px !important;
}

.nav-pills .nav-link {
  padding: 0rem 0.9rem;
  text-align: center;
}

/*=========Tab CSS rules ============*/

/*======== Typography CSS Rules ======= */

.p-typo {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 25px !important;
}

.list-typo li {
  line-height: 25px !important;
}

/*======Typography CSS Rules =======*/

/*======Icons CSS Rules =======*/

.icons-list div {
  font-size: 14px;
  color: #5c5c5c;
  font-weight: 500;
}

.icons-list div i {
  font-size: 15px;
  padding-right: 10px;
  transition: 100ms;
}

.icons-list div:hover i {
  font-size: 28px;
}

/*======Icons CSS Rules =======*/

/*=========Notification CSS Rules === */

.ajs-message.ajs-custom {
  color: #fff !important;
  background-color: #0d47a1 !important;
  border-color: #0d47a1 !important;
}

.ajs-message.ajs-custom:before {
  content: "\f1d7";
  font-family: FontAwesome;
  margin-right: 10px;
  font-size: 17px;
  color: #fff;
}

.ajs-message.ajs-success {
  color: #fff !important;
  background-color: #29a744 !important;
  border-color: #29a744 !important;
  font-size: 14px;
}

.ajs-message.ajs-success:before {
  content: "\f00c";
  font-family: FontAwesome;
  margin-right: 10px;
  font-size: 17px;
  color: #fff;
}

.ajs-message.ajs-error {
  color: #fff !important;
  background-color: rgb(175, 15, 15) !important;
  border-color: rgb(175, 15, 15) !important;
  font-size: 14px;
}

.ajs-message.ajs-error:before {
  content: "\f00d";
  font-family: FontAwesome;
  margin-right: 10px;
  font-size: 17px;
  color: #fff;
}

.ajs-message.ajs-warning {
  color: #fff !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
  font-size: 14px;
}

.ajs-message.ajs-warning:before {
  content: "\f071";
  font-family: FontAwesome;
  margin-right: 10px;
  font-size: 17px;
  color: #333;
}

/* ========Notification CSS Rules ======*/

/* ==========Widget CSS Rules ==========*/

.updates-wrapper {
  position: relative;
  border-left: 2px solid #dee2e6 !important;
}

.updates-content {
  position: relative;
}

.updates-content:before {
  content: "";
  position: absolute;
  top: 20px;
  left: -5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.updates-content.up-success:before {
  background: #007b5e !important;
}

.updates-content.up-warning:before {
  background: #ffc107 !important;
}

.updates-content.up-primary:before {
  background: #007bff !important;
}

.updates-content.up-danger:before {
  background: rgb(175, 15, 15) !important;
}

.chat-left p,
.chat-right p {
  position: relative;
}

.chat-left,
.chat-right {
  margin-top: 0rem;
}

.chat-left small,
.chat-right small {
  font-size: 12px !important;
}

.chat-left p span {
  background: #9db0c5b4;
  padding: 7px;
  border-radius: 0px 20px 20px 20px;
  color: #5c5c5c;
}

.chat-left.chat-others p span {
  border-radius: 20px;
}

.chat-right p span {
  background: #007bff;
  padding: 7px;
  border-radius: 20px 0px 20px 20px;
  color: #fff;
}

.chat-right.chat-others p span {
  border-radius: 20px;
}

.pw-2 i {
  font-size: 70px;
}

.pw-3 i,
.pw-3 small {
  font-size: 70px;
}

.pw-3 i {
  padding-left: 20px;
}

.cw-1 {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-bottom: 10px;
  text-align: center;
}

.cw-1 canvas {
  position: absolute;
  top: 0;
  left: 0;
}

.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
}

.percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 0.8em;
}

/* ==========Widget CSS Rules ==========*/

/* ============= Form CSS Rules ======= */

.form-control-primary,
.form-control-primary:focus {
  border: 1px solid #0073aa;
  color: #0073aa;
}

.form-control-warning,
.form-control-warning:focus {
  border: 1px solid #f1c40f;
  color: #f1c40f;
}

.form-control-danger,
.form-control-danger:focus {
  border: 1px solid #ea5941;
  color: #ea5941;
}

.form-control-info,
.form-control-info:focus {
  border: 1px solid #169db2;
  color: #169db2;
}

.form-control-success,
.form-control-success:focus {
  border: 1px solid #29a744;
  color: #29a744;
}

.fc-bg-primary,
.fc-bg-primary:focus,
.fc-bg-primary::-webkit-input-placeholder {
  background: #0073aa;
  border: 1px solid #0073aa;
  color: #fff;
}

.fc-bg-warning,
.fc-bg-warning:focus,
.fc-bg-warning::-webkit-input-placeholder {
  background: #f1c40f;
  border: 1px solid #f1c40f;
  color: #fff;
}

.fc-bg-danger,
.fc-bg-danger:focus,
.fc-bg-danger::-webkit-input-placeholder {
  background: #ea5941;
  border: 1px solid #ea5941;
  color: #fff;
}

.fc-bg-info,
.fc-bg-info:focus,
.fc-bg-info::-webkit-input-placeholder {
  background: #169db2;
  border: 1px solid #169db2;
  color: #fff;
}

.fc-bg-success,
.fc-bg-success:focus,
.fc-bg-success::-webkit-input-placeholder {
  background: #29a744;
  border: 1px solid #29a744;
  color: #fff;
}

.custom-control-label {
  line-height: 25px !important;
  /* vertical-align: middle !important; */
}

.custom-control-label1 {
  line-height: 24px !important;
  vertical-align: middle !important;
}

span.tag.label.label-info {
  background: #169db2;
  padding: 2px 5px;
  font-size: 14px;
}

.floating-label {
  position: relative;
  margin-top: 40px !important;
}

.floating-label label {
  position: absolute;
  pointer-events: none;
  color: #6c757d;
  top: 9px;
  font-size: 14px;
  left: 12px;
  transition: 0.5s;
}

.floating-label input:focus ~ label,
.floating-label input:valid ~ label,
.floating-label select:focus ~ label,
.floating-label select:valid ~ label {
  top: -25px !important;
  left: 0px;
  font-size: 15px;
  color: #0073aa;
}

#validation_style span.help-block.form-error {
  display: inline-block;
  width: 100%;
  text-align: right;
  padding: 0px 20px;
}

/*  ========== Form CSS Rules ==========*/

/*==========Editor CSS Rules========*/

#inline_editor {
  font-size: 16px !important;
}

.editor p,
.note-editable.card-block p {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 15px;
}

/*========Editor CSS Rules=========*/

/*========Chartist chart styles========*/

#lineChartChartist .ct-series-a .ct-line {
  /* Set the colour of this series line */
  stroke: #3dc9b0;
  /* Control the thikness of your lines */
  stroke-width: 4px;
}

#lineChartChartist .ct-series-a .ct-point {
  /* Set the colour of this series line */
  stroke: #3dc9b0;
  /* Control the thikness of your lines */
  stroke-width: 10px;
}

#lineChartChartist .ct-series-b .ct-line {
  /* Set the colour of this series line */
  stroke: #3a5276;
  /* Control the thikness of your lines */
  stroke-width: 4px;
}

#lineChartChartist .ct-series-b .ct-point {
  /* Set the colour of this series line */
  stroke: #3a5276;
  /* Control the thikness of your lines */
  stroke-width: 10px;
}

#areaChartChartist .ct-series-a .ct-area {
  fill: #3dc9b0;
}

#areaChartChartist .ct-series-a .ct-line {
  /* Set the colour of this series line */
  stroke: #3dc9b0;
  /* Control the thikness of your lines */
  stroke-width: 3px;
}

#areaChartChartist .ct-series-a .ct-point {
  /* Set the colour of this series line */
  stroke: #3dc9b0;
  /* Control the thikness of your lines */
  stroke-width: 7px;
}

#overlapBarchartChartist .ct-series-a .ct-bar {
  stroke: #3dc9b0;
}

#overlapBarchartChartist .ct-series-b .ct-bar {
  stroke: #3a5276;
}

#costRevenue .ct-series-a .ct-bar {
  stroke: #65b5c2;
}

#costRevenue .ct-series-b .ct-bar {
  stroke: #0d47a1;
}

#chartContainer .ct-series-a .ct-line {
  /* Set the colour of this series line */
  stroke: #65b5c2;
  fill: #65b5c2;
  /* Control the thikness of your lines */
  stroke-width: 4px;
}

#chartContainer .ct-series-a .ct-point {
  /* Set the colour of this series line */
  stroke: #65b5c2;
  /* Control the thikness of your lines */
  stroke-width: 10px;
}

#chartContainer .ct-series-b .ct-line {
  /* Set the colour of this series line */
  stroke: #23649e;
  fill: #23649e;
  /* Control the thikness of your lines */
  stroke-width: 4px;
}

#chartContainer .ct-series-b .ct-point {
  /* Set the colour of this series line */
  stroke: #23649e;
  /* Control the thikness of your lines */
  stroke-width: 10px;
}

#stackedBarchartChartist .ct-series-a .ct-bar {
  stroke: #3dc9b0;
}

#stackedBarchartChartist .ct-series-b .ct-bar {
  stroke: #3a5276;
}

#pieChartChartist .ct-chart-pie .ct-label,
#donutChartChartist .ct-chart-donut .ct-label {
  color: #fff;
  fill: #fff;
}

#pieChartChartist .ct-series-a .ct-slice-pie {
  stroke: #3a5276;
  fill: #3a5276;
}

#pieChartChartist .ct-series-b .ct-slice-pie {
  stroke: #3dc9b0;
  fill: #3dc9b0;
}

#donutChartChartist .ct-series-a .ct-slice-donut-solid {
  stroke: #3a5276;
  fill: #3a5276;
}

#donutChartChartist .ct-series-c .ct-slice-donut-solid {
  stroke: #3dc9b0;
  fill: #3dc9b0;
}

#areaChartFb .ct-series-a .ct-line {
  /* Set the colour of this series line */
  stroke: #fff;
  /* Control the thikness of your lines */
  stroke-width: 3px;
}

#areaChartFb .ct-series-a .ct-point {
  /* Set the colour of this series line */
  stroke: #fff;
  /* Control the thikness of your lines */
  stroke-width: 0px;
}

#areaChartFb .ct-labels .ct-label {
  color: #fff !important;
}

/*========Chartist chart styles========*/

/*===========Login page CSS Rules===========*/

.login-body {
  background: url("../img/profile.jpg");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.login-wrapper {
  background: rgba(0, 0, 0, 0.7);
  height: 100vh;
  position: relative;
}

.login-box {
  width: 60%;
  padding: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  transition: 300ms;
}

.login-box-info {
  background: #0d47a1;
  padding: 45px 30px !important;
  text-align: center;
}

.login-box-info p {
  line-height: 25px !important;
}

.login-box-form {
  background: #fff;
  color: #333;
  padding: 45px 30px !important;
}

.login-box-info a,
.login-box-form button,
.login-box-form input[type="submit"] {
  font-size: 15px;
  font-weight: 500;
}

.btn-theme {
  background: #0d47a1;
  color: #fff;
}

.btn-outline-theme {
  color: #0d47a1;
  border: 1px solid #0d47a1;
  background: #fff;
}

.btn-outline-theme:hover {
  color: #fff;
  background: #0d47a1;
}

.text-theme {
  color: #0d47a1 !important;
}

/*==Login and Register page CSS Rules=====*/

/*========Profile page CSS Rules=========*/

.profile-bg {
  background-image: url("../img/profile.jpg");
  background-size: cover;
  background-position-y: 60%;
  background-repeat: no-repeat;
  height: 150px;
}

.profile-img {
  position: absolute;
  bottom: 40px;
  z-index: 100;
}

.bio-header h3 {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  line-height: 10px;
}

.feed-single .card-title {
  font-size: 16px !important;
  font-weight: 500;
}

.feed-single .card-img-top {
  width: 100% !important;
  height: auto !important;
}

.feed-footer {
  font-size: 13px;
  color: #aaa;
}

/*======Profile Page CSS Rules==========*/

/*======Search result Page CSS Rules==========*/

.search-header {
  font-weight: 500;
  margin-top: 30px;
}

.search-slug {
  font-weight: 500;
}

.search-single h5 {
  margin-bottom: 0px;
  font-size: 20px;
  font-weight: 500;
}

.search-single a {
  font-size: 14px;
  font-weight: 500;
  color: #0d47a1;
}

.search-single p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px !important;
}

/*======Search result Page CSS Rules==========*/

/*======Email Page CSS Rules==========*/

.email-menu a,
.email-form label,
.email-form input,
.email-form button,
.email-msg button,
.email-msg a {
  font-size: 15px !important;
}

.email-chat h6 small {
  font-size: 13px;
  margin-top: 10px;
  font-weight: 500;
}

/*======Email compose Page CSS Rules==========*/

/*=======Email Inbox Page CSS Rules =============*/

/*form element checkbox css*/

.form-check-box input[type="checkbox"] {
  display: none;
}

.form-check-box input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #2e2e3a;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-size: 14px;
}

.form-check-box input[type="checkbox"] + label:last-child {
  margin-bottom: 0;
}

.form-check-box input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border: 2px solid #5c5c5c;
  position: absolute;
  left: 8px;
  top: -11px;
  -webkit-transition: all 0.12s, border-color 0.08s;
  transition: all 0.12s, border-color 0.08s;
  border-radius: 0px;
}

.form-check-box input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: -17px;
  left: 10px;
  border-radius: 0;
  opacity: 1;
  filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  border-top-color: transparent;
  border-left-color: transparent;
  border-bottom-color: #5c5c5c;
  border-right-color: #5c5c5c;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.form-check-box input[type="checkbox"]:checked + label:after {
  height: 13px;
  width: 13px;
  content: "";
  position: absolute;
  left: 8px;
  top: -11px;
  border: 2px solid #5c5c5c;
}

.form-check-box.cta input[type="checkbox"] + label {
  display: inline-block;
  margin-right: 10px;
  top: 0px;
  left: 5px;
}

/*=======Email Inbox Page CSS Rules============*/

/*========Products page CSS Rules============*/

.product-list table,
.product-list button,
.product-list table tbody tr td p {
  font-size: 15px;
}

.product-list table tbody tr td p {
  font-weight: 400 !important;
}

.product-list table tbody tr td button i {
  font-size: 18px !important;
}

#productList_length,
#productList_filter,
#productList_info {
  font-size: 15px;
  color: #333;
}

#productList_length label,
#productList_filter label,
#productList_info {
  font-weight: 500;
}

/*========Products page CSS Rules============*/

/*=========Product detail page CSS Rules========*/

.slick-prev {
  left: 1px !important;
}

.slick-next {
  right: 1px !important;
}

.product-slug {
  font-size: 14px;
  line-height: 26px !important;
  color: #000;
}

.user-rating .br-theme-fontawesome-stars .br-widget a {
  font: normal normal normal 15px/1 FontAwesome !important;
  margin-right: 3px;
}

.user-rating .br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #007b5e;
}

.user-rating .br-theme-fontawesome-stars .br-widget a.br-selected:after {
  color: #007b5e;
}

/*======Product detail page CSS Rules==========*/

/*===========Orders Page CSS Rules==========*/

.btn-order-bulk .nice-select:after {
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
}

.btn-order-bulk .nice-select .current {
  color: #fff !important;
}

.order-qty {
  width: 40px;
  margin: 0;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

.order-qty-head {
  width: 40px !important;
  padding: 0.75rem 0.3rem !important;
}

/*==========Orders Page CSS Rules============*/

/*==========Fullcalendar Page CSS Rules============*/

.fc-content,
.fc-title,
.fc-time {
  color: #0d47a1 !important;
  font-weight: 400;
  font-size: 13px !important;
}

.fc-event {
  border-radius: 0px !important;
  border: 1px solid #0d47a1 !important;
  border-left-width: 4px !important;
  background: #efefef !important;
  padding: 2px !important;
}

.fc-row thead th {
  background: #0d47a1;
  border: none;
  padding: 7px;
  color: #fff;
}

.fc-center h2 {
  font-size: 20px;
  margin-top: 12px !important;
}

.fc-button.fc-state-default {
  background: #fff;
  border-radius: none !important;
  height: 45px;
}

.fc-button.fc-state-default.fc-state-active {
  background: #0d47a1;
  color: #fff;
}

/*==========LogOut Button CSS Rules============*/
.logout {
  width: 130px !important;
  height: 36px;
  padding: 9px 16px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin-top: 1px;
  font-family: "Inter";
  border: 1px solid #ffc72c;
  background: #ffc72c;
  color: #4b4b4b;
}

.disabled-btn,
.custom-btn {
  width: 130px;
  height: 36px;
  padding: 9px 16px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin-top: 1px;
  border: 1px solid #828282;
  background: #4b4b4b;
  color: #bdbdbd;
  font-style: normal;
  font-family: "Inter";
}

/*==========MultiSelect Field CSS Rules============*/
.multiselec-parent {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

/* .search-wrapper {
  height: 40px;
  overflow-y: scroll;
} */

.multiSelectContainer input {
  width: 100%;
}

/*==========Tooltip CSS Rules============*/
.tip {
  position: relative;
  display: inline-block;
}

.label_margin_css {
  margin: 0 !important;
}

.tip + .tiptext {
  visibility: hidden;
  width: max-content;
  max-width: 590px;
  background-color: black;
  color: #fff;
  text-align: justify;
  border-radius: 6px;
  line-height: 30px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 3%;
  bottom: 96%;
  padding: 0 8px;
}

.tip:hover + .tiptext {
  visibility: visible;
}

/* .tip+.tiptext::after {
    content: " ";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
  } */

/*==========DatePicker CSS Rules============*/

.react-datepicker__navigation {
  /* top: 20px !important; */
  padding: 10px 0 0 0 !important;
}

.react-datepicker__time-list-item {
  padding: 0px 10px !important;
}

.react-datepicker-wrapper {
  line-height: 35px;
}

.react-datepicker-popper {
  z-index: 2;
}

/*==========Dashboard Breadcrum CSS Rules============*/

.section_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 19px !important;
  line-height: 23px !important;
  color: #4b4b4b !important;
}

.program_heading {
  font-weight: 600 !important;
  font-size: 15px !important;
  line-height: 18px !important;
  color: #4b4b4b !important;
}

.submission_text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center;
  color: #000000 !important;
}

.submission_icon {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.portal_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #f2f2f2;
}

.custom-btn,
.update-btn {
  font-family: "Inter";
  width: 233px !important;
  border: 1px solid #ffc72c;
  background: #ffc72c;
  color: #4b4b4b;
}

.update-btn {
  width: 153px !important;
}

.submit-btn {
  width: 130px !important;
  font-family: "Inter";
  border: 1px solid #ffc72c;
  background: #ffc72c;
  color: #4b4b4b;
}

.submit-btn2 {
  width: 130px !important;
}

.next-btn {
  width: 130px !important;
}

.back-btn {
  width: 130px !important;
  border: #ffc72c;
  background: #ffc72c;
  color: #4b4b4b !important;
}

.btn-no-drop {
  cursor: no-drop !important;
}

.review-btn {
  width: 95px !important;
  background: #ffffff;
  color: #828282 !important;
}

.delete {
  border: 1px solid #da291c;
  width: 144px;
  background: #ffffff;
  color: #da291c;
}

.right-arrow {
  font-size: 24px !important;
  vertical-align: middle;
}

.button-card {
  background: #fafafa !important;
  border-radius: 5px !important;
}

.button-card p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #4b4b4b;
}
/* -----------------css for breadcrum arrow DONT'T REMOVE IT!!!..................... */
.breadcrumb > li + li:before {
  content: "\f105" !important;
  color: #797b80 !important;
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 22px;
  vertical-align: middle;
}
.breadcrumb-item.active {
  color: #797b80 !important;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0px;
  text-decoration: none;
}
/* ------------------------------------------------------------------------------------- */

.first span {
  color: black;
}

.breadcrum_text,
.tech,
.breadcrum_text_user {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #4b4b4b !important;
  vertical-align: middle;
}

.active-1,
.active-2 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 4px 6px;
  border: 1px solid #4b4b4b !important;
  background-color: #f1f2f3 !important;
}

.active-2 {
  color: gray !important;
  border: 1px solid gray !important;
}

.tech {
  color: gray !important;
}

.breadcrum_text_user {
  background-color: #ffc72c !important;
}

#home {
  vertical-align: middle !important;
}

.search_box,
.filter_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 9px 10px;
  /* width: 438px !important; */
  height: 36px;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #d3d8de;
}

.search_box::placeholder {
  width: 392px;
  height: 18px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #9fa5a8;
}

.filter_btn {
  padding: 10px 0px 9px;
  width: 83px;
}

.filter_btn span {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.ticket_box {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  line-height: 16px;
}

.ticket_text {
  width: max-content;
  height: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 12px !important;
  line-height: 15px !important;
  text-transform: uppercase;
  color: #9fa5a8 !important;
}

.ticket_tot_count {
  /* width: 74px; */
  height: 39px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 32px !important;
  line-height: 39px !important;
  color: #000000 !important;
}

.ticket_indiv_count {
  /* width: 74px; */
  height: 29px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 29px !important;
  color: #000000 !important;
}

.ticket_sep {
  /* width: 173px; */
  height: 5px;
  background: #f2c94c;
  border-radius: 1px;
}

.ticket-container {
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  /* height: 85px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ticket_status {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #4b4b4b;
  /* width: 33px; */
  height: 16px;
}

.ticket_dot {
  width: 8px;
  height: 8px;
  background-color: #f2c94c;
  border-radius: 50% !important;
  display: inline-block;
}

.open {
  background-color: #f2c94c;
}

.closed {
  background-color: #000000;
}

.completed {
  background-color: #27ae60;
}

.in-progress {
  background-color: #2d9cdb;
}

.tooltip_status_value {
  /* width: 41px; */
  height: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
}

.dis-block {
  display: block !important;
}

.map-nav-btn,
.map-nav-btn:hover,
.map-nav-btn:active,
.map-nav-btn:focus,
.show > .btn-secondary.dropdown-toggle,
.btn-secondary:not(:disabled):not(.disabled):active {
  background: #ffffff !important;
  border: 1px solid #d3d8de;
  border-radius: 5px;
  padding: 9px 12px;
}

.search_btn {
  position: absolute;
  top: -1px;
  right: 6%;
  border: 0;
  height: 38px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  outline: none;
  background-color: transparent;
}

.map-nav {
  background-color: #ccd3db;
  border-radius: 8px 8px 0px 0px;
  padding: 4px 5px;
}

.ticket-heading {
  font-weight: 700;
  font-size: 1rem;
  line-height: 29px;
  color: #333333;
  height: 29px;
}

.map-container {
  border-radius: 0px 0px 8px 8px;
  background-color: #dfe4eb;
}

.strip_tbl_head {
  height: 33px;
  background: #e1e4e8;
  border-radius: 8px;
}

.strip_tbl_head th {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  height: 17px;
  padding: 8px 16px;
}

.strip_tbl_content {
  height: 33px;
  background: transparent;
  border-radius: 4px;
}

.strip_tbl_content td {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  height: 17px;
  padding: 10px 16px;
}

.table-borderless tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}

.table-borderless tbody tr:nth-of-type(odd) {
  background-color: transparent;
}

/*==============Error 404 rules ==================*/

@media only screen and (min-width: 908px) and (max-width: 1074px) {
  .header-logo {
    max-width: 85px !important;
    min-width: 85px !important;
  }

  .header-logo.header-logo.shrink-sidebar {
    max-width: 250px !important;
    min-width: 250px !important;
  }

  .header-logo span {
    display: none;
  }

  /*.header-logo.shrink-sidebar span{
        display: block;
    }*/
  .header-menu {
    min-width: 90%;
  }

  .header-menu.header-menu.expand-content {
    min-width: calc(100% - 250px);
    position: absolute;
    right: 0;
  }
}

@media only screen and (max-width: 1074px) {
  .sidebar-menu li a {
    display: block;
    color: #ffffff !important;
    padding: 12px 20px;
  }

  .footer span,
  footer > span,
  a {
    font-size: 14px;
  }

  .login-box {
    width: 75%;
  }
}

.maintainance-menu-bar {
  display: none;
}

@media only screen and (max-width: 906px) {
  .maintainance-menu-bar {
    display: block;
    width: 100%;
    height: 50px;
    background-color: #4b4b4b;
  }

  .header-logo {
    max-width: 100% !important;
    min-width: 100% !important;
    margin: 0;
    padding: 0;
    /*position: absolute;*/
  }

  .header-logo.header-logo.shrink-sidebar {
    max-width: 100% !important;
    min-width: 100% !important;
    margin: 0;
    padding: 0;
  }

  .header-logo span {
    display: initial;
  }

  .header-logo .bg-theme {
    margin-right: 0px !important;
  }

  .header-menu {
    min-width: 100%;
    padding-left: 45px !important;
    padding-right: 30px !important;
    padding-bottom: 10px !important;
  }

  .header-menu.header-menu.expand-content {
    min-width: 100%;
  }

  /* .sidebar {
        max-width: 0px !important;
        min-width: 0px !important;
        width: 0px !important;
        min-height: 100%;
        overflow: hidden;
        height: 100%;
        padding: 0px;
        margin: 0px;
        z-index: 1000;
        position: absolute;
        top: 0px;
        bottom: 0px !important;
    }

    .sidebar .avatar img {
        display: none;
    }

    .sidebar .avatar p,
    .sidebar .avatar span.small {
        display: none;
    }

    .sidebar.shrink-sidebar .avatar {
        text-align: center;
    }

    .sidebar.shrink-sidebar .avatar img {
        display: inline;
    }

    .sidebar.shrink-sidebar .avatar p,
    .sidebar.shrink-sidebar .avatar span.small {
        display: block;
    } */

  .content {
    width: 100%;
    min-width: 100%;
  }

  .content.expand-content {
    width: 100%;
    min-width: 100%;
  }

  .content.pl-0 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

  .card-chats {
    width: 100%;
  }
}

@media only screen and (max-width: 868px) {
  .cont-1,
  .cont-2,
  .cont-3,
  .cont-4 {
    display: initial;
  }

  #card-chats,
  #card-all {
    margin-left: 0px !important;
  }

  #card-chats,
  #card-all,
  .card-pro,
  .card-traffic,
  .card-analytics,
  .card-calendar {
    display: block !important;
    min-width: 100%;
    width: 100% !important;
    box-sizing: border-box;
    padding: 0px;
  }

  .login-box {
    width: 85%;
  }

  .profile-img {
    top: 100px;
  }

  .bio-header {
    margin-left: 0px;
  }

  .bio-header,
  .bio-comment {
    display: block;
    min-width: 100% !important;
    text-align: center !important;
  }

  .bio-comment {
    padding: 5px 0px !important;
  }
}

@media only screen and (max-width: 748px) {
  .container-fluid {
    padding: 0px !important;
  }

  .header.pt-3.pb-2 {
    display: initial;
    padding: 0px !important;
    margin: 0px !important;
  }

  .header-logo {
    display: block;
    min-width: 100%;
    width: 100%;
    background: #0d47a1;
    /*padding: 10px 7px;*/
    margin: 0px;
  }

  .logo,
  .logo span.small {
    color: #fff !important;
    /*font-size: 23px;*/
  }

  .header-menu {
    display: block;
    min-width: 100%;
    padding: 8px 30px 10px 15px !important;
    /*box-shadow: 0px 2px 2px 0px #ccc;*/
  }

  .menu-icon:first-child {
    margin: 5px 15px 0px 30px;
  }

  .sidebar {
    top: 0px;
  }

  .sidebar.shrink-sidebar .sidebar-menu li a {
    padding-left: 25px !important;
  }

  .sidebar.shrink-sidebar .sidebar-menu li a:hover {
    padding-left: 30px !important;
  }

  .login-box {
    width: 95%;
  }
}

@media only screen and (max-width: 580px) {
  .search-box {
    display: none;
  }

  .profile-img {
    top: 27%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-height: 497px) {
  .dropdown-menu.mt-10 {
    margin-top: 2.7rem !important;
  }
}

@media (min-width: 1074px) {
  .sidebar-menu li.parent a.active,
  .sidebar-menu li.child.active {
    background-color: #3d3d3d;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 8px;
  }

  .sidebar-menu li a {
    display: block;
    color: #ffffff !important;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 12px 7px;
    transition: 300ms;
    /* margin-left: 10px;
    margin-right: 10px; */
  }
}
.margin_sidebar a {
  margin-left: 10px;
  margin-right: 10px;
}

.row-wrapper {
  gap: 10px;
}

@media screen and (max-width: 1300px) {
  .row-cols-5 > * {
    flex: 0 0 25%;
    justify-content: center;
    max-width: 30%;
  }

  .row-wrapper {
    justify-content: center;
  }

  /* .ticket-container 
     { 
        height: 200px; 
    } */
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.loading-wheel {
  width: 70px;
  height: 70px;
  margin-top: -40px;
  margin-left: -40px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.style-2 .loading-wheel {
  border: 10px solid #bec6d1;
  border-top: 10px solid #da291c;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(-360deg);
  }
}

:root {
  --toastify-color-progress-light: linear-gradient(
    to right,
    #ffc72c,
    #ffc72c,
    #ffc72c,
    #ffc72c,
    #ffc72c,
    #ffc72c
  ) !important;
}

.data-table-column-rsm-number {
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}

.form-control[readonly] {
  background-color: #e9ecef;
  color: #6c757d;
  opacity: 0.7;
}

.form-control[type="text"],
.form-control[type="number"],
.form-control[type="search"] {
  box-shadow: 0px 1px 0px 0px #0000000d !important;
  height: 36px;
  border-radius: 4px;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.search-text {
  padding: 7px 12px;
  border-radius: 10px;
}

.tooltip-totalcount {
  width: 45px;
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.tooltip-heading {
  padding: 8px !important;
  /* width: auto !important; */
  /* height: 155px !important; */
  /* White */
  background: #ffffff !important;
  border-radius: 8px !important;
  line-height: 15px !important;
}

.tooltip-head {
  /* width: 70px; */
  height: 19px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.tooltip-countryhead {
  /* width: 80px; */
  height: 16px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #9fa5a8;
}

.tooltip-circle {
  width: 70px;
  height: 15px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px !important;
  color: #000000;
}

.custom-border {
  border-bottom: 1px solid rgba(146, 152, 156, 0.3);
}

.filter-menu {
  width: 457px;
  height: auto;
}

.filter-btns {
  border: none;
  background: transparent;
  color: #007be5;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.filter-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  height: 17px;
}

.filter-select {
  border: none;
  padding: 0;
  margin: -5px 0 0;
  width: 100%;
  background: #fff url("../img/right-angle.png") right 3px center/8px 10px
    no-repeat;
  text-align: left;
}

span.ticket-tag.label.label-info {
  background: #ffffff;
  padding: 4px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-right: 5px;
  color: #000000;
  border: 1px solid rgba(146, 152, 156, 0.3);
  border-radius: 100px;
  width: 148px;
  height: 23px;
}

.ticket-tag [data-role="remove"] {
  padding: 4px 0px 4px 6px;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
}
.dropdown-item:hover {
  color: black;
}

.filter-menu .dropdown-item:active,
.filter-menu .dropdown-item:hover {
  color: transparent;
  background-color: transparent;
}

.submit-fil-btn {
  font-family: "Inter";
  width: 125px !important;
  border: 1px solid #ffc72c;
  background: #ffc72c;
  color: #4b4b4b;
  height: 36px;
  padding: 9px 12px 9px 0px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  margin-top: 1px;
}

.filter-content .react-datepicker__navigation {
  top: -6px !important;
}

.multi_select_Wrapper .multiSelectContainer {
  line-height: 20px;
}

.multi_select_Wrapper :first-child {
  width: 100% !important;
}

.multi_select_Wrapper :first-child .multiSelectContainer {
  line-height: 20px;
}

.multi_select_Wrapper :first-child ul {
  line-height: 5px;
}

/* switch */

div.custom-control-right {
  padding-right: 24px;
  padding-left: 0;
  margin-left: 0;
  margin-right: 0;
  /* display: flex; */
  /* align-items: center; */
}

div.custom-control-right .custom-control-label::after {
  right: -1.5rem;
  left: auto;
}

div.custom-control-right .custom-control-label::before {
  right: -2.35rem;
  left: auto;
}

div.custom-control {
  z-index: 0 !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffe200 !important;
  background-color: #ffc107 !important;
}

input#search_input.searchBox:valid {
  box-shadow: none !important;
}

div#multiselectContainerReact.multiselect-container.multiSelectContainer.browser-default.custom-select {
  background: none !important;
}

/* react select */
.css-b62m3t-container {
  width: 100%;
}

div.css-13cymwt-control {
  line-height: 43px;
}

.myCustomPrefix__value-container {
  height: 43px;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px;
  width: 100%;
}

div.myCustomPrefix__control {
  width: 100%;
  position: relative;
  box-shadow: none;
  border-radius: 0.25rem;
  height: 38px;
}

div.myCustomPrefix__input-container {
  padding: 0;
  margin: 0;
}

.myCustomPrefix__placeholder {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

div.myCustomPrefix__menu {
  line-height: 20px;
  position: static;
}

.myCustomPrefix--is-disabled {
  width: 100%;
}

.myCustomPrefix__control--is-focused {
  align-items: center;
  align-content: center;
  padding: 0;
  margin: 0;
}

div.myCustomPrefix__option--is-focuse,
div.myCustomPrefix__option--is-selected {
  background-color: rgba(255, 199, 44, 0.16);
}

.myCustomPrefix__option--is-selected {
  accent-color: #ffc72c;
}

div.myCustomPrefix--is-disabled .myCustomPrefix__menu-list {
  pointer-events: visible;
}

div.myCustomPrefix--is-disabled .myCustomPrefix__menu-list div {
  pointer-events: none;
}

/* validate button  */

.success_message {
  color: #29a744 !important;
}

/* ErrorPage 404 not found */
.error_page_wrapper {
  height: 100vh;
}

.page_not_found {
  height: 450px;
  width: 337px;
}

/* Maintainance Page */

.maintainance-menu {
  background: #4b4b4b;
}

.maintainance-page {
  width: 381px;
  height: 358.37px;
}

/* css part for select filter */
div.CustomPrefixer__control {
  width: 100%;
  height: 50px;
  position: relative;
  box-shadow: none;
}

div.CustomPrefixer__multi-value {
  background-color: #0096fb;
  border-radius: 11px;
  line-height: 19px;
  color: black;
}

div.CustomPrefixer__menu-list {
  height: 175px;
}

div.CustomPrefixer__menu {
  line-height: 20px;
  position: absolute;
  top: 55px;
}

.CustomPrefixer__placeholder {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

div.CustomPrefixer__input-container {
  padding: 0;
  margin: 0;
}

div.CustomPrefixer__input-container input.active:valid {
  box-shadow: none !important;
}

.CustomPrefixer__value-container {
  overflow-y: scroll !important;
  height: 47px;
}

.CustomPrefixer__indicators {
  position: absolute;
  right: 15px;
  top: 8px;
}

.CustomPrefixer--is-disabled {
  width: 100%;
}

.CustomPrefixer__control--menu-is-open::before {
  content: "";
}

.CustomPrefixer__value-container--has-value:focus {
  content: "";
}

/* removed the yellow error */

div.myCustomPrefix__input-container input.active:valid {
  box-shadow: none !important;
}

/* login page css part */
.x-icon {
  height: 1.2em;
  width: 1.2em;
  top: 0.125em;
  position: relative;
  margin-right: 2px;
}

.login-button {
  font-family: "Open Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 16px;
  padding: 6px 12px;
  width: 206px;
  height: 37px;
  border: #ffff;
  background-color: #eeeeee;
}

.CustomPrefixer__multi-value__remove,
.CustomPrefixer__indicator {
  display: none !important;
}

.CustomPrefixer__option--is-selected {
  accent-color: #ffc72c;
}

div.CustomPrefixer__option--is-focuse,
div.CustomPrefixer__option--is-selected {
  background-color: rgba(255, 199, 44, 0.16);
}

/*change by jasdeep*/
.program_margin {
  margin-top: 241px;
}

.toggle_button {
  position: absolute;
  top: 10px;
  cursor: pointer;
  right: 33px;
  box-sizing: border-box;
}

.top_class {
  top: 48px;
}

div.CustomPrefixer_devices__control {
  width: 100%;
  height: 50px;
  position: relative;
  box-shadow: none;
}

div.CustomPrefixer_devices__menu-list {
  width: 100%;
  text-align: center;
  overflow-x: hidden;
  max-height: inherit;
  overflow-y: scroll;
}

div.CustomPrefixer_devices__menu {
  line-height: 20px;
  position: absolute;
  top: 43px;
  border-radius: 0;
  height: 446px;
  overflow-x: hidden;
  text-align: center;
}

.CustomPrefixer_devices__placeholder {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

div.CustomPrefixer_devices__input-container {
  padding: 0;
  margin: 0;
}

div.CustomPrefixer_devices__input-container input.active:valid {
  box-shadow: none !important;
}

div.CustomPrefixer_devices__value-container {
  height: 47px;
}

.CustomPrefixer_devices__indicators {
  position: absolute;
  right: 15px;
  top: 8px;
}

.CustomPrefixer_devices--is-disabled {
  width: 100%;
}

.CustomPrefixer_devices__control--menu-is-open::before {
  content: "";
}

.CustomPrefixer_devices__value-container--has-value:focus {
  content: "";
}

div.myCustomPrefix_devices__control {
  width: 100%;
  position: relative;
  box-shadow: none;
  border-radius: 0.25rem;
  height: 43px;
}

div.CustomPrefixer_devices__single-value {
  display: none;
}

div.CustomPrefixer--is-disabled div.CustomPrefixer__option {
  background-color: #e9ecef;
}

.ReactVirtualized__Grid__innerScrollContainer {
  max-width: 100% !important;
}

.source_package_wrapper {
  margin: 0.5rem 0;
  width: 100%;
  align-items: center;
  line-height: 0 !important;
}

.deploy_date_css1 {
  width: 75%;
}

.deploy_date_css2 {
  width: 17%;
}

.deploy-date-gap-css {
  gap: 5px;
}

.dashboard_padding_css {
  padding-left: 0 !important;
  padding-right: 0 !important;
  /* display: flex;
   gap: 10px; */
}

@media only screen and (max-width: 500px) {
  .source_package_wrapper {
    /* height: 40px; */
    align-items: flex-end;
  }
}

@media only screen and (max-width: 600px) {
  .ticket-container {
    width: 90%;
  }
}

@media only screen and (min-width: 800px) {
  .deploy_date_css1 {
    width: 80%;
  }

  .deploy-date-gap-css {
    justify-content: space-between !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 617px) {
  .mobile_view {
    width: 130px;
    padding: 9px 40px;
    height: 37px;
    flex-direction: column;
  }
}

.sub-menu-1 {
  left: 80px;
  background-color: #4b4b4b;
  width: 168px;
  height: fit-content;
  z-index: 10;
}

.editbutton {
  border: 1px solid #ffc72c;
  background: #ffc72c;
  color: #4b4b4b;
  border-radius: 4px;
  width: 50px;
  height: 25px;
}

.success_text {
  color: #27ae60;
}

.cancel_btn {
  width: 130px;
  height: 37px;
}

/* @media only screen and (min-width: 320px) and (max-width:617px){
.mobile_view {
    width:130px ;
    padding: 9px 40px;
    height: 37px;
    flex-direction: column;

}
}  */

/* Sweetalert box */
.sa-button-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

.swal-button {
  margin: 5px 0;
}

.sweet-alert .confirm {
  background-color: #ffc72c !important;
}

.sweet-alert .cancel {
  background-color: #4b4b4b !important;
}

/* dashboard */

.HeaderMainDiv {
  display: flex;
  padding: 16px 40px 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  background: #0f2573;
  /* min-width: calc(100% - 50px); */
}

.main_heading-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* margin-left: 83px; */
  /* opacity: 50%; */
}

.main_heading {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  opacity: 0.5;
}

.UPDIV {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}

.headinghfour {
  display: flex;
  justify-content: space-between;
}

.SecondDIv {
  display: flex;
  justify-content: space-between;
}

.secondDIVH4 {
  opacity: 0.5;
  font-weight: 400;
  font-size: 15px;
  line-height: 18.15px;
  color: var(--White, #fff);
}

.allcloudstyle {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.ComboFirstDiv {
  display: flex;
  align-items: flex-start;
  padding: 24px 22px;
  flex-direction: column;
  /* justify-content: space-between; */
  align-self: stretch;
  border-radius: 8px 0px 0px 8px;
  background: var(--White, #fff);
  flex-shrink: 1;
}

.ComboSECDivSecondDiv {
  color: var(--Success-Text, #127e24);
  font-family: Inter;
  padding: 2px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: rgba(20, 197, 65, 0.12);
  border: none;
}

.tableHead {
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px;
  background-color: #f6f6f6 !important;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.upByTotal {
  color: var(--ceq-core-UI-Palette-Fire-Engine-Red, #d01a1a);
  background-color: #faebeb;
  outline: none;
  /* height: 10%; */
  padding: 2px;
  border-radius: 4px;
  font-family: Inter;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btn__container {
  display: flex;
  padding: 8px 4px 3px 5px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 2px;
  background: rgba(20, 197, 65, 0.12);
}

.twoTabsContainer {
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.tabsDateContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.updatedDate {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  opacity: 50%;
}

.btn-div {
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

.vertical_line {
  border-left: 1px solid rgba(222, 226, 230, 1);
  height: 70px;
}

.period_cost-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.SECONDComboDiv {
  display: flex;
  padding: 24px 20px;
  flex-direction: column;
  justify-content: center;
  /* align-items: flex-start; */
  align-items: baseline;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  background: var(--neutral-d-9, #f8f9fa);
  /* width: 599px; */
  /* height: 212px; */
}

.ICON__container {
  /* width:16px;
  height:16px; */
  gap: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.numb-dropdown__Container-number {
  color: var(--neutral-d-4, #6c757d);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.Inner_containers-header {
  display: flex;
  gap: 8px;
  justify-content: center;
  text-align: center;
  line-height: normal;
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.second-ICON__Container {
  /* width: 16px;
  height: 16px; */
  padding: 0.5px, 1.5px, 0.5px, 1.5px;
}

.second-number__Container {
  text-align: left;
  color: var(--Dark, #333840);
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.second-drop-down__Container-num {
  /* width: 37px;
  height: 16px; */
  color: var(--neutral-d-4, #6c757d);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.service_container {
  border-left: 1px solid rgba(222, 226, 230, 1);
  height: 131px;
  margin-left: 85px;
  /* margin-right: 20px; */
}

.third__Container {
  width: 385px;
  width: 385px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  padding: 0px;
  /* gap: 8px; */
  background: green;
}

.calmaindiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
}

.calheading {
  color: var(--neutral-d-9, #f8f9fa);
  text-align: right;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.5;
}

.calsecheading {
  display: flex;
  padding: 3px 8px 4px 7px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #101d4f;
}

.savings_headers {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  /* width: 144px;
  height: 17px; */
}

.saving-main-containers {
  /* width: 104px;
  height: 23px; */
  padding: 21px 24px 16px 24px;
  /* border-radius: 4px; */
  gap: 10px;
  display: flex;
  padding: 3px 8px 4px 7px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: var(--Green-Dark, #359446);
}

.text-this-month__CONT {
  /* width: 71px;
  height: 16px; */
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.arrow-this-month__CONT {
  /* width: 8px;
  height: 4px; */
  fill: var(--White, #fff);
}

.savings_cost {
  /* width: 123px;
  height: 32px; */
  gap: 8px;
  display: flex;
  align-items: flex-end;
  gap: 8px;
}

.value__CONT {
  /* width: 123px;
  height: 32px; */
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.idle-main-container {
  width: 100%;
  /* gap: 7px; */
  /* gap: 7px; */
  display: flex;
}

.idle-main-headers {
  /* width: 30%; */
  /* width: 30%; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  /* margin-right: 4px; */
}

.idle-resource-texts {
  opacity: 0.7px;
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.7;
}

.idle-costs {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

/* .LineBTWBTHDIV3{
  border-left: 1px solid rgba(68, 100, 132, 0.15)                                ;
  height: 70px;
  } */

.last-month-saving-header {
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
}

.savings-container {
  padding: 16px, 24px, 16px, 24px;
  /* border-radius: 0px, 0px, 8px, 0px !important; */
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  border-radius: 0px 0px 8px 0px;
  background: var(--Success-Text, #127e24);
  border-radius: 0px 0px 8px 0px !important;
}

.saving-main-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}

.savings-texts {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.savings-costs {
  color: var(--White, #fff);
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.btn__Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.btn-combo__Container {
  display: flex;
  width: 731.5px;
  align-items: center;
  gap: 2px;
}

.first-btn-btn-combo__Container {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px 4px 0px 0px;
  background: #fff;
}

.text-first-btn-btn-combo__Container {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.second-btn-btn-combo__Container {
  display: flex;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px 4px 0px 0px;
  background: rgba(255, 255, 255, 0.18);
}

.text-second-btn-btn-combo__Container {
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.8;
}

.tabs_content {
  padding: 16px 40px;
}

/* IDLE RESOURCES-------------------------------------- */

.main-container {
  display: flex;
  background: #0f2573;
  /* height: 235px; */
  padding: 16px 40px 16px 40px;
  gap: 16px;
  flex-direction: column;
}

.Inner_main_container {
  display: flex;

  /* background: blue; */
  /* z-index: 20; */
  flex-direction: column;
  padding: 0px;

  gap: 16px;

  gap: 16px;
}

.inner_first_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* background-color: white; */
  /* height: 25px; */
  /* gap: 16px;
  padding: 0px; */
}

.inner_first_container_text {
  opacity: 0.5;
  color: white;
  font-size: 21px;
  font-family: inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
  margin: 0px;
}

.inner_second_container {
  /* background-color: yellow; */
  /* height: 131px; */
  /* border-radius: 8px; */
  padding: 0px;
}

.second_div_first_container {
  background-color: #ffffff;
  /* height: 130px; */
  padding: 16px 24px 16px 24px;
  gap: 24px;
  border-radius: 8px 0px 0px 8px;
}

/* .second_div_first_container_firstDiv {
  display: flex;
  flex-direction: column;
  height: 74px;
  gap: 8px;
} */

.ideal_resource_count {
  color: #797b80;
  font-size: 15px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
  padding: 0px;
  margin: 0px;
}

.ideal_resource_count_number {
  /* background-color: red; */
  /* height: 48px; */
  gap: 8px;
  padding: 0px;
  margin: 0px;
  display: flex;
  align-items: flex-end;
  /* width: 113px; */
}

.number_container {
  color: #212224;
  font-size: 40px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;

  line-height: 48.41px;
  width: 52px;
}

.percent_container {
  height: 21px;
  display: flex;
  gap: 10px;
  height: 21px;
  /* background-color: yellow; */
  padding: 0px 0px 7px 0px;
  width: 52px;
}

.percent_container_first_div {
  gap: 4px;
  height: 14px;
  display: flex;
  padding: 0px;
}

.percentage_div {
  color: #797b80;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
}

.percentage_div_icon {
  width: 12px;
  margin: 0px;
  padding: 0px;
  display: flex;
  gap: 7.5px;
  align-items: flex-end;
}

.icone {
  height: 8px;
  width: 10.67px;
}

.second_div_first_container_secDiv {
  border-left: 1px solid rgba(222, 226, 230, 1);
  height: 98px;
}

.second_div_first_container_ThirdDiv {
  display: flex;
  flex-direction: column;
  /* background-color: blue; */
  height: 98px;
  width: 101px;
  gap: 8px;
  padding: 0px;
}

.Idle_duration {
  color: #797b80;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  /* line-height: 14.40; */
  word-wrap: break-word;
  padding: 0px;
}

.Idle_duration_container {
  height: 20px;
  display: flex;
  justify-content: space-between;
  padding: 0px;
}

.Idle_duration_number {
  color: #495057;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
}

.Idle_duration_content {
  display: flex;
  width: 50px;
  height: 16px;
  gap: 4px;
  /* padding: 0px */
}

.second_div_second_container {
  background-color: #f8f9fa;
  /* height: 131px; */
  padding: 16px 24px 0px 24px;
  gap: 8px;
}

.second_div_second_main_container {
  display: flex;
  flex-direction: column;
  height: 85px;
  gap: 16px;
  /* background-color: red; */
  padding: 0px;
}

.text_container {
  color: #797b80;
  font-size: 15px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  word-wrap: break-word;
  /* width: 118px; */
  padding: 0px;
}

.content_container {
  /* display: flex; */
  height: 51px;
  /* gap: 32px; */
  /* flex-direction: column; */
  padding: 0px;
  /* margin: 0px; */
}

.content_container_div {
  display: flex;
  /* gap: 32px; */
  /* height: 51px; */
  padding: 0px;
}

.content_container-first {
  display: flex;
  /* flex-direction: column; */
  gap: 16px;
  padding: 0px;
}

.content {
  display: flex;
  flex-direction: column;
  /* height: 51px; */
  gap: 7px;
  padding: 0px;
}

.upperContent {
  /* background-color: white; */
  display: flex;
  gap: 8px;
  height: 17px;
  padding: 0px;
}

.upperContent_text {
  color: #797b80;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
}

.lowerContent {
  /* background-color: white; */
  display: flex;
  height: 28px;
  gap: 4px;
  padding: 0px;
}

.Kuber_lowerContent {
  /* background-color: white; */
  display: flex;
  height: 28px;
  gap: 4px;
  padding: 0px;
  margin-left: 23px;
}

.lowerContent_number {
  color: #212224;
  font-size: 24px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
  width: 33px;
}

.lowerContent_text {
  height: 17px;
  padding: 0px 0px 1px 0px;
  gap: 4px;
  display: flex;
  margin-top: 10px;
}

.lowerContent_text-1 {
  color: #797b80;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
}

.lowerContent-icon {
  display: flex;
  height: 13px;
  gap: 7.5px;
  margin-top: 6px;
  /* width: 12px; */
  /* padding: 3px; */
}

.second_div_third_container {
  background: #3da64f;
  /* width: 313px; */
  /* height: 131px; */
  border-radius: 0px 8px 8px 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.innerContainer-1 {
  /* background-color: red; */
  /* height: 93px; */
  display: flex;
  padding: 16px 24px 12px 24px;
  gap: 20px;
  flex: 1;
}

.innerContainer-div {
  display: flex;
  gap: 8px;
  margin: 0px;
  padding: 0px;
}

.innerContainer-text {
  display: flex;
  gap: 8px;
  padding: 0px;
  flex-direction: column;
}

.innerContainer-text-1 {
  color: white;
  font-size: 15px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  text-align: uppercase;
  word-wrap: break-word;
  padding: 0px;
}

.innerContainer-text-2 {
  color: white;
  font-size: 32px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  padding: 0px;
}

.innerContainer-2 {
  background-color: #127e24;
  /* height: 38px; */
  padding: 8px 24px 8px 24px;
  border-radius: 0px 0px 4px 0px;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.innerSecondContainer {
  display: flex;
  padding: 8px 24px 8px 24px;
  border-radius: 0px 0px 4px 0px;
  justify-content: space-between;
  padding: 0px;
}

.innerSecondContainer-text {
  display: flex;
  color: white;
  font-size: 13px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  padding: 0px;
  align-items: center;
}

.innerSecondContainer-num {
  color: white;
  font-size: 18px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}

.inner_third_container {
  /* background-color: red; */
  display: flex;
  gap: 10px;
}

.inner_contaier-text {
  color: white;
  font-size: 12px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
  opacity: 0.5;
  justify-content: flex-end;
  display: flex;
  padding: 0px;
  /* margin-top: 8px; */
}

.IdleResource-head {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}

.IdleResource-heading {
  color: #212224;
  font-size: 16px;
  font-family: Inter;
  font-weight: 700;
  word-wrap: break-word;
}

/* ------------------------------------------------------------- */
.all_cloud_text {
  color: #495057;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.last_days_name {
  /* margin-left: 4px; */
  padding: 3px;
  color: var(--neutral-d-5, #adb5bd);
  text-align: right;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  align-items: center;
}

.icc_main_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  /* margin-left: 40px; */
}

.graph_main_div {
  display: flex;
  justify-content: flex-start;
  gap: 16px !important;
}

.cost_graph {
  display: flex;
  padding: 16px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-d-8, #e9ecef);
  background: #fff;
}

.aws_costs_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  gap: 5px;
}

.aws_cost_name {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.aws_cost_value {
  color: #495057;
  font-size: 13px !important;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
  font-size: 13px !important;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}

.buttons_css {
  border-radius: 4px;
  background: var(--Controls-BG, #f0f6ff);
  display: flex;
  width: 24px;
  height: 24px;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;
}

.aws_cost_text_div {
  padding: 0px;
  display: flex;
  gap: 12px;
}

.aws_cost_text {
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;
}

/*aws Idle Resource Table---------------------------------------------  */

/* K8s-------------------------------------------------------------------------------- */

.K8s_heading {
  opacity: 0.5;
  font-family: Inter;
  font-size: 21px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.Kuber-Sec-div {
  border-radius: 8px;
  background-color: #f8f9fa;
  height: 116px;
  margin-top: 8px;
}

.Kuber-third-div {
  display: flex;
  padding: 12px 32px 12px 32px;
  border-radius: 8px;
  justify-content: center;
  margin: auto;
  background: #ffffff1a;
}

.kuber-services {
  /* background-color: white; */
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin-right: 40px;
}

.kuber-services-heading {
  color: #dee2e6;
  font-size: 13px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}

.kuber-services-value {
  color: white;
  font-size: 28px;
  font-family: Inter, sans-serif;
  font-weight: 600;
  word-wrap: break-word;
}

.service_cotainer-1 {
  display: flex;
  padding: 24px 32px 24px 20px;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  height: 116px;
}

.service_container-2 {
  display: flex;
  padding: 24px;
  gap: 8px;
}

.middle-line {
  border: 1px solid rgb(218, 218, 218);
}

.lowerContent_numbers {
  color: #212224;
  font-size: 36px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  word-wrap: break-word;
}

.upperContent_texts {
  padding: 0px;
  color: #797b80;
  font-size: 14px;
  font-family: Inter, sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  word-wrap: break-word;
}

.lowerContent_texts {
  padding: 0px 0px 1px 0px;
  gap: 4px;
  display: flex;
  margin-top: 26px;
}

.cost_graphs {
  display: flex;
  padding: 16px 16px 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1px solid var(--neutral-d-8, #e9ecef);
  background: #fff;
  height: 172px;
  overflow: hidden;
}

.Inner_main_Container {
  display: flex;
  /* background: red; */
  flex-direction: column;
  padding: 0px;
  height: 242px;
  gap: 16px;
}

.service-container-details {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.rdrMonthName {
  display: none;
}

.ComboSECONDDivs {
  display: flex;
  justify-content: flex-end;
}

.ComboSECONDInnerDIvFirstPartINNERDIVSECTEXTs {
  color: #495057;
  font-size: 13px;
  font-family: Inter, sans-serif;
  font-weight: 500;
  word-wrap: break-word;
}

.Idle-Resource-Table {
}

/* React calender CSS */
/* .calenderWarap {
  position: relative;
} */

.calenderElement {
  position: absolute;
  top: 30px;
  right: 0;
  /* left: 20; */
  z-index: 100;
  box-shadow: #0073aa;
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid #ccc;
}

.calenderElement::backdrop {
  background-color: rgba(255, 0, 0, 0.5);
}

.recharts-default-legend .recharts-legend-item span {
  color: #000 !important;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.rdrDateDisplayWrapper {
  display: none !important;
}

.kuber_ComboFirstDivInnerDiv {
  display: flex;
  align-items: center;
  gap: 8px;

  /* width: 280px; */
}

.kuber_Inner_main_Container {
  display: flex;
  /* background: red; */
  flex-direction: column;
  padding: 0px;
  /* height: 172px; */

  gap: 8px;
}

.kuber_main-container {
  display: flex;
  background: #0f2573;
  /* height: 235px; */
  padding: 16px 40px 16px 40px;
  gap: 16px;
  flex-direction: column;
}

.rdrMonthAndYearPickers .rdrMonthPicker select option,
.rdrMonthAndYearPickers .rdrYearPicker select option {
  background-color: rgba(248, 248, 255, 0.762) !important;
  padding: 4px !important;
  /* border: 1px solid black; */
}

.hover {
  cursor: pointer;
}

.add_widget_overlay_screen {
  position: fixed;
  top: 0;
  right: 0px;
  width: 100%;

  background-color: rgba(0, 0, 0, 0.5);
  /* overflow-y: scroll; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 9999;
  height: 100vh;
}

/*allcloud dropdown -------------------------------------------------------------- */
.customs-select {
  position: relative;
  /* display: inline-block; */
}

.customs-select select {
  padding: 6px 10px 7px 4px;
  border-radius: 4px;
  color: white;
  /* background-color: #101D4F; */
  width: 107px;
  border: 0px;
}

.customs-select select::-ms-expand {
  display: none;
}

.customs-select:after {
  content: "\1F893";
  position: absolute;
  top: 68%;
  right: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  color: white;
}

.customs-select select option {
  background-color: white;
  color: black;
  padding: 40px;
}

.customs-select select:hover option:hover {
  background-color: red;
  color: white;
}

.single_item {
  margin-bottom: 8px;
}
.side_Link:hover {
  cursor: pointer;
  background-color: #0a13334d;
}
/* --------------------------------------------- */
#customers .tableData,
#customers .table-class_css {
  min-width: 105px;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  border: none;
  text-align: right;
  border-bottom: 1px solid #f6f6f6;
  font-weight: 400;
}
#customers .table-class_css {
  background-color: var(--Header_bg, #f6f6f6);
  font-weight: 600;
  color: #000000;
}

/* .tooltip .arrow::before {
  left: 50% !important;
  transform: translateX(-50%) !important;
} */

/* ---------------- NEW STYLING --------------- */
.dropdown-disable-caret::after {
  display: none !important;
}

.content-wrapper {
  padding-left: 40px;
  padding-right: 40px;
}

.content-wrapper-left {
  padding-left: 40px;
  padding-right: 18px;
}

.form-select {
  padding-left: 4px;
  --bs-form-select-bg-img: none;
}
.form-select .formOption {
  padding-top: 10px;
  margin: 20px;
  color: #007b5e !important;
}
.formOption {
  margin: 20px;
  background-color: yellow !important;
}

/* .dropdown-toggle::after {
  content: none;
} */

.filter_search_trigger {
  cursor: pointer;
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 4px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  background: var(--White, #fff);
  justify-content: space-between;
  position: relative;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05) !important;
  height: 36px;
  /* overflow: hidden; */
}

.filter_search_trigger::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  transform: translateY(-50%);
  background-image: url("../img/dropdown-icon.svg");
  background-repeat: no-repeat;
  width: 6px;
  height: 10px;
  background-size: contain;
}

.filter_search_text {
  color: #adb5bd;

  font-family: Inter, sans-serif;
  font-size: 13px;
  line-height: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.filter_search_text_selected {
  color: initial;
}
#dropdown-basic {
  width: 100%;
  padding: none;
  display: flex;
  flex-direction: flex-start;
  color: #adb5bd;
}
#dropdown-basic,
.--bs-btn-bg {
  background-color: white;
}
#dropdown-basic,
.--bs-btn-hover-border-color {
  border-color: white;
}
#dropdown-basic,
.alligment {
  /* transform: translate3d(0px, 35.6px, 0px); */
  inset: 0px auto auto -5px;
  width: 100%;
  padding: 0;
  color: var(--Neutral-D2, #343a40);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

#dropdown {
  width: 100%;
  padding: 0;
}
#dropdown,
#dropdownContainer {
  padding: none;
}
#budgetScreen {
  width: 200px;
}
.react-datepicker-wrapper {
  /* width: 327px; */

  padding: 7px;
  border-radius: 4px;
  width: 100%;
}
/* .react-datepicker-ignore-onclickoutside{
  width: 327px;

  padding:7px;
  border-radius: 4px;
  width:100%;
} */
.dateField {
  width: 327px;
  /* border: 1px solid red; */
  /* padding:7px; */
  border-radius: 4px;
  /* width:100%; */
}

.dataFieldInput {
  width: 100%;
  height: 36px;
  padding: 6px;
  border: 1px solid var(--Neutral-D6, #ced4da);
  border-radius: 4px;
  padding-bottom: 9px;
}
#graphId .btn-light {
  border-radius: 4px;
  --bs-btn-bg: var(--Controls-BG, #f0f6ff);
}
#graphId .btn {
  padding-top: 4px;
  padding-bottom: 4px;
  width: 24px;
  height: 24px;
  padding-right: 6px;
  padding-left: 6px;
}
#budgetId .btn-light {
  border-radius: 4px;
  --bs-btn-bg: none;
  --bs-btn-hover-bg: var(--Controls-BG, #f0f6ff);
  --bs-btn-active-bg: var(--Controls-BG, #f0f6ff);
  /* var(--Controls-BG, #f0f6ff) */
}
#budgetId .btn {
  padding-top: 4px;
  padding-bottom: 4px;
  width: 27px;
  height: 24px;
  padding-right: 6px;
  padding-left: 6px;
}
#budgetId .dropdown-menu {
  --bs-dropdown-link-hover-bg: var(--Controls-BG, #f0f6ff);
}
#graphId .dropdown-menu {
  --bs-dropdown-link-hover-bg: var(--Controls-BG, #f0f6ff);
}

.dropdown-menu {
  font-size: 13px;
}
#graphId .dropdown-item {
  border-radius: 4px;
  padding: 4px 8px;
}

#budgetId .dropdown-item {
  border-radius: 4px;
  padding: 4px 8px;
}
#budgetId .dropdown-menu.show {
  padding: 8px;
  display: flex !important;
  flex-direction: column !important;
  overflow-x: auto;
  box-shadow: 0px 12px 27px 0px rgba(0, 0, 0, 0.1),
    0px 49px 49px 0px rgba(0, 0, 0, 0.09),
    0px 111px 67px 0px rgba(0, 0, 0, 0.05),
    0px 197px 79px 0px rgba(0, 0, 0, 0.01), 0px 308px 86px 0px rgba(0, 0, 0, 0);
  transform: translate3d(-131px, 27.2px, 0px) !important;
}
#graphId .dropdown-menu.show {
  padding: 8px;
  display: flex !important;
  flex-direction: column !important;
  overflow-x: auto;
  box-shadow: 0px 12px 27px 0px rgba(0, 0, 0, 0.1),
    0px 49px 49px 0px rgba(0, 0, 0, 0.09),
    0px 111px 67px 0px rgba(0, 0, 0, 0.05),
    0px 197px 79px 0px rgba(0, 0, 0, 0.01), 0px 308px 86px 0px rgba(0, 0, 0, 0);
  transform: translate3d(-131px, 27.2px, 0px) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f0f6ff;
}

.dropdown-item:hover {
  background-color: #f0f6ff;
}

.dropdown-divider {
  border-top-color: #e9ecef;
}

.rdrMonth {
  /* padding-bottom: 60px !important; */
  height: 300px;
}

/*admin layout*/

.adminContainer {
  padding: 0px;
  width: 100%;
  margin-top: 54px;
  display: flex;
  flex-direction: row;
  /* overflow: hidden; */
}

.sideBar {
  position: fixed;
  top: 60px;
  bottom: 0;
  width: 17%;
  background-color: #f8f9fa;
  padding: 24px 10px 24px 24px;
  box-shadow: 2px 0 5px -4px #091e428d;
  display: flex;
  flex-direction: column;
}

.mainContainer {
  width: 100%;
  margin-left: 17%;
  min-height: calc(100vh - 58px);
}

.sideBarButtons {
  height: 40px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
  padding: 8px 10px;
}

.sideBarButtons.active {
  background-color: #e9ecef;
  color: #056ee5 !important;
}
.sideBarButtons:hover {
  cursor: pointer;
}

.roles {
  /* height: 24px;
  width: 24px; */
}

.active .roles path {
  fill: rgba(5, 110, 229, 1);
}

/* admin-tabs-css---------------------------------------------------------------- */
/* .nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700 ;
  color: #056EE5 ; 
  border-bottom-color: #056EE5 ; 
  position: relative ;
} */

/* .nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
  color: #797b80;

  font-weight: 500;
  font-size: 13px;
} */

.offcanvas-header .btn-close {
  padding: 0px;
  margin: 0px;
}
/* 
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background: #fff !important;
  opacity: 1;
  border: none;
  border-bottom: 2px solid #056ee5;
}
.nav-tabs .nav-link {
  color: black;
  border: none;
  font-size: 14px;
  font-weight: 500;
}

#fill-tab-example .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #495057;
  background: #fff;
  opacity: 1;
  border: none;
} */

#admin,
.offcanvas_body {
  padding-top: 12px;
  padding-right: 24px;
  padding-left: 24px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
}

.table > thead {
  vertical-align: middle;
}

.rc-slider {
  width: 90% !important;
}

.searchBar {
  display: flex;
  width: 450px;
  min-width: 250px;
  padding: 7px 10px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  background: var(--Dark-Blue, #0f2573);
}
#searchBarInput {
  margin-left: 10px;
  background: var(--Dark-Blue, #0f2573);
  border: none;
  text-align: start;
}

.searchedItem {
  color: var(--White, #fff);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px; /* 84.615% */
}
