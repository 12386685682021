.success {
  display: flex;
  width: 645px;

  flex-grow: 1;
  /* width: 645px; */
  padding: 12px 0px 12px 16px;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  border-radius: 6px;
  border-left: 6px solid #2f803d;
  background: var(--Success, #3da64f);
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.1),
    0px 30px 30px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05),
    0px 118px 47px 0px rgba(0, 0, 0, 0.01), 0px 185px 52px 0px rgba(0, 0, 0, 0);
}
.dropdown_Toggel::after {
  /* display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: none !important;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent; */
}

.toast {
  display: flex;
  width: 645px;

  flex-grow: 1;
  /* width: 645px; */
  padding: 12px 0px 12px 16px;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.toastContainer {
  width: 645px;
  padding: 12px 36px 12px 16px;
}
.warning {
  display: flex;

  flex-grow: 1;
  /* width: 645px; */
  padding: 12px 0px 12px 16px;
  align-items: center;
  justify-content: flex-start;
  width: 645px;

  gap: 10px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  border-radius: 6px;
  border-left: 6px solid #cc9929;
  background-color: #ffbf33;
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.1),
    0px 30px 30px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05),
    0px 118px 47px 0px rgba(0, 0, 0, 0.01), 0px 185px 52px 0px rgba(0, 0, 0, 0);
}
.info {
  display: flex;

  flex-grow: 1;
  /* width: 645px; */
  padding: 12px 0px 12px 16px;
  align-items: center;
  justify-content: flex-start;
  width: 645px;

  gap: 10px;
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  border-radius: 6px;
  border-left: 6px solid #cc9929;
  background-color: #ffbf33;
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.1),
    0px 30px 30px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05),
    0px 118px 47px 0px rgba(0, 0, 0, 0.01), 0px 185px 52px 0px rgba(0, 0, 0, 0);
}

.error {
  display: flex;

  flex-grow: 1;
  /* width: 645px; */
  padding: 12px 0px 12px 16px;
  align-items: center;
  width: 645px;

  justify-content: flex-start;
  gap: 10px;
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 107.692% */
  border-radius: 6px;
  border-left: 6px solid #96222b;
  background: #e33341;
  box-shadow: 0px 7px 16px 0px rgba(0, 0, 0, 0.1),
    0px 30px 30px 0px rgba(0, 0, 0, 0.09), 0px 67px 40px 0px rgba(0, 0, 0, 0.05),
    0px 118px 47px 0px rgba(0, 0, 0, 0.01), 0px 185px 52px 0px rgba(0, 0, 0, 0);
}
.toastHeader {
  display: flex;
  flex-grow: 1;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: white;
  /* padding-right: 36px; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
  background-color: transparent;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  gap: 4px;
}
.toastNotification {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
}

.messageType {
  display: flex;
  align-items: center;

  justify-content: flex-start;
  color: var(--White, #fff);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 107.692% */
  text-transform: uppercase;
}
.info_message {
  display: flex;
  align-items: center;

  justify-content: flex-start;
  color: #212224;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px; /* 107.692% */
  text-transform: uppercase;
}
.get_messaged {
  color: #212224;
  font-family: Inter;
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0%;
}

.customeOverlay {
  position: fixed;
  top: 0%;
  margin: 0;
  height: 100vh;
  right: 0px;
  /* bottom:50%; */
  /* left:50%;
  right: 50%; */
  width: 100%;

  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}
.conformationBox {
  /* width: 410px; */
  /* height: 170px; */
  background-color: white;
  border-radius: 8px;
}
.dialogContainer {
  padding: 24px 64px;
  display: flex;
  flex-direction: column;

  gap: 24px;
}
.content {
  display: flex;
  align-items: center;
}
.dialogTitle {
  text-align: center;
  /* flex-direction: column;
  justify-content: center; */
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dialogBody {
  margin-top: 8px;
  color: var(--Neutral-D4, #797b80);
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: space-between;
}
.deleteButton {
  display: flex;
  padding: 9px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  color: #d01a1a;
  flex: 1 0 0;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--Neutral-D5, #adb5bd);
  background: var(--White, #fff);
}

.cancleButton {
  display: flex;
  padding: 9px 20px 10px 20px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  color: #ffffff;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #056ee5;
  background: var(--Charts-Blue, #056ee5);
}

.placeholderText {
  color: var(--Neutral-D5, #adb5bd);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}

.custom_dropdown_toggle {
  padding: 4px 6px !important;
  border: none;
}
.Dropdown_Toggel::after {
  content: none;
}
.dropdown_item {
  color: #000 !important;
  padding: 4px 6px !important;
  font-size: 12px;
  font-weight: 550;
}
.budget_dropdown_item {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.errorMessage {
  color: red;
  font-size: 12px;
  /* margin-top: 5px; */
}
.dropdown_menu {
  left: 0 !important;
  right: 0 !important;
  border-radius: 4px;
  box-shadow: 0px 12px 27px 0px #0000001a;
  overflow-y: hidden;
  transform: translate3d(-131px, 27.2px, 0px);
}

.budgetsFilterSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.filterDimension {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filterHeading {
  color: var(--Neutral-D1, #212529);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 0 !important;
}

.filterTagLine {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0 !important;
}

.semicolon {
  margin-top: 25px;
  color: #606367;
  display: flex;
  padding-bottom: 10px;
  font-weight: 750;
  align-items: flex-end;
}
.badgeContainers {
  display: flex;
  flex-direction: row;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: #f1f6ff;
  width: fit-content;
}
.badgeHeading {
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
}
.badgeSelectedValue {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--Dark, #212224);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
}
.badgeLengthValues {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* overflow: hidden; */
  color: var(--Neutral-D3, #495057);
  /* text-overflow: ellipsis; */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.4px;
}
.badgesValues {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.placeholderFilter {
  padding-left: 8px;
  padding-top: 2px;
  color: var(--Neutral-D5, #adb5bd);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
}
.cardListContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}
.buttonView {
  padding: 8px;
  border: 1px solid #ced4da;
  cursor: pointer;
}

.filterContainer {
  /* margin-bottom: -33px;height */
  /* height: 100vh; */
  margin-bottom: -33px;
  display: flex;
  /* width: 240px; */
  /* min-width: 240px; */
  padding-top: 24px;
  padding-right: 40px;
  padding-left: 16px;
  width: 20%;
  flex-direction: column;
  justify-content: flex-start;
  /* align-items: stretch; */
  gap: 16px;
  border-left: 1px solid var(--Neutral-D7, #dee2e6);
  background: #fff;
  box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.04);
}

.filterHeader {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

.filterContainerBudgets {
  margin-top: -16px;
  /* border-left: 1px solid var(#dee2e6); */
  background: #fff;
  /* box-shadow: -1px 0px 0px 0px rgba(0, 0, 0, 0.04); */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  box-sizing: border-box;
  width: 100%;
}
.dimension_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  box-sizing: border-box;
  width: 100%;
}

.dimension_name_css {
  color: var(--neutral-d-4, #797b80);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Inter, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 153.846% */
  flex: 1 0 0;
  box-sizing: border-box;
}

.filter_name_addsign {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-self: stretch;
}
.filter_name {
  padding: 6px;
  color: var(--Dark, #212224);
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}

/* #check, .options {
  color: yellow !important;
  padding:12px !important;
} */

.deltailePageContainer {
  padding: 24px;
  background: var(--Neutral-D9, #f8f9fa);
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.detailePageHeader {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
.budgetNavigation {
  color: var(--Charts-Blue, #056ee5);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
}

.budgetNavigation:hover {
  border-bottom: 2px solid #056ee5;
}

.deltailePageTitle {
  color: var(--Neutral-D3, #495057);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}
.detailePageBody {
  display: flex;
  gap: 20px;
  justify-content: flex-start;
}
.detailePageSidebarContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Corner-Radius-8, 8px);
  border-radius: var(--Corner-Radius-8, 8px);
  background: var(--White, #fff);
  /* padding-left: 24px; */
  /* padding-right: 16px; */
  width: 22.3%;
  /* justify-content: flex-start; */
}

.detailPageBoxes {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  padding-top: 16px;
  padding-left: 24px;
  padding-right: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #e9ecef;
}
.detailPageBoxes:hover {
  border-radius: 8px;
  background: var(--Controls-BG, #e6eefa);
}
.boxTitle {
  color: #495057;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.boxBodyTitleCS {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.boxBodyTitle {
  color: var(--Dark-Blue, #0f2573);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 20px */
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 283px;
}
.boxBodyTitleF {
  color: var(--Red, #d01a1a);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 18px */
}
.boxCostUsedT {
  color: var(--Neutral-D1, #212529);
  text-align: center;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.boxCostUsedC {
  color: var(--Neutral-D1, #212529);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.boxBudgetRemaining {
  color: var(--Neutral-D4, #797b80);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.detailePageMainContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-left: 4px;
  padding-right: 16px;
  width: 76.19%;
}
.overviewBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  flex-direction: row;
}
.selectedTabactive {
  color: #1657a8;
  border-bottom: 3px solid #1657a8;
  padding-bottom: 12px;
}
.selectedTabinactive {
  color: #89888f;
}
.legendText {
  font-weight: 400;
  color: #000000;
  font-size: 12px;
}

.chartsContainers {
  display: flex !important;
  gap: 20px;
  width: 100%;
  /* margin-top: 44px; */
}

.chartContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 48%;
  /* background-color: #f9f9f9; */
  border-radius: 8px;
  border: 1px solid #e9ecef;
}
.cardListView {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #e9ecef;
}

.tabsStyle {
  margin-right: 24px;
  cursor: pointer;
}
.overviewTitle {
  color: var(--Neutral-D1, #212529);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.utilities {
  display: flex;
  gap: 8px;
  align-items: center;
}
.utilityButtons {
  display: flex;
  padding-top: 6px;
  padding-bottom: 7px;
  padding-left: 11px;
  padding-right: 10px;
  gap: 10px;
  border-radius: 4px;
  align-items: center;
  border: 1px solid var(--Neutral-D6, #ced4da);
  background: var(--White, #fff);
  cursor: pointer;
}

.navigateT {
  color: var(--Charts-Blue, #056ee5);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.download {
  color: var(--Dark, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.graphSection {
  background-color: white;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
  box-sizing: border-box;
  border-radius: var(--Corner-Radius-8, 8px);
}
.graphHeading {
  color: var(--Neutral-D1, #212529);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.budgetDetailSection {
  display: flex;
  /* height: 1068px; */
  padding: 16px 20px 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
  border-radius: var(--Corner-Radius-8, 8px);
  border: 1px solid var(--Neutral-D9, #f8f9fa);
  background: #fff;
}
.budgetDetailHeader {
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.budgetDetailBox {
  margin-top: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: var(--Corner-Radius-8, 8px);
  background: var(--Neutral-D9, #f8f9fa);
}
.detailBoxContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: var(--Corner-Radius-8, 8px);
  background: var(--Neutral-D9, #f8f9fa);
  margin-top: 12px;
}
.detailBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  gap: 12px;
  flex: 1 0 0;
  border-radius: var(--Corner-Radius-8, 8px);
  background: var(--Neutral-D9, #f8f9fa);
}
.detailTitles {
  min-width: 75px;
  color: var(--Neutral-D4, #797b80);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.detailBoxRow {
  display: flex;
  align-items: center;
}

.detailValues {
  min-width: 75px;
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 10px;
}

.tableContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  margin-top: 12px;
  /* width: 100%; */
}

.theadStylings {
  padding: var(--Corner-Radius-8, 8px);
  background: #f6f6f6;
}
.tdStyling {
  padding: 6px 8px;
  border-right: 1px solid var(--Header_bg, #edeff2);
  border-bottom: 1px solid var(--Header_bg, #edeff2);
  border-left: 1px solid var(--Header_bg, #edeff2);
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.progress_bar {
  border-radius: 0px;
  height: 8px;
}

.noData {
  color: var(--Neutral-D2, #343a40);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.footStyle {
  background-color: #f8f9fa !important;
}
#customers .th_class {
  color: #343a40;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Header_bg, #f6f6f6);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#customers .table_data__Class,
#customers .th_class,
.total_Costs {
  width: 100px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid #ddd;
  padding: 8px;
  color: var(--Neutral-D3, #212224);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
  border: none;
  border-bottom: 1px solid #f6f6f6;
}
.th_class {
  position: relative;
}

#customers .th_class {
  color: #343a40;
  padding: 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Header_bg, #f6f6f6);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.sorting_icon {
  margin-left: 4px;
  position: relative;
  transform: translateY(-7%);
}
.sortingUp {
  margin-left: 4px;
  position: relative;
  transform: translateY(-7%) rotate(180deg);
}
.newlyTabs {
  padding: 6px 7px 6px 7px;
  font-size: 11px;
  font-weight: 500px;
  cursor: pointer;
  border: 1px solid #e9ecef;
}
.newlyTabActive {
  background-color: #056ee5;
  color: #ffffff;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.headingModal {
  font-size: 18px;
  font-weight: 700;
}
.pahrahModal {
  font-size: 13px;
  font-weight: 400;
  color: #212224;
}
.costheadingModal {
  gap: 32px;
  display: flex;
  padding: 0px 8px 0px 8px;
}
.totalCostModal {
  font-size: 14px;
  font-weight: 600;
  color: #797b80;
}
.total_CostModal {
  font-size: 22px;
  font-weight: 700;
  color: #212529;
}
.costContainerModal {
  display: flex;
  flex-direction: column;
}

.IndaysStyle {
  font-size: 14px;
  font-weight: 400;
  color: #797b80;
  margin-left: 8px;
}
.topCostimgcircle {
  width: 12px;
  display: block;
  background: #df5f5f;
  height: 12px;
  border-radius: 50%;
}

.tableContainer {
  max-height: 361px;
  overflow-y: auto;
}

.stickyHeader th {
  position: sticky;
  top: 0;
  background-color: #f9f9f9;
  z-index: 2;
}

.stickyFooter td {
  position: sticky;
  bottom: 0;
  background-color: #f9f9f9;
  z-index: 2;
}

.filter_search_field {
  position: relative;
  margin-bottom: 10px;
}
.fill_add_symbol {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  padding-top: 16px;
  cursor: pointer;
}
.add_filter_name {
  color: #056ee5;
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.toggle_value {
  padding: 4px 8px 4px 8px;
  gap: 10px;
  border-radius: 2px;
  background-color: #edeff2;
  color: #212529;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.input_box_css {
  color: #343a40;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none;
  background: url("/src/assets/img/selectOption.svg") no-repeat 95% center;
  cursor: pointer;
}
.input_box_css:disabled {
  color: grey;
  background-color: #fefefe;
  border-color: #ddd;
}

.remove_button {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #d01a1a;
}
.filternamed {
  color: #343a40;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.filter_dimension {
  display: flex;
  flex-direction: column;
  gap: 2px;
}
.dimensio_name {
  color: #797b80;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.filter_box {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
}
