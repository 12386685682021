.mainRolesContainer {
  width: 100%;
  display: flex;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  gap: 32px;
  padding: 28px 40px;
}

.textAndButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.labelHolder {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
#mybutton_groups:hover {
  background-color: initial;
  color: initial;
  border-color: initial;
}
#mybutton_groups:hover:not(.active) {
  background-color: #0d6efd;
  color: white;
}

#mybutton_groups:hover:not(.active) .myimgcolor {
  filter: brightness(0) invert(1); /* Invert the colors of the image */
}
.myimgcolorb {
  filter: brightness(0) invert(1);
}

.goBackBtn {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: left;
  color: #797b80;
  margin-bottom: 12px;
}

.manageRoles {
  font-family: Inter;
  font-size: 21px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #495057;
}

.addUserButton {
  height: 36px;
  padding: 8px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  display: flex;
  border: 1px solid #056ee5;
  background-color: #f0f6ff;
  color: #056ee5;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.hover .addUserImg path {
  fill: #fff;
}

.dropDown ::after {
  display: none;
}

.tabs {
  position: relative;
  display: none;
}

/* .tabs ul li a {
  color: #797b80;
}

.tabs::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #e9ecef;
} */

.tabsUserProfile {
  position: relative;
  /* color: red; */
}

.tabsUserProfile ul li a {
  color: #797b80;
}

.tabsUserProfile button {
  color: #89888f;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.73px;
  text-align: left;
}
.tabsUserProfile button:focus:not(:focus-visible) {
  color: #1657a8 !important;
  /* z-index: 100000000 !important; */
}

.tabsUserProfile button:hover{
  color: #797b80;

}


.tabsUserProfile::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  height: 2px;
  background-color: #e9ecef;
  z-index: -1;
}
.table {
  margin-bottom: 0px;
  width: 100%;
  /* border-radius: 1px;
  border-bottom: #e9ecef; */
}
/* 
.table th,
td {
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
} */

.tableContainer {
  border: 1px solid #e9ecef;
  border-radius: 4px;
  height: 100%;
  padding-bottom: 0px;
}

.tableStyling {
  border-radius: 4px;
  margin-bottom: 0px;
}

.tableRowStyling td {
  padding: 17px 8px 17px 8px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.user_css {
  background-color: rgba(248, 249, 250, 1);
  padding: 4px 8px 4px 8px;
  gap: 8px;
  border-radius: 2px 0px 0px 0px;
  /* opacity: 0px; */
  width: 73%;
}

.privilages_css {
  background-color: rgba(241, 239, 254, 1);
  padding: 4px 8px 4px 8px;
  gap: 8px;
  border-radius: 2px;
  /* opacity: 0px; */
  /* width: 60%; */
}

.tableHeadStyling th {
  background-color: #edeff2;
  border-bottom: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  max-width: 30%;
}

.badgeHolder {
  display: flex;
  gap: 12px;
}

.readAccess {
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fdf6db;
  gap: 8px;
  border-radius: 2px;
}

/* .writeAccess{
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F0F6FF;
  gap: 8px;
  border-radius: 2px;

} */

/*Add User Modal*/
.modal {
  width: auto;
  top: 50;
  left: 50;
  bottom: 50;
  right: 50;
}

.modalTitle {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.mainTitle {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}
.sunmaintitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.subTitle {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #797b80;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.modalBody {
  display: flex;
  flex-direction: column;
}
.modalHeader .modal-header .btn-close {
  display: none;
  border-bottom: none !important;
}
.modalHeader {
  padding: 24px 24px 0px 24px;
}

.checkbox {
  width: 16px;
  height: 16px;
  outline: 2px solid #c00;
}

.addAdminText {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #343a40;
}

.textArea {
  resize: none;
  height: 72px;
  border: 1px solid rgba(206, 212, 218, 1);
  border-radius: 4px;
  background: none;
  box-shadow: 0 1px 0 0 #0000000d;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
}
.footer_add {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 8px;
  margin-top: 24px;
}

.cancelButton {
  padding: 9px 20px 10px 20px;
  border: 1px solid #adb5bd;
  color: #797b80;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  width: 20%;
}
.cancelButtonp {
  padding: 9px 20px 10px 20px;
  border: 1px solid #adb5bd;
  color: #797b80;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
}

.addButton {
  width: 167px;
  padding: 9px 20px 10px 20px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
}

.placeholder_text {
  padding: 4px 6px;
}

.chip {
  align-items: center;
  background: #f0f6ff;
  border-radius: 1px;
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: inline-block;
  padding: 4px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

.filter {
  /* background-color: red; */
  /* padding-top: 20px; */
  width: 80% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
  margin: 0px;
  /* align-items: center; */
  gap: 4px;
  gap: 4px;
}

.infoStyle {
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #797b80;
}

.success_image {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.delete_main_heading {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: center;
  color: rgba(33, 34, 36, 1);
}
.delete_message {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: center;
  color: rgba(121, 123, 128, 1);
}

.flex_direction {
  display: flex;
  justify-content: center;
}
.success_message {
  font-size: 18px;
  font-weight: 600;
  color: #212224;
  text-align: center;
}
.deleted_button {
  padding: 9px 32px 10px 32px;

  border-radius: 4px;
  border: 1px solid rgba(173, 181, 189, 1);
  background-color: transparent;
  color: rgba(208, 26, 26, 1);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: right;
}
.closed_button {
  padding: 9px 32px 10px 32px;

  border-radius: 4px;
  border: none;
  color: #fff;
  background-color: rgba(5, 110, 229, 1);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: right;
}

.success_body {
  padding: 24px;
  display: flex;
  gap: 32px;
  border-radius: 8px;
  justify-content: center;
  flex-direction: column;
}
.close_buttom {
  background-color: #ffffff;
  color: #797b80;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  width: 100px;
}
.close_button {
  display: flex;
  justify-content: center;
  gap: 4px;
  padding: 9px 20px 10px 20px;
}
.my_note {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #ce7c00;
}

.sideComponent {
  width: 40% !important;
  display: flex;
  flex-direction: column;
  /* gap: 24px; */
}

.heading {
  padding: 12px 0px 12px 24px;
  border-bottom: 2px solid #e9ecef;
  font-weight: 700;
  font-size: 18px;
}

.headingsec {
  border-bottom: none !important;
  padding-left: 0px !important;
}

.gap {
  display: flex;
  gap: 12px;
  width: 100%;
  border-bottom: 1px solid #e9ecef;
  /* padding: 0px; */
}

.body_gap {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.smallGap {
  display: flex;
  flex-direction: column;

  gap: 8px;
}

.group_Heading {
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #212529;
}
.group_statement {
  font-size: 12px;
  font-weight: 500;
  color: #797b80;
  text-align: left;
}
.offcanva_body {
  padding: 0px 24px 0px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
}

.search_name_css::placeholder {
  color: #adb5bd;
}

/* -----------------------------search bar css-------------------------------------- */
.searchbar_main_div {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  padding: 8px;
  border-bottom: 1px solid #ced4da;
  box-shadow: 0px 1px 0px 0px #0000000d;
  width: 25%;
}
.search_name_css {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  width: 100%;
  border: none;
}
.search_name_css::-webkit-input-placeholder {
  color: #adb5bd;
}

.adding_searchbar {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.headings {
  border-bottom: 2px solid #e9ecef;
}
.group_modal {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.last_modified_name {
  color: rgba(52, 58, 64, 1);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  opacity: 0.5;
}
.group_name {
  color: rgba(33, 34, 36, 1);
  font-family: Inter;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.78px;
  text-align: left;
}
.table_div {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.discription_name {
  color: rgba(121, 123, 128, 1);
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.discription_details {
  color: rgba(52, 58, 64, 1);
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.2px;
  text-align: left;
  margin: 0px;
}
.hr_css {
  margin: 0px !important;
  border: 1px solid rgba(233, 236, 239, 1);
}
.creater_div_groups {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  padding-top: 12px;
}
.creater_div {
  display: flex;
  justify-content: flex-start;
  gap: 12px;
  padding-top: 12px;
  padding-left: 24px;
}

.creater_box {
  display: flex;
  gap: 2px;
  padding: 12px;
  border: none;
  border-radius: 4px;
  background: rgba(248, 249, 250, 1);
}
.creater_name {
  color: rgba(121, 123, 128, 1);
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
  padding: 0px 10px;
}
.creater_name_value {
  display: flex;
  gap: 4px;
  color: rgba(33, 34, 36, 1);
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;
}
.createdOn_styling {
  font-size: 13px;
  font-weight: 600;
  line-height: 15.6px;
  text-align: left;
}
.privilage_box {
  border: 1px solid rgba(248, 249, 250, 1);
  border-radius: 4px;
}
.tabContent {
  height: 367px;
  overflow-y: auto;
}
.tabContent::-webkit-scrollbar {
  width: 0;
}

.tabContent {
  scrollbar-width: none;
}

.button_main_div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  width: 100%;
  /* padding: 0px 24px; */
}

.delete_button {
  border: 1px solid rgba(208, 26, 26, 1);
  border-radius: 4px;
  padding: 9px 20px 10px 20px;
  color: rgba(208, 26, 26, 1);
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  /* text-align: left; */
  background-color: transparent;
}
.buttons {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
}
.edit_button {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  /* text-align: left; */
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 9px 20px 10px 20px;
  background-color: rgba(5, 110, 229, 1);
}
.cancel_button {
  color: rgba(121, 123, 128, 1);
  border: 1px solid rgba(173, 181, 189, 1);
  border-radius: 4px;
  padding: 9px 20px 10px 20px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  text-align: left;
  background-color: transparent;
}
.groupname {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
}
.groupNAme_editor {
  color: #343a40 !important;
  font-weight: 500 !important;
}
.groupname_details {
  color: rgba(121, 123, 128, 1);
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
  color: #797b80;
  border-bottom: 1px solid #f8f9fa;
}
.image_div {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
}
.groupmainModal {
  display: flex;
  gap: 12px;
  border-radius: 4px 0px 0px 0px;
  /* opacity: 0px; */
}

.modal_body_css {
  padding: 16px 24px 24px 24px;
}
.modal_body_css::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
.modal_body_css {
  scrollbar-width: none;
}
.modal_header_css {
  padding: 16px 24px 16px 24px !important;
  background-color: #f8f9fa;
  display: flex;
  justify-content: space-between;
}
.modal_header_css button {
  width: 10px;
}

.modal_footer_css {
  display: block;
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #f8f9fa;
  border-top: 1px solid #e9ecef;
}
.modal_user_footar {
  display: block;
  padding: 16px 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.modal_header_css .btn-close {
  font-size: 12px;
}

.preMainmodal {
  display: flex;
}
.firstmodal {
  width: 47%;
  padding: 24px 24px 24px 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  /* opacity: 0px; */
}
#mymainmodal {
  max-width: 75%;
}
.secondmodalpart {
  background-color: #f8f9fa;
  width: 53%;
  padding: 32px;

  border: 1px solid #e9ecef;
  /* opacity: 0px; */
  border-radius: 8px;
}
.selectCloud {
  font-size: 13px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  display: flex;
  align-items: center;
}
.cloudsdiv {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border-bottom: 1px solid #f8f9fa;
}
.cloudMain {
  border: 1px solid #dee2e6;
  border-radius: 4px;
}

.mytabsdiv .mb-3 nav nav-tabs {
  margin: 0px !important;
}

.mb {
  margin: 0px;
  display: flex;
  gap: 4px;
  border-bottom: none;
}

/* .mb,li {
  display: flex;
  gap: 8px;
  border-radius: 4px;
  /* background-color: #F8F9FA; */
/* }  */

/* .tabStyling   .nav-link.active {
  background-color: #056ee5;
  
} */
.mb button {
  padding: 6px 32px 6px 32px;
  font-size: 12px !important;
  font-weight: 600 !important;
  background-color: #f8f9fa;
  border-radius: 4px;
  outline: 1px solid #f8f9fa;
  color: #343a40;
  border: none !important;
}

.mb button:focus {
  background-color: #e6eefa !important;
  outline: 1px solid #8fbbea80;
  color: #212224 !important;
}

/* .mb .nav-item .nav-link.active{
  background-color: #056ee5;

} */

.Datacontainer {
  height: 217px;
  overflow-y: scroll;
}
.mainDAta_contianer {
  border: 1px solid #dee2e6;
  padding: 8px;
}
.mainRow {
  padding: 6px 8px 6px 8px;
  display: flex;
  gap: 12px;
  border-radius: 4px 0px 0px 0px;
  /* opacity: 0px; */
}

.row {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  text-align: left;
  color: #343a40;
}

.mainRow:hover {
  background: #e6eefa80;
  border-radius: 4px;
}
.thead {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  /* border-bottom: #f8f9fa; */
  background-color: #f8f9fa !important;
}

.mytd {
  background-color: #f8f9fa !important;
  border: none !important;
}
.mytd img {
  max-height: 100%; /* Adjust as needed */
}

.mytr {
  height: 14px;
  /* border-bottom: #f8f9fa; */
}
.textalign_center {
  text-align: center;
}
.textalign_start {
  text-align: left;
}

.adminContent {
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.gap {
  display: flex;
  gap: 8px;
  font-size: 13px;
  font-weight: 600;
  padding: 12px 24px 12px 24px;
  gap: 8px;
  /* opacity: 0px; */
  align-items: center;
  /* border-bottom:1px solid #E9ECEF; */
}
.myimgGroup {
  width: 24.22px;
  height: 11.95px;
  top: 2.03px;
  left: 1.22px;
  gap: 0px;
  /* opacity: 0px; */
}

.img_width {
  width: 12px;
}
.myselection {
  height: 100%;
  text-align: center;
  display: flex;
  width: 78%;
  align-items: center;
  justify-content: right;
  padding-top: 50%;
  padding-bottom: 50%;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px;
}

/* User Profile */

.userContainer {
  width: 100%;
  height: 30%;
  background-color: #0f2573;
  padding: 40px 180px;
}

.userInfoContainer {
  display: flex;
  flex-direction: row;
}

.userDetails {
  display: flex;
  flex-direction: column;
  padding-left: 19px;
  gap: 24px;
}

.nameAndDesignation {
}

.teamAndEmail {
  display: flex;
  flex-direction: column;
}

.userName {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.78px;
  text-align: left;
  color: #ffffff;
}

.userName::after {
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 100%;
  display: inline-block;
  margin: 0px 4px;
  background-color: #adb5bd;
}

.designation {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.94px;
  text-align: left;
  color: #fff;
}

.teamName {
  font-family: Inter;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.15px;
  text-align: left;
  color: #fff;
}

.emailStyling {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  color: #adb5bd;
}

.userTableContainer {
  padding: 44px 180px 44px 180px;
}

.selected_items {
  padding: 4px 8px 4px 8px;
  gap: 10px;
  border-radius: 2px;
  background-color: #edeff2;
  color: #212529;
  /* margin-right: 4px; */
}
.selectCloud_gap {
  padding: 5px 0px 5px 0px;
  display: flex;
  gap: 4px;
}

.onclick_name {
  cursor: pointer;
}

.accmodalwidth {
  width: 48% !important;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.goback {
  color: #797b80;
  display: flex;
  gap: 4px;
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}

.awsPolicy {
  font-size: 18px;
  font-weight: 700;
}

.last_modify {
  font-size: 12px;
  font-weight: 500;
  color: #343a40;
  opacity: 0.5;
}
.mainheaderr {
  /* padding: 24px; */
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.policyMainHeader {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
}
.policy_description {
  font-size: 13px;
  font-weight: 400;
}
.fontWeightHead {
  font-weight: 500 !important;
}
.padding_ACC_Head {
  padding-top: 24px !important;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.giving_right {
  position: relative;
  right: 13%;
}
.myMai_header {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.myhr_line {
  border-bottom: 1px solid #e9ecef;
  padding-top: 24px;
}

.groupUSer_name {
  font-size: 13px;
  font-weight: 600;
  text-align: left;
}

.groupuser_name {
  font-size: 13px;
  font-weight: 600;
  text-align: left;
}

.AdminBadge {
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  line-height: 13.31px;
  text-align: left;
  background-color: #f7edd2;
  border-radius: 2px;
  padding: 3px 6px;
}

.UserBadge {
  font-family: Inter;
  font-size: 11px;
  font-weight: 600;
  line-height: 13.31px;
  text-align: left;
  padding: 3px 6px;
  background-color: #eadbf2;
  border-radius: 2px;
}

.groupUSer_name::after {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 100%;
  display: inline-block;
  margin: 0px 4px;
  background-color: #afb5bd;
}
.myselected_user_data {
  font-size: 13px;
  font-weight: 600;
  text-align: left;
  color: #343a40;
  padding-left: "8px";
  white-space: nowrap;
}
.padding_left {
  padding-left: "8px";
}

.myTabs_UserGroup {
  /* width: 26% !important; */
  display: flex;
  gap: 8px;
  background-color: white;
  border-bottom: none;
  padding-top: 28px;
}
.myTabs_UserGroup button {
  color: black;
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #ced4da;
  padding: 8xp 16px 8px 16px;
  width: 20% !important;
}

.myUSerImage {
  width: 13px;
  height: 13px;
}
.myMainUserTab_div {
  padding: 16px 16px 0px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
}
.my_privileges_modal_header {
  padding: 24px !important;
  display: flex !important;
  align-items: flex-start !important;
}
.my_privileges_modal_header {
  padding: 24px !important;
  display: flex !important;
  align-items: flex-start !important;
}

.mySpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 226px;
}
.my_truncatecell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 40px;
}

.errorMessage {
  font-size: 10px;
  color: brown;
}

.mySpinner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 226px;
}
.my_truncatecell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
.my_truncatecells {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}
.my_truncatecellss {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 220px;
}

.border_bottomall {
  border-bottom: 1px solid #f8f9fa;
}

.errorMessage {
  font-size: 10px;
  color: brown;
}

.budgetButton {
  border-bottom: 1px solid #dee2e6;
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.73px;
  text-align: right;
}

.myemailstyling {
  display: flex;
  gap: 6px;
}
.emailOnly {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #797b80;
}
.myfilter_section {
  font-size: 13px;
  font-weight: 500;
  color: #adb5bd;
}
.myfilter_section2 {
  font-size: 13px;
  font-weight: 500;
  color: #212529;
}
.add_as_admin {
  display: flex;
  gap: 8px;
  padding: 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
  align-items: center;
}

.marginStyle {
  margin-top: "-21px" !important;
}
